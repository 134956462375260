/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import JWT from 'expo-jwt';
import companyActionTypes from './company.types';
import generalTypesAction from '../genera.types';
import {
	createCompany,
	getAllCompany,
	updateCompany,
	deleteCompany,
	companyLMSCall,
	companyListSelectCall,
	companyCondListCall,
	companyCondCreateCall,
	companyLoanSchedule,
} from './company.services';
import {
	getAllListSuccess,
	getAllListFailure,
	createCompanySuccess,
	createCompanyFailure,
	companyCreateChanged,
	updateCompanySuccess,
	updateCompanyFailure,
	companyUpdateChanged,
	deleteCompanySuccess,
	deleteCompanyFailure,
	companyDeleteChanged,
	lmsCallSuccess,
	lmsCallFailure,
	lmsCallChanged,
	companyListSelectSuccess,
	companyListSelectFailure,
	companyListSelectChanged,
	companyCondListSuccess,
	companyCondListFailure,
	companyCondListChanged,
	companyCondCreateSuccess,
	companyCondCreateFailure,
	companyCondCreateChanged,
	companyCondUpdateSuccess,
	companyCondUpdateFailure,
	companyCondUpdateChanged,
	companyLoanScheduleSuccess,
	companyLoanScheduleFailure,
	companyLoanScheduleChanged,
} from './company.actions';
import { encodeJson } from '../enode-decode';

export function* createNewCompany({ payload: { values } }) {
	try {
		const jsonData = {
			CompanyName: values.companyName,
			CompanyLogo: values.companyLogo,
			CompanyAddress: values.companyAddress,
			CompanyZip: values.companyZip,
			CompanyState: values.companyState,
			CompanyCity: values.companyCity,
			CompanyPhone: values.companyPhone,
			CompanyWebsite: values.companyWebsite,
			CompanyEmail: values.companyEmail,
			CompanyGST: values.companyGST,
			CompanyAuthority1: values.companyAuthority1,
			CompanyAuthPhone1: values.companyAuthPhone1,
			CompanyAuthority2: values.companyAuthority2,
			CompanyAuthPhone2: values.companyAuthPhone2,
			CompanyAuthority3: values.companyAuthority3,
			CompanyAuthPhone3: values.companyAuthPhone3,
			HighmarkUserID: values.highmarkUserID,
			HighMarkUserPassword: values.highMarkUserPassword,
			HighMarkMbrName: values.highMarkMbrName,
			HighmarkConsumerID: values.highmarkConsumerID,
			HighMarkConsumerUserID: values.highMarkConsumerUserID,
			HighMarkConsumerUserPassword: values.highMarkConsumerUserPassword,
			HighMarkConsumerMbrName: values.highMarkConsumerMbrName,
			IsProductionHighMark: values.isProductionHighMark ? 1 : 0,
			MaxRuralIncome: values.maxRuralIncome,
			Score: values.score,
			ActiveMFICount: values.activeMFICount,
			OutstandingMax: values.outstandingMax,
			OverdueAmountMax: values.overdueAmountMax,
			WriteOffAmountMax: values.writeOffAmountMax,
			NoOfOtherMFIMax: values.noOfOtherMFIMax,
			LinkCompanyID: values.linkCompanyID,
			AdminName: values.adminName,
			AdminPhone: values.adminPhone,
			AdminPassword: values.adminPassword,
			IsProductBased: values.isProductBased ? 1 : 0,
			IsLMSEnabled: values.isLMSEnabled ? 1 : 0,
		};
		const CompanyData = encodeJson(jsonData, generalTypesAction.COMPANY);

		const responseData = yield call(createCompany, CompanyData);

		if (responseData.data.statusCode === '100') {
			yield put(createCompanyFailure(responseData.data));
		} else {
			const DecodeCompanyData = JWT.decode(
				responseData.data.response,
				generalTypesAction.COMPANY,
			);
			yield put(createCompanySuccess(DecodeCompanyData.data));
		}
	} catch (error) {
		yield put(createCompanyFailure(error));
	}
}

export function* updateCompanyDetails({ payload: { values } }) {
	try {
		const jsonData = {
			CompanyID: values.companyID,
			CompanyName: values.companyName,
			CompanyLogo: values.companyLogo,
			CompanyAddress: values.companyAddress,
			CompanyZip: values.companyZip,
			CompanyState: values.companyState,
			CompanyCity: values.companyCity,
			CompanyPhone: values.companyPhone,
			CompanyWebsite: values.companyWebsite,
			CompanyEmail: values.companyEmail,
			CompanyGST: values.companyGST,
			CompanyAuthority1: values.companyAuthority1,
			CompanyAuthPhone1: values.companyAuthPhone1,
			CompanyAuthority2: values.companyAuthority2,
			CompanyAuthPhone2: values.companyAuthPhone2,
			CompanyAuthority3: values.companyAuthority3,
			CompanyAuthPhone3: values.companyAuthPhone3,
			HighmarkUserID: values.highmarkUserID,
			HighMarkUserPassword: values.highMarkUserPassword,
			HighMarkMbrName: values.highMarkMbrName,
			HighmarkConsumerID: values.highmarkConsumerID,
			HighMarkConsumerUserID: values.highMarkConsumerUserID,
			HighMarkConsumerUserPassword: values.highMarkConsumerUserPassword,
			HighMarkConsumerMbrName: values.highMarkConsumerMbrName,
			IsProductionHighMark: values.isProductionHighMark ? 1 : 0,
			MaxRuralIncome: values.maxRuralIncome,
			Score: values.score,
			ActiveMFICount: values.activeMFICount,
			OutstandingMax: values.outstandingMax,
			OverdueAmountMax: values.overdueAmountMax,
			WriteOffAmountMax: values.writeOffAmountMax,
			NoOfOtherMFIMax: values.noOfOtherMFIMax,
			LinkCompanyID: values.linkCompanyID,
			AdminName: values.adminName,
			AdminPhone: values.adminPhone,
			AdminPassword: values.adminPassword,
			IsProductBased: values.isProductBased ? 1 : 0,
			IsLMSEnabled: values.isLMSEnabled ? 1 : 0,
		};
		const CompanyData = encodeJson(jsonData, generalTypesAction.COMPANY);

		const responseData = yield call(updateCompany, CompanyData);

		if (responseData.data.statusCode === '100') {
			yield put(updateCompanyFailure(responseData.data));
		} else {
			const DecodeCompanyData = JWT.decode(
				responseData.data.response,
				generalTypesAction.COMPANY,
			);
			yield put(updateCompanySuccess(DecodeCompanyData.data));
		}
	} catch (error) {
		yield put(updateCompanyFailure(error));
	}
}

export function* deleteCompanyDetails({ payload: { companyID } }) {
	try {
		const jsonData = {
			CompanyID: companyID,
		};
		const CompanyData = encodeJson(jsonData, generalTypesAction.COMPANY);

		const responseData = yield call(deleteCompany, CompanyData);

		if (responseData.data.statusCode === '100') {
			yield put(deleteCompanyFailure(responseData.data));
		} else {
			const DecodeCompanyData = JWT.decode(
				responseData.data.response,
				generalTypesAction.COMPANY,
			);
			yield put(deleteCompanySuccess(DecodeCompanyData.data));
		}
	} catch (error) {
		yield put(deleteCompanyFailure(error));
	}
}

export function* createListOfCompany({ payload: { limit } }) {
	try {
		const jsonData = {
			Limit: limit,
		};
		const CompanyData = encodeJson(jsonData, generalTypesAction.COMPANY);

		const responseData = yield call(getAllCompany, CompanyData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllListFailure(responseData.data));
		} else {
			const DecodeCompanyData = JWT.decode(
				responseData.data.response,
				generalTypesAction.COMPANY,
			);
			yield put(getAllListSuccess(DecodeCompanyData.data));
		}
	} catch (error) {
		yield put(getAllListFailure(error));
	}
}

export function* createResponseReset() {
	yield put(companyCreateChanged());
}

export function* updateResponseReset() {
	yield put(companyUpdateChanged());
}

export function* deleteResponseReset() {
	yield put(companyDeleteChanged());
}

export function* companyCreateStart() {
	yield takeLatest(companyActionTypes.COMPANY_CREATE_REPSONSE_START, createNewCompany);
}

export function* companyCreateResponseReset() {
	yield takeLatest(companyActionTypes.COMPANY_CREATE_REPSONSE_RESET, createResponseReset);
}

export function* companyUpdateStart() {
	yield takeLatest(companyActionTypes.COMPANY_UPDATE_REPSONSE_START, updateCompanyDetails);
}

export function* companyUpdateResponseReset() {
	yield takeLatest(companyActionTypes.COMPANY_UPDATE_REPSONSE_RESET, updateResponseReset);
}

export function* companyDeleteStart() {
	yield takeLatest(companyActionTypes.COMPANY_DELETE_REPSONSE_START, deleteCompanyDetails);
}

export function* companyDeleteResponseReset() {
	yield takeLatest(companyActionTypes.COMPANY_DELETE_REPSONSE_RESET, deleteResponseReset);
}

export function* getAllCompanyList() {
	yield takeLatest(companyActionTypes.COMPANY_LIST_START, createListOfCompany);
}

// LMS CALL SECTION

// eslint-disable-next-line no-unused-vars
export function* createLMSCall({ payload }) {
	try {
		const jsonData = {
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, generalTypesAction.PAISANOWPUSH);
		const responseData = yield call(companyLMSCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(lmsCallFailure(responseData.data));
		} else {
			yield put(lmsCallSuccess(responseData.data));
		}
	} catch (error) {
		yield put(lmsCallFailure(error));
	}
}

export function* createLMSCallReset() {
	yield put(lmsCallChanged());
}

export function* companyLMSCallStart() {
	yield takeLatest(companyActionTypes.COMPANY_LMS_CALL_START, createLMSCall);
}

export function* companyLMSCallResponseReset() {
	yield takeLatest(companyActionTypes.COMPANY_LMS_CALL_RESET, createLMSCallReset);
}

// COMPANY LIST SELECT 

// eslint-disable-next-line no-unused-vars
export function* createListSelectCall({ payload }) {
	try {
		const jsonData = {
			UserID: payload.userID,
		};
		const encodeData = encodeJson(jsonData, generalTypesAction.PAISANOWPUSH);
		const responseData = yield call(companyListSelectCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(companyListSelectFailure(responseData.data));
		} else {
			yield put(companyListSelectSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(companyListSelectFailure(error));
	}
}

export function* createListSelectCallReset() {
	yield put(companyListSelectChanged());
}

export function* companyListSelectStart() {
	yield takeLatest(companyActionTypes.COMPANY_LIST_SELECT_START, createListSelectCall);
}

export function* companyListSelectResponseReset() {
	yield takeLatest(companyActionTypes.COMPANY_LIST_SELECT_RESET, createListSelectCallReset);
}

// COMPANY LIST SELECT 

// eslint-disable-next-line no-unused-vars
export function* createCondListCall({ payload }) {
	try {
		const jsonData = {
			CompanyID: payload.CompanyID,
		};
		const encodeData = encodeJson(jsonData, generalTypesAction.COMPANY);
		const responseData = yield call(companyCondListCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(companyCondListFailure(responseData.data));
		} else {
			yield put(companyCondListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(companyCondListFailure(error));
	}
}

export function* createCondListCallReset() {
	yield put(companyCondListChanged());
}

export function* companyCondListStart() {
	yield takeLatest(companyActionTypes.COMPANY_COND_LIST_START, createCondListCall);
}

export function* companyCondListResponseReset() {
	yield takeLatest(companyActionTypes.COMPANY_COND_LIST_RESET, createCondListCallReset);
}

// COMPANY LIST SELECT 

// eslint-disable-next-line no-unused-vars
export function* createCondCreateCall({ payload }) {
	try {
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			CondArr: JSON.stringify(payload.condArr).replace(/"/g, "'"),
		};
		const encodeData = encodeJson(jsonData, generalTypesAction.COMPANY);
		const responseData = yield call(companyCondCreateCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(companyCondCreateFailure(responseData.data));
		} else {
			yield put(companyCondCreateSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(companyCondCreateFailure(error));
	}
}

export function* createCondCreateCallReset() {
	yield put(companyCondCreateChanged());
}

export function* companyCondCreateStart() {
	yield takeLatest(companyActionTypes.COMPANY_COND_CREATE_START, createCondCreateCall);
}

export function* companyCondCreateResponseReset() {
	yield takeLatest(companyActionTypes.COMPANY_COND_CREATE_RESET, createCondCreateCallReset);
}

// COMPANY COND UPDATE SELECT 
export function* createCondUpdateCall({ payload }) {
	try {
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			CondArr: JSON.stringify(payload.condArr).replace(/"/g, "'"),
		};
		const encodeData = encodeJson(jsonData, generalTypesAction.COMPANY);
		const responseData = yield call(companyCondCreateCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(companyCondUpdateFailure(responseData.data));
		} else {
			yield put(companyCondUpdateSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(companyCondUpdateFailure(error));
	}
}

export function* createCondUpdateCallReset() {
	yield put(companyCondUpdateChanged());
}

export function* companyCondUpdateStart() {
	yield takeLatest(companyActionTypes.COMPANY_COND_UPDATE_START, createCondUpdateCall);
}

export function* companyCondUpdateResponseReset() {
	yield takeLatest(companyActionTypes.COMPANY_COND_UPDATE_RESET, createCondUpdateCallReset);
}

// LOAN SCHEDULE SECTION

// eslint-disable-next-line no-unused-vars
export function* createLoanSchedule({ payload }) {
	try {
		const jsonData = {
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, generalTypesAction.PAISANOWPUSH);
		const responseData = yield call(companyLoanSchedule, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(companyLoanScheduleFailure(responseData.data));
		} else {
			yield put(companyLoanScheduleSuccess(responseData.data));
		}
	} catch (error) {
		yield put(companyLoanScheduleFailure(error));
	}
}

export function* createLoanScheduleReset() {
	yield put(companyLoanScheduleChanged());
}

export function* companyLoanScheduleStart() {
	yield takeLatest(companyActionTypes.COMPANY_LOAN_SCHEDULE_START, createLoanSchedule);
}

export function* companyLoanScheduleResponseReset() {
	yield takeLatest(companyActionTypes.COMPANY_LOAN_SCHEDULE_RESET, createLoanScheduleReset);
}

export function* companySaga() {
	yield all([
		call(getAllCompanyList),
		call(companyCreateStart),
		call(companyCreateResponseReset),
		call(companyUpdateStart),
		call(companyUpdateResponseReset),
		call(companyDeleteStart),
		call(companyDeleteResponseReset),
		call(companyLMSCallStart),
		call(companyLMSCallResponseReset),
		call(companyListSelectStart),
		call(companyListSelectResponseReset),
		call(companyCondListStart),
		call(companyCondListResponseReset),
		call(companyCondCreateStart),
		call(companyCondCreateResponseReset),
		call(companyCondUpdateStart),
		call(companyCondUpdateResponseReset),
		call(companyLoanScheduleStart),
		call(companyLoanScheduleResponseReset),
	]);
}
