/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import productActionTypes from './product.types';
import generalTypesAction from '../genera.types';
import { getEndodeJsonData } from '../common/common.utils';

const INITIAL_STATE = {
	allProductList: null,
	allProductListStatus: null,

	productCreate: null,
	productCreateStatus: null,

	productUpdate: null,
	productUpdateStatus: null,

	productDelete: null,
	productDeleteStatus: null,

	productFetch: null,
	productFetchStatus: null,
};

// eslint-disable-next-line default-param-last
const productReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {

		case productActionTypes.PRODUCT_LIST_SUCCESS:
			return {
				...state,
				allProductList: getEndodeJsonData(action.payload, generalTypesAction.PRODUCT),
				allProductListStatus: 'Success',
			};
		case productActionTypes.PRODUCT_LIST_FAILURE:
			return { ...state, allProductList: null, allProductListStatus: 'Failure' };
		case productActionTypes.PRODUCT_LIST_CHANGED:
			return { ...state, allProductListStatus: null };

		case productActionTypes.PRODUCT_CREATE_SUCCESS:
			return {
				...state,
				productCreate: getEndodeJsonData(action.payload, generalTypesAction.PRODUCT),
				productCreateStatus: 'Success',
			};
		case productActionTypes.PRODUCT_CREATE_FAILURE:
			return { ...state, productCreate: null, productCreateStatus: 'Failure' };
		case productActionTypes.PRODUCT_CREATE_CHANGED:
			return { ...state, productCreateStatus: null };

		case productActionTypes.PRODUCT_UPDATE_SUCCESS:
			return {
				...state,
				productUpdate: getEndodeJsonData(action.payload, generalTypesAction.PRODUCT),
				productUpdateStatus: 'Success',
			};
		case productActionTypes.PRODUCT_UPDATE_FAILURE:
			return { ...state, productUpdate: null, productUpdateStatus: 'Failure' };
		case productActionTypes.PRODUCT_UPDATE_CHANGED:
			return { ...state, productUpdateStatus: null };

		case productActionTypes.PRODUCT_DELETE_SUCCESS:
			return {
				...state,
				productDelete: getEndodeJsonData(action.payload, generalTypesAction.PRODUCT),
				productDeleteStatus: 'Success',
			};
		case productActionTypes.PRODUCT_DELETE_FAILURE:
			return { ...state, productDelete: null, productDeleteStatus: 'Failure' };
		case productActionTypes.PRODUCT_DELETE_CHANGED:
			return { ...state, productDeleteStatus: null };

		case productActionTypes.PRODUCT_FETCH_SUCCESS:
			return {
				...state,
				productFetch: getEndodeJsonData(action.payload, generalTypesAction.PRODUCT),
				productFetchStatus: 'Success',
			};
		case productActionTypes.PRODUCT_FETCH_FAILURE:
			return { ...state, productFetch: null, productFetchStatus: 'Failure' };
		case productActionTypes.PRODUCT_FETCH_CHANGED:
			return { ...state, productFetchStatus: null };
		default:
			return state;
	}
};

export default productReducer;
