import { all, call } from 'redux-saga/effects';

import { loginSaga } from './login/login.sagas';
import { departmentSaga } from './department/department.sagas';
import { deliveryListSaga } from './deliveryProcess/deliveryProcess.sagas';
import { companySaga } from './company/company.sagas';
import { userTypeSaga } from './userType/userType.sagas';
import { staffSaga } from './staff/staff.sagas';
import { branchSaga } from './branch/branch.sagas';
import { compareSaga } from './compare/compare.sagas';
import { loanIndexSaga } from './loanIndex/loanIndex.sagas';
import { memberSaga } from './member/member.sagas';
import { profileSaga } from './profile/profile.sagas';
import { ndcSaga } from './memberNDC/memberNDC.sagas';
import { bankSaga } from './bank/bank.sagas';
import { cbCheckSaga } from './cbCheck/cbCheck.sagas';
import { questionnaireSaga } from './questionnaire/questionnaire.sagas';
import { blockChainSaga } from './blockChain/blockChain.sagas';
import { dashboardSaga } from './dashboard/dashboard.sagas';
import { productSaga } from './product/product.sagas';
import { disbursementSaga } from './disbursement/disbursement.sagas';
import { demandReportSaga } from './demand/demandReport.sagas';
import { repaymentReportSaga } from './repayment/repaymentReport.sagas';
import { targetSaga } from './target/target.sagas';

export default function* rootSaga() {
	yield all([
		call(loginSaga),
		call(departmentSaga),
		call(deliveryListSaga),
		call(companySaga),
		call(userTypeSaga),
		call(staffSaga),
		call(branchSaga),
		call(compareSaga),
		call(loanIndexSaga),
		call(memberSaga),
		call(profileSaga),
		call(ndcSaga),
		call(bankSaga),
		call(cbCheckSaga),
		call(questionnaireSaga),
		call(blockChainSaga),
		call(dashboardSaga),
		call(productSaga),
		call(disbursementSaga),
		call(demandReportSaga),
		call(repaymentReportSaga),
		call(targetSaga),
	]);
}
