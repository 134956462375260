/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import QuestionnaireActionTypes from './questionnaire.types';

export const getAllQuestionnaireList = (Questionnaire) => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_LIST_START,
	payload: Questionnaire,
});

export const getAllQuestionnaireListSuccess = (success) => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_LIST_SUCCESS,
	payload: success,
});

export const getAllQuestionnaireListFailure = (error) => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_LIST_FAILURE,
	payload: error,
});

export const getAllQuestionnaireListChanged = () => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_LIST_CHANGED,
});

export const getAllQuestionnaireListReset = () => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_LIST_RESET,
});

export const createQuestionnaireStart = (QuestionnaireDetails) => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_CREATE_REPSONSE_START,
	payload: QuestionnaireDetails,
});

export const createQuestionnaireSuccess = (success) => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_CREATE_REPSONSE_SUCCESS,
	payload: success,
});

export const createQuestionnaireFailure = (error) => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_CREATE_REPSONSE_FAILURE,
	payload: error,
});

export const createQuestionnaireChanged = () => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_CREATE_REPSONSE_CHANGED,
});

export const createQuestionnaireReset = () => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_CREATE_REPSONSE_RESET,
});

export const updateQuestionnaireStart = (QuestionnaireDetails) => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_UPDATE_REPSONSE_START,
	payload: QuestionnaireDetails,
});

export const updateQuestionnaireSuccess = (success) => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_UPDATE_REPSONSE_SUCCESS,
	payload: success,
});

export const updateQuestionnaireFailure = (error) => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_UPDATE_REPSONSE_FAILURE,
	payload: error,
});

export const updateQuestionnaireChanged = () => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_UPDATE_REPSONSE_CHANGED,
});

export const updateQuestionnaireReset = () => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_UPDATE_REPSONSE_RESET,
});

export const deleteQuestionnaireStart = (QuestionnaireDetails) => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_DELETE_REPSONSE_START,
	payload: QuestionnaireDetails,
});

export const deleteQuestionnaireSuccess = (success) => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_DELETE_REPSONSE_SUCCESS,
	payload: success,
});

export const deleteQuestionnaireFailure = (error) => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_DELETE_REPSONSE_FAILURE,
	payload: error,
});

export const deleteQuestionnaireChanged = () => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_DELETE_REPSONSE_CHANGED,
});

export const deleteQuestionnaireReset = () => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_DELETE_REPSONSE_RESET,
});

export const fetchQuestionnaireStart = (QuestionnaireDetails) => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_FETCH_REPSONSE_START,
	payload: QuestionnaireDetails,
});

export const fetchQuestionnaireSuccess = (success) => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_FETCH_REPSONSE_SUCCESS,
	payload: success,
});

export const fetchQuestionnaireFailure = (error) => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_FETCH_REPSONSE_FAILURE,
	payload: error,
});

export const fetchQuestionnaireChanged = () => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_FETCH_REPSONSE_CHANGED,
});

export const fetchQuestionnaireReset = () => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_FETCH_REPSONSE_RESET,
});

export const getFilterListStart = (QuestionnaireDetails) => ({
	type: QuestionnaireActionTypes.QUESTIONNAIRE_GET_FILTER_SEARCH_LIST,
	payload: QuestionnaireDetails,
});