/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import staffActionTypes from './staff.types';
import generalTypesAction from '../genera.types';
import { getEndodeJsonData } from '../common/common.utils';

const INITIAL_STATE = {
	staffCreateStatus: null,
	staffCreateResponse: null,
	staffUpdateStatus: null,
	staffUpdateResponse: null,
	staffDeleteStatus: null,
	staffDeleteResponse: null,
	allStaffList: null,
	allStaffListStatus: null,
};

// eslint-disable-next-line default-param-last
const staffReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case staffActionTypes.STAFF_CREATE_REPSONSE_SUCCESS:
			return { ...state, staffCreateResponse: action.payload, staffCreateStatus: 'Success' };
		case staffActionTypes.STAFF_CREATE_REPSONSE_FAILURE:
			return { ...state, staffCreateResponse: action.payload, staffCreateStatus: 'Failure' };
		case staffActionTypes.STAFF_CREATE_REPSONSE_CHANGED:
			return { ...state, staffCreateResponse: null, staffCreateStatus: null };

		case staffActionTypes.STAFF_UPDATE_REPSONSE_SUCCESS:
			return { ...state, staffUpdateResponse: action.payload, staffUpdateStatus: 'Success' };
		case staffActionTypes.STAFF_UPDATE_REPSONSE_FAILURE:
			return { ...state, staffUpdateResponse: action.payload, staffUpdateStatus: 'Failure' };
		case staffActionTypes.STAFF_UPDATE_REPSONSE_CHANGED:
			return { ...state, staffUpdateResponse: null, staffUpdateStatus: null };

		case staffActionTypes.STAFF_DELETE_REPSONSE_SUCCESS:
			return { ...state, staffDeleteResponse: action.payload, staffDeleteStatus: 'Success' };
		case staffActionTypes.STAFF_DELETE_REPSONSE_FAILURE:
			return { ...state, staffDeleteResponse: action.payload, staffDeleteStatus: 'Failure' };
		case staffActionTypes.STAFF_DELETE_REPSONSE_CHANGED:
			return { ...state, staffDeleteResponse: null, staffDeleteStatus: null };

		case staffActionTypes.STAFF_LIST_SUCCESS:
			return {
				...state,
				allStaffList: getEndodeJsonData(action.payload, generalTypesAction.STAFF),
				allStaffListStatus: 'Success',
			};
		case staffActionTypes.STAFF_LIST_FAILURE:
			return { ...state, allStaffList: null, allStaffListStatus: 'Failure' };
		case staffActionTypes.STAFF_LIST_CHANGED:
			return { ...state, allStaffListStatus: null };
		default:
			return state;
	}
};

export default staffReducer;
