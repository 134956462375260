/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import DemandReportActionTypes from './demandReport.types';
import generalTypesAction from '../genera.types';
import { getAllDemandReport } from './demandReport.services';
import {
	getAllDemandListSuccess,
	getAllDemandListFailure,
	getAllDemandListChanged,
} from './demandReport.actions';
import { encodeJson } from '../enode-decode';

export function* getListOfDemand({ payload }) {
	try {
		const jsonData = {
			Limit: payload.limit,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			BranchID: payload.branchID,
		};
		const encodedData = encodeJson(jsonData, generalTypesAction.DEMAND);
		const responseData = yield call(getAllDemandReport, encodedData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(getAllDemandListFailure(responseData.data));
		} else {
			yield put(getAllDemandListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getAllDemandListFailure(error));
	}
}

export function* demandFilterList({ payload }) {
	try {
		const jsonData = {
			SearchVal: payload.searchVal,
			CompanyID: payload.companyID,
		};
		const encodedData = encodeJson(jsonData, generalTypesAction.DEMAND);
		const responseData = yield call(getAllDemandReport, encodedData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(getAllDemandListFailure(responseData.data));
		} else {
			yield put(getAllDemandListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getAllDemandListFailure(error));
	}
}

export function* getListOfDemandReset() {
	yield put(getAllDemandListChanged());
}
export function* getAllDemandList() {
	yield takeLatest(DemandReportActionTypes.DEMAND_REPORT_LIST_START, getListOfDemand);
}

export function* getAllDemandListReset() {
	yield takeLatest(DemandReportActionTypes.DEMAND_REPORT_LIST_RESET, getListOfDemandReset);
}

export function* getAllDemandFilterList() {
	yield takeLatest(DemandReportActionTypes.DEMAND_REPORT_GET_FILTER_SEARCH_LIST, demandFilterList);
}
export function* demandReportSaga() {
	yield all([
		call(getAllDemandList),
		call(getAllDemandListReset),
		call(getAllDemandFilterList),
	]);
}
