const compareActionTypes = {
	COMPARE_LIST_START: 'compare_list_start',
	COMPARE_LIST_SUCCESS: 'compare_list_success',
	COMPARE_LIST_FAILURE: 'compare_list_failure',
	COMPARE_LIST_CHANGED: 'compare_list_changed',
	COMPARE_LIST_RESET: 'compare_list_reset',

	COMPARE_MEMBER_DETAILS_START: 'compare_member_details_start',
	COMPARE_MEMBER_DETAILS_SUCCESS: 'compare_member_details_success',
	COMPARE_MEMBER_DETAILS_FAILURE: 'compare_member_details_failure',
	COMPARE_MEMBER_DETAILS_CHANGED: 'compare_member_details_changed',
	COMPARE_MEMBER_DETAILS_RESET: 'compare_member_details_reset',

	COMPARE_SQ_MEMBER_DETAILS_START: 'compare_sq_member_details_start',
	COMPARE_SQ_MEMBER_DETAILS_SUCCESS: 'compare_sq_member_details_success',
	COMPARE_SQ_MEMBER_DETAILS_FAILURE: 'compare_sq_member_details_failure',
	COMPARE_SQ_MEMBER_DETAILS_CHANGED: 'compare_sq_member_details_changed',
	COMPARE_SQ_MEMBER_DETAILS_RESET: 'compare_sq_member_details_reset',

	SQCOMPARE_MEMBER_DETAILS_START: 'sqcompare_member_details_start',
	SQCOMPARE_MEMBER_DETAILS_SUCCESS: 'sqcompare_member_details_success',
	SQCOMPARE_MEMBER_DETAILS_FAILURE: 'sqcompare_member_details_failure',
	SQCOMPARE_MEMBER_DETAILS_CHANGED: 'sqcompare_member_details_changed',
	SQCOMPARE_MEMBER_DETAILS_RESET: 'sqcompare_member_details_reset',

	COMPARE_LIST_ID: 'compare_list_id',
	COMPARE_LIST_NAME: 'compare_list_name',

	COMPARE_REMARKS_LOG_START: 'compare_remarks_log_start',
	COMPARE_REMARKS_LOG_SUCCESS: 'compare_remarks_log_success',
	COMPARE_REMARKS_LOG_FAILURE: 'compare_remarks_log_failure',
	COMPARE_REMARKS_LOG_CHANGED: 'compare_remarks_log_changed',
	COMPARE_REMARKS_LOG_RESET: 'compare_remarks_log_reset',

	COMPARE_FETCH_REMARKS_RESPONSE_START: 'compare_confirmation_response_start',
	COMPARE_FETCH_REMARKS_RESPONSE_SUCCESS: 'compare_confirmation_response_success',
	COMPARE_FETCH_REMARKS_RESPONSE_FAILURE: 'compare_confirmation_response_failure',
	COMPARE_FETCH_REMARKS_RESPONSE_CHANGED: 'compare_confirmation_response_changed',
	COMPARE_FETCH_REMARKS_RESPONSE_RESET: 'compare_confirmation_response_reset',

	COMPARE_REMARKS_VALUES: 'compare_remarks_values',

	COMPARE_FILTER_SEARCH_START: 'compare_filter_search_start',

	COMPARE_LEADER_DATA_STORE: 'compare_leader_data_store',
	COMPARE_LEADER_DATA_STORE_RESET: 'compare_leader_data_store_reset',

	COMPARE_CONFIRMATINO_RESPONSE_START: 'compare_confirmation_response_start',
	COMPARE_CONFIRMATINO_RESPONSE_SUCCESS: 'compare_confirmation_response_success',
	COMPARE_CONFIRMATINO_RESPONSE_FAILURE: 'compare_confirmation_response_failure',
	COMPARE_CONFIRMATINO_RESPONSE_CHANGED: 'compare_confirmation_response_changed',
	COMPARE_CONFIRMATINO_RESPONSE_RESET: 'compare_confirmation_response_reset',

	COMPARE_REJECT_MEMBER_START: 'compare_reject_member_start',
	COMPARE_REJECT_MEMBER_SUCCESS: 'compare_reject_member_success',
	COMPARE_REJECT_MEMBER_FAILURE: 'compare_reject_member_failure',
	COMPARE_REJECT_MEMBER_CHANGED: 'compare_reject_member_changed',
	COMPARE_REJECT_MEMBER_RESET: 'compare_reject_member_reset',
};
export default compareActionTypes;
