/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import dashboardActionTypes from './dashboard.types';

export const dashboardLoadStart = (data) => ({
	type: dashboardActionTypes.DASHBOARD_LOAD_REPSONSE_START,
	payload: data,
});

export const dashboardLoadSuccess = (data) => ({
	type: dashboardActionTypes.DASHBOARD_LOAD_REPSONSE_SUCCESS,
	payload: data,
});

export const dashboardLoadFailure = (data) => ({
	type: dashboardActionTypes.DASHBOARD_LOAD_REPSONSE_FAILURE,
	payload: data,
});

export const dashboardLoadChanged = () => ({
	type: dashboardActionTypes.DASHBOARD_LOAD_REPSONSE_CHANGED,
});

export const dashboardLoadReset = () => ({
	type: dashboardActionTypes.DASHBOARD_LOAD_REPSONSE_RESET,
});

// COLLECTION SECTION
export const dashboardCollectionStart = (data) => ({
	type: dashboardActionTypes.DASHBOARD_COLLECTION_START,
	payload: data,
});

export const dashboardCollectionSuccess = (data) => ({
	type: dashboardActionTypes.DASHBOARD_COLLECTION_SUCCESS,
	payload: data,
});

export const dashboardCollectionFailure = (data) => ({
	type: dashboardActionTypes.DASHBOARD_COLLECTION_FAILURE,
	payload: data,
});

export const dashboardCollectionChanged = () => ({
	type: dashboardActionTypes.DASHBOARD_COLLECTION_CHANGED,
});

export const dashboardCollectionReset = () => ({
	type: dashboardActionTypes.DASHBOARD_COLLECTION_RESET,
});

// COLLECTION SECTION TABLE
export const dashboardCollectionTableStart = (data) => ({
	type: dashboardActionTypes.DASHBOARD_COLLECTION_TABLE_START,
	payload: data,
});

export const dashboardCollectionTableSuccess = (data) => ({
	type: dashboardActionTypes.DASHBOARD_COLLECTION_TABLE_SUCCESS,
	payload: data,
});

export const dashboardCollectionTableFailure = (data) => ({
	type: dashboardActionTypes.DASHBOARD_COLLECTION_TABLE_FAILURE,
	payload: data,
});

export const dashboardCollectionTableChanged = () => ({
	type: dashboardActionTypes.DASHBOARD_COLLECTION_TABLE_CHANGED,
});

export const dashboardCollectionTableReset = () => ({
	type: dashboardActionTypes.DASHBOARD_COLLECTION_TABLE_RESET,
});
