const questionnaireActionTypes = {
	QUESTIONNAIRE_LIST_START: 'questionnaire_list_start',
	QUESTIONNAIRE_LIST_SUCCESS: 'questionnaire_list_success',
	QUESTIONNAIRE_LIST_FAILURE: 'questionnaire_list_failure',
	QUESTIONNAIRE_LIST_CHANGED: 'questionnaire_list_changed',
	QUESTIONNAIRE_LIST_RESET: 'questionnaire_list_reset',

	QUESTIONNAIRE_CREATE_REPSONSE_START: 'questionnaire_create_response_start',
	QUESTIONNAIRE_CREATE_REPSONSE_SUCCESS: 'questionnaire_create_response_success',
	QUESTIONNAIRE_CREATE_REPSONSE_FAILURE: 'questionnaire_create_response_failure',
	QUESTIONNAIRE_CREATE_REPSONSE_CHANGED: 'questionnaire_create_response_changed',
	QUESTIONNAIRE_CREATE_REPSONSE_RESET: 'questionnaire_create_response_reset',

	QUESTIONNAIRE_UPDATE_REPSONSE_START: 'questionnaire_update_response_start',
	QUESTIONNAIRE_UPDATE_REPSONSE_SUCCESS: 'questionnaire_update_response_success',
	QUESTIONNAIRE_UPDATE_REPSONSE_FAILURE: 'questionnaire_update_response_failure',
	QUESTIONNAIRE_UPDATE_REPSONSE_CHANGED: 'questionnaire_update_response_changed',
	QUESTIONNAIRE_UPDATE_REPSONSE_RESET: 'questionnaire_update_response_reset',

	QUESTIONNAIRE_DELETE_REPSONSE_START: 'questionnaire_delete_response_start',
	QUESTIONNAIRE_DELETE_REPSONSE_SUCCESS: 'questionnaire_delete_response_success',
	QUESTIONNAIRE_DELETE_REPSONSE_FAILURE: 'questionnaire_delete_response_failure',
	QUESTIONNAIRE_DELETE_REPSONSE_CHANGED: 'questionnaire_delete_response_changed',
	QUESTIONNAIRE_DELETE_REPSONSE_RESET: 'questionnaire_delete_response_reset',

	QUESTIONNAIRE_FETCH_REPSONSE_START: 'questionnaire_fetch_response_start',
	QUESTIONNAIRE_FETCH_REPSONSE_SUCCESS: 'questionnaire_fetch_response_success',
	QUESTIONNAIRE_FETCH_REPSONSE_FAILURE: 'questionnaire_fetch_response_failure',
	QUESTIONNAIRE_FETCH_REPSONSE_CHANGED: 'questionnaire_fetch_response_changed',
	QUESTIONNAIRE_FETCH_REPSONSE_RESET: 'questionnaire_fetch_response_reset',

	QUESTIONNAIRE_GET_FILTER_SEARCH_LIST: 'questionnaire_get_filter_search_list',
};
export default questionnaireActionTypes;
