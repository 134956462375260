/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import memberActionTypes from './member.types';

export const allMemberBorrowerStart = (MemberDetails) => ({
	type: memberActionTypes.MEMBER_GET_ALL_LIST_START,
	payload: MemberDetails,
});

export const allMemberBorrowerSuccess = (Staff) => ({
	type: memberActionTypes.MEMBER_GET_ALL_SUCCESS,
	payload: Staff,
});

export const allMemberBorrowerFailure = (error) => ({
	type: memberActionTypes.MEMBER_GET_ALL_FAILURE,
	payload: error,
});

export const allMemberBorrowerChanged = () => ({
	type: memberActionTypes.MEMBER_GET_ALL_CHANGED,
});

export const allMemberBorrowerReset = () => ({
	type: memberActionTypes.MEMBER_GET_ALL_RESET,
});

export const memberBorrowerStart = (MemberDetails) => ({
	type: memberActionTypes.MEMBER_GET_DETAILS_START,
	payload: MemberDetails,
});

export const memberBorrowerSuccess = (Staff) => ({
	type: memberActionTypes.MEMBER_GET_DETAILS_SUCCESS,
	payload: Staff,
});

export const memberBorrowerFailure = (error) => ({
	type: memberActionTypes.MEMBER_GET_DETAILS_FAILURE,
	payload: error,
});

export const memberBorrowerChanged = () => ({
	type: memberActionTypes.MEMBER_GET_DETAILS_CHANGED,
});

export const memberBorrowerReset = () => ({
	type: memberActionTypes.MEMBER_GET_DETAILS_RESET,
});


export const memberContactFetchStart = (ContactDetails) => ({
	type: memberActionTypes.MEMBER_CONTACT_FETCH_START,
	payload: ContactDetails,
});

export const memberContactFetchSuccess = (contacts) => ({
	type: memberActionTypes.MEMBER_CONTACT_FETCH_SUCCESS,
	payload: contacts,
});

export const memberContactFetchFailure = (error) => ({
	type: memberActionTypes.MEMBER_CONTACT_FETCH_FAILURE,
	payload: error,
});

export const memberContactFetchChanged = () => ({
	type: memberActionTypes.MEMBER_CONTACT_FETCH_CHANGED,
});

export const memberContactFetchReset = () => ({
	type: memberActionTypes.MEMBER_CONTACT_FETCH_RESET,
});

export const memberBankFetchStart = (BankDetails) => ({
	type: memberActionTypes.MEMBER_BANK_FETCH_START,
	payload: BankDetails,
});

export const memberBankFetchSuccess = (bank) => ({
	type: memberActionTypes.MEMBER_BANK_FETCH_SUCCESS,
	payload: bank,
});

export const memberBankFetchFailure = (error) => ({
	type: memberActionTypes.MEMBER_BANK_FETCH_FAILURE,
	payload: error,
});

export const memberBankFetchChanged = () => ({
	type: memberActionTypes.MEMBER_BANK_FETCH_CHANGED,
});

export const memberBankFetchReset = () => ({
	type: memberActionTypes.MEMBER_BANK_FETCH_RESET,
});

export const memberBorrowerLoanDetailsStart = (MemberLoanDetails) => ({
	type: memberActionTypes.MEMBER_GET_LOAN_DETAILS_START,
	payload: MemberLoanDetails,
});

export const memberBorrowerLoanDetailsSuccess = (Staff) => ({
	type: memberActionTypes.MEMBER_GET_LOAN_DETAILS_SUCCESS,
	payload: Staff,
});

export const memberBorrowerLoanDetailsFailure = (error) => ({
	type: memberActionTypes.MEMBER_GET_LOAN_DETAILS_FAILURE,
	payload: error,
});

export const memberBorrowerLoanDetailsChanged = () => ({
	type: memberActionTypes.MEMBER_GET_LOAN_DETAILS_CHANGED,
});

export const memberBorrowerLoanDetailsReset = () => ({
	type: memberActionTypes.MEMBER_GET_LOAN_DETAILS_RESET,
});

export const memberFilterSearchStart = (MemberDetails) => ({
	type: memberActionTypes.MEMBER_GET_FILTER_SEARCH_START,
	payload: MemberDetails,
});

// REJECT SECTION

export const memberRejectStart = (MemberLoanDetails) => ({
	type: memberActionTypes.MEMBER_REJECT_START,
	payload: MemberLoanDetails,
});

export const memberRejectSuccess = (Staff) => ({
	type: memberActionTypes.MEMBER_REJECT_SUCCESS,
	payload: Staff,
});

export const memberRejectFailure = (error) => ({
	type: memberActionTypes.MEMBER_REJECT_FAILURE,
	payload: error,
});

export const memberRejectChanged = () => ({
	type: memberActionTypes.MEMBER_REJECT_CHANGED,
});

export const memberRejectReset = () => ({
	type: memberActionTypes.MEMBER_REJECT_RESET,
});

