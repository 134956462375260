/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import RepaymentReportActionTypes from './repaymentReport.types';

export const getAllRepaymentList = (data) => ({
	type: RepaymentReportActionTypes.REPAYMENT_REPORT_LIST_START,
	payload: data,
});

export const getAllRepaymentListSuccess = (data) => ({
	type: RepaymentReportActionTypes.REPAYMENT_REPORT_LIST_SUCCESS,
	payload: data,
});

export const getAllRepaymentListFailure = (error) => ({
	type: RepaymentReportActionTypes.REPAYMENT_REPORT_LIST_FAILURE,
	payload: error,
});

export const getAllRepaymentListChanged = () => ({
	type: RepaymentReportActionTypes.REPAYMENT_REPORT_LIST_CHANGED,
});

export const getAllRepaymentListReset = () => ({
	type: RepaymentReportActionTypes.REPAYMENT_REPORT_LIST_RESET,
});

export const getFilterListStart = (data) => ({
	type: RepaymentReportActionTypes.REPAYMENT_REPORT_GET_FILTER_SEARCH_LIST,
	payload: data,
});