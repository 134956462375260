/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { PAISANOW_API_URL } from '../const';

export const dashboardLoadCall = (encodeData) => {
	const data = {
		DashboardTokenData: encodeData,
	};
	const url = `${PAISANOW_API_URL}DashboardLoadDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// COLLECTION SECTION
export const dashboardCollectionCall = (encodeData) => {
	const data = {
		DashboardTokenData: encodeData,
	};
	const url = `${PAISANOW_API_URL}DashboardCollectionDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};


// COLLECTION SECTION TABLE
export const dashboardCollectionTableCall = (encodeData) => {
	const data = {
		DashboardTokenData: encodeData,
	};
	const url = `${PAISANOW_API_URL}DashboardCollectionTableDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
