/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import repaymentReportActionTypes from './repaymentReport.types';
import generalTypesAction from '../genera.types';
import { getEndodeJsonData } from '../common/common.utils';

const INITIAL_STATE = {
	allRepaymentReportList: null,
	allRepaymentReportListStatus: null,
};

// eslint-disable-next-line default-param-last
const repaymentReportReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case repaymentReportActionTypes.REPAYMENT_REPORT_LIST_SUCCESS:
			return {
				...state,
				allRepaymentReportList: getEndodeJsonData(
					action.payload,
					generalTypesAction.REPAYMENT,
				),
				allRepaymentReportListStatus: 'Success',
			};
		case repaymentReportActionTypes.REPAYMENT_REPORT_LIST_FAILURE:
			return {
				...state,
				allRepaymentReportList: null,
				allRepaymentReportListStatus: 'Failure',
			};
		case repaymentReportActionTypes.REPAYMENT_REPORT_LIST_CHANGED:
			return { ...state, allRepaymentReportListStatus: null };

		default:
			return state;
	}
};

export default repaymentReportReducer;
