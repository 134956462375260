/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { PAISANOW_API_URL } from '../const';

export const createDepartment = (deptName, payrollID, databaseName) => {
	debugger;
	const data = {
		DepartmentName: deptName,
		PayrollID: payrollID,
		DatabaseName: databaseName,
	};
	const url = `${PAISANOW_API_URL}CreateDepartment`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateDepartment = (deptName, payrollID, databaseName, deptID) => {
	debugger;
	const data = {
		DepartmentName: deptName,
		PayrollID: payrollID,
		DatabaseName: databaseName,
	};
	const url = `${PAISANOW_API_URL}UpdateDepartment/${deptID}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteDepartment = (databaseName, deptID) => {
	const data = {
		DatabaseName: databaseName,
	};
	const url = `${PAISANOW_API_URL}DeleteDepartment/${deptID}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getAllDepartment = (databaseName, searchData, limit, payrollID) => {
	debugger;
	const data = {
		Limit: limit,
		PayrollID: payrollID,
		SearchData: searchData,
		DatabaseName: databaseName,
	};
	const url = `${PAISANOW_API_URL}GetAllDepartmentWithLimit`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
