/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import loanIndexActionTypes from './loanIndex.types';
import { calculateSchedule } from './loanIndex.utils';

const INITIAL_STATE = {
	loanLimitStatus: null,
	loanLimitResponse: null,
	loanDisbursementStatus: null,
	loanDisbursementResponse: null,
	groupLoanLimitStatus: null,
	groupLoanLimitResponse: null,
	loanScheduleDetails: null,
	loanProductStatus: null,
	loanProductResponse: null,
};

// eslint-disable-next-line default-param-last
const loanIndexReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case loanIndexActionTypes.SET_LOAN_LIMIT_SUCCESS:
			return { ...state, loanLimitResponse: action.payload, loanLimitStatus: 'Success' };
		case loanIndexActionTypes.SET_LOAN_LIMIT_FAILURE:
			return { ...state, loanLimitResponse: action.payload, loanLimitStatus: 'Failure' };
		case loanIndexActionTypes.SET_LOAN_LIMIT_CHANGED:
			return { ...state, loanLimitResponse: null, loanLimitStatus: null };
		case loanIndexActionTypes.LOAN_DISBURSEMENT_OFFLINE_SUCCESS:
			return {
				...state,
				loanDisbursementResponse: action.payload,
				loanDisbursementStatus: 'Success',
			};
		case loanIndexActionTypes.LOAN_DISBURSEMENT_OFFLINE_FAILURE:
			return {
				...state,
				loanDisbursementResponse: action.payload,
				loanDisbursementStatus: 'Failure',
			};
		case loanIndexActionTypes.LOAN_DISBURSEMENT_OFFLINE_CHANGED:
			return { ...state, loanDisbursementResponse: null, loanDisbursementStatus: null };

		case loanIndexActionTypes.SET_LOAN_LIMIT_GROUP_SUCCESS:
			return { ...state, groupLoanLimitResponse: action.payload, groupLoanLimitStatus: 'Success' };
		case loanIndexActionTypes.SET_LOAN_LIMIT_GROUP_FAILURE:
			return { ...state, groupLoanLimitResponse: action.payload, groupLoanLimitStatus: 'Failure' };
		case loanIndexActionTypes.SET_LOAN_LIMIT_GROUP_CHANGED:
			return { ...state, groupLoanLimitResponse: null, groupLoanLimitStatus: null };


		case loanIndexActionTypes.LOAN_REQUEST_CALCULATION:
			return {
				...state,
				loanScheduleDetails: calculateSchedule(action.payload),
			};

		case loanIndexActionTypes.SET_LOAN_PRODUCT_SUCCESS:
			return { ...state, loanProductResponse: action.payload, loanProductStatus: 'Success' };
		case loanIndexActionTypes.SET_LOAN_PRODUCT_FAILURE:
			return { ...state, loanProductResponse: action.payload, loanProductStatus: 'Failure' };
		case loanIndexActionTypes.SET_LOAN_PRODUCT_CHANGED:
			return { ...state, loanProductStatus: null };
		default:
			return state;
	}
};

export default loanIndexReducer;
