/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { PAISANOW_API_URL } from '../const';

export const getAllRepaymentReport = (RepaymentReportData) => {
	const data = {
		RepaymentReportToken: RepaymentReportData,
	};
	const url = `${PAISANOW_API_URL}GetRepaymentReport`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
