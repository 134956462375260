/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { PAISANOW_API_URL } from '../const';

export const createLoanLimit = (loanLimitData) => {
	const data = {
		LoanLimitToken: loanLimitData,
	};
	const url = `${PAISANOW_API_URL}AddLoanLimit`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createGroupLoanLimit = (loanLimitData) => {
	const data = {
		LoanLimitToken: loanLimitData,
	};
	const url = `${PAISANOW_API_URL}AddGroupLoanLimit`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const loanRequestCreate = (loanRequestData) => {
	const data = {
		LoanRequestToken: loanRequestData,
	};
	const url = `${PAISANOW_API_URL}AddLoanRequest`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createLoanProduct = (loanProductData) => {
	const data = {
		LoanProductToken: loanProductData,
	};
	const url = `${PAISANOW_API_URL}AddLoanProduct`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};