/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import cbCheckActionTypes from './cbCheck.types';

export const getBorrowerCBStart = (MemberDetails) => ({
	type: cbCheckActionTypes.MEMBER_GET_CBCHECK_START,
	payload: MemberDetails,
});

export const getBorrowerCBSuccess = (Staff) => ({
	type: cbCheckActionTypes.MEMBER_GET_CBCHECK_SUCCESS,
	payload: Staff,
});

export const getBorrowerCBFailure = (error) => ({
	type: cbCheckActionTypes.MEMBER_GET_CBCHECK_FAILURE,
	payload: error,
});

export const getBorrowerCBChanged = () => ({
	type: cbCheckActionTypes.MEMBER_GET_CBCHECK_CHANGED,
});

export const getBorrowerCBReset = () => ({
	type: cbCheckActionTypes.MEMBER_GET_CBCHECK_RESET,
});


export const getBorrowerCRIFStart = (MemberDetails) => ({
	type: cbCheckActionTypes.MEMBER_GET_CRIF_START,
	payload: MemberDetails,
});

export const getBorrowerCRIFSuccess = (Staff) => ({
	type: cbCheckActionTypes.MEMBER_GET_CRIF_SUCCESS,
	payload: Staff,
});

export const getBorrowerCRIFFailure = (error) => ({
	type: cbCheckActionTypes.MEMBER_GET_CRIF_FAILURE,
	payload: error,
});

export const getBorrowerCRIFChanged = () => ({
	type: cbCheckActionTypes.MEMBER_GET_CRIF_CHANGED,
});

export const getBorrowerCRIFReset = () => ({
	type: cbCheckActionTypes.MEMBER_GET_CRIF_RESET,
});


// CHECK CRIF HIGHMARK
export const checkMemberCRIFStart = (MemberDetails) => ({
	type: cbCheckActionTypes.MEMBER_CHECK_CRIF_START,
	payload: MemberDetails,
});

export const checkMemberCRIFSuccess = (Staff) => ({
	type: cbCheckActionTypes.MEMBER_CHECK_CRIF_SUCCESS,
	payload: Staff,
});

export const checkMemberCRIFFailure = (error) => ({
	type: cbCheckActionTypes.MEMBER_CHECK_CRIF_FAILURE,
	payload: error,
});

export const checkMemberCRIFChanged = () => ({
	type: cbCheckActionTypes.MEMBER_CHECK_CRIF_CHANGED,
});

export const checkMemberCRIFReset = () => ({
	type: cbCheckActionTypes.MEMBER_CHECK_CRIF_RESET,
});

// FETCH HIGH MARK
export const getMemberHMStart = (MemberDetails) => ({
	type: cbCheckActionTypes.MEMBER_GET_HIGHMARK_START,
	payload: MemberDetails,
});

export const getMemberHMSuccess = (Staff) => ({
	type: cbCheckActionTypes.MEMBER_GET_HIGHMARK_SUCCESS,
	payload: Staff,
});

export const getMemberHMFailure = (error) => ({
	type: cbCheckActionTypes.MEMBER_GET_HIGHMARK_FAILURE,
	payload: error,
});

export const getMemberHMChanged = () => ({
	type: cbCheckActionTypes.MEMBER_GET_HIGHMARK_CHANGED,
});

export const getMemberHMReset = () => ({
	type: cbCheckActionTypes.MEMBER_GET_HIGHMARK_RESET,
});