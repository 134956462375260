/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import blockChainActionTypes from './blockChain.types';
import { createBlockChainLoanApproval } from './blockChain.services';
// import generalTypesAction from '../genera.types';
import {
	memberBlockChainLoanApprovalSuccess,
	memberBlockChainLoanApprovalFailure,
	memberBlockChainLoanApprovalChanged,
} from './blockChain.actions';
import { encodeJson } from '../enode-decode';

export function* memberBlockChainLoanApproval({ payload }) {
	try {
		const jsonData = {
			MemberID: payload.memberID,
			User: payload.staffID,
		};
		const memberBankData = encodeJson(jsonData, 'BlockChainLoanApproval');
		const responseData = yield call(createBlockChainLoanApproval, memberBankData);
		if (responseData.data.statusCode === '100') {
			yield put(memberBlockChainLoanApprovalFailure(responseData.data));
		} else {
			yield put(memberBlockChainLoanApprovalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(memberBlockChainLoanApprovalFailure(error));
	}
}

export function* memberBlockChainLoanApprovalReset() {
	yield put(memberBlockChainLoanApprovalChanged());
}

export function* blockChainLoanApproval() {
	yield takeLatest(
		blockChainActionTypes.MEMBER_BLOCK_CHAIN_LOAN_APPROVAL_START,
		memberBlockChainLoanApproval,
	);
}

export function* blockChainLoanApprovalReset() {
	yield takeLatest(
		blockChainActionTypes.MEMBER_BLOCK_CHAIN_LOAN_APPROVAL_RESET,
		memberBlockChainLoanApprovalReset,
	);
}

export function* blockChainSaga() {
	yield all([call(blockChainLoanApproval), call(blockChainLoanApprovalReset)]);
}
