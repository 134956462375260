/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import compareActionTypes from './compare.types';

export const getAllList = (Compare) => ({
	type: compareActionTypes.COMPARE_LIST_START,
	payload: Compare,
});

export const getAllListSuccess = (Compare) => ({
	type: compareActionTypes.COMPARE_LIST_SUCCESS,
	payload: Compare,
});

export const getAllListFailure = (error) => ({
	type: compareActionTypes.COMPARE_LIST_FAILURE,
	payload: error,
});

export const getAllListChanged = () => ({
	type: compareActionTypes.COMPARE_LIST_CHANGED,
});

export const getAllListReset = () => ({
	type: compareActionTypes.COMPARE_LIST_RESET,
});

export const getCompareMemberDetailsStart = (Compare) => ({
	type: compareActionTypes.COMPARE_MEMBER_DETAILS_START,
	payload: Compare,
});

export const getCompareMemberDetailsSuccess = (Compare) => ({
	type: compareActionTypes.COMPARE_MEMBER_DETAILS_SUCCESS,
	payload: Compare,
});

export const getCompareMemberDetailsFailure = (error) => ({
	type: compareActionTypes.COMPARE_MEMBER_DETAILS_FAILURE,
	payload: error,
});

export const getCompareMemberDetailsChanged = () => ({
	type: compareActionTypes.COMPARE_MEMBER_DETAILS_CHANGED,
});

export const getCompareMemberDetailsReset = () => ({
	type: compareActionTypes.COMPARE_MEMBER_DETAILS_RESET,
});

export const getSelectedCustomerID = (CustomerData) => ({
	type: compareActionTypes.COMPARE_LIST_ID,
	payload: CustomerData,
});

export const selectedMemberRemarks = (CustomerData) => ({
	type: compareActionTypes.COMPARE_REMARKS_VALUES,
	payload: CustomerData,
});

export const compareRemarksStart = (remarksDetails) => ({
	type: compareActionTypes.COMPARE_REMARKS_LOG_START,
	payload: remarksDetails,
});

export const compareRemarksSuccess = (Compare) => ({
	type: compareActionTypes.COMPARE_REMARKS_LOG_SUCCESS,
	payload: Compare,
});

export const compareRemarksFailure = (error) => ({
	type: compareActionTypes.COMPARE_REMARKS_LOG_FAILURE,
	payload: error,
});

export const compareRemarksChanged = () => ({
	type: compareActionTypes.COMPARE_REMARKS_LOG_CHANGED,
});

export const compareRemarksReset = () => ({
	type: compareActionTypes.COMPARE_REMARKS_LOG_RESET,
});


export const compareFilterSearchStart = (searchVal) => ({
	type: compareActionTypes.COMPARE_FILTER_SEARCH_START,
	payload: searchVal,
});

export const compareLeaderDataStore = (storeVal) => ({
	type: compareActionTypes.COMPARE_LEADER_DATA_STORE,
	payload: storeVal,
});

export const compareLeaderDataStoreReset = () => ({
	type: compareActionTypes.COMPARE_LEADER_DATA_STORE_RESET,
});

export const compareConfirmationListStart = (compareGroup) => ({
	type: compareActionTypes.COMPARE_CONFIRMATINO_RESPONSE_START,
	payload: compareGroup,
});

export const compareConfirmationListSuccess = (success) => ({
	type: compareActionTypes.COMPARE_CONFIRMATINO_RESPONSE_SUCCESS,
	payload: success,
});

export const compareConfirmationListFailure = (error) => ({
	type: compareActionTypes.COMPARE_CONFIRMATINO_RESPONSE_FAILURE,
	payload: error,
});

export const compareConfirmationListChanged = () => ({
	type: compareActionTypes.COMPARE_CONFIRMATINO_RESPONSE_CHANGED,
});

export const compareConfirmationListReset = () => ({
	type: compareActionTypes.COMPARE_CONFIRMATINO_RESPONSE_RESET,
});

export const compareRejectMemberStart = (RejectMemberDetails) => ({
	type: compareActionTypes.COMPARE_REJECT_MEMBER_START,
	payload: RejectMemberDetails,
});

export const compareRejectMemberSuccess = (success) => ({
	type: compareActionTypes.COMPARE_REJECT_MEMBER_SUCCESS,
	payload: success,
});

export const compareRejectMemberFailure = (error) => ({
	type: compareActionTypes.COMPARE_REJECT_MEMBER_FAILURE,
	payload: error,
});

export const compareRejectMemberChanged = () => ({
	type: compareActionTypes.COMPARE_REJECT_MEMBER_CHANGED,
});

export const compareRejectMemberReset = () => ({
	type: compareActionTypes.COMPARE_REJECT_MEMBER_RESET,
});

// SQUARENOW SECTION

export const getCompareSQMemberDetailsStart = (Compare) => ({
	type: compareActionTypes.COMPARE_SQ_MEMBER_DETAILS_START,
	payload: Compare,
});

export const getCompareSQMemberDetailsSuccess = (Compare) => ({
	type: compareActionTypes.COMPARE_SQ_MEMBER_DETAILS_SUCCESS,
	payload: Compare,
});

export const getCompareSQMemberDetailsFailure = (error) => ({
	type: compareActionTypes.COMPARE_SQ_MEMBER_DETAILS_FAILURE,
	payload: error,
});

export const getCompareSQMemberDetailsChanged = () => ({
	type: compareActionTypes.COMPARE_SQ_MEMBER_DETAILS_CHANGED,
});

export const getCompareSQMemberDetailsReset = () => ({
	type: compareActionTypes.COMPARE_SQ_MEMBER_DETAILS_RESET,
});