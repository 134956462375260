/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
// import JWT from 'expo-jwt';
import compareActionTypes from './disbursement.types';
import generalTypesAction from '../genera.types';
import {
	getAllCompare,
	getCompareDetails,
	addMemberRemarks,
	getConfirmationDetails,
	rejectMember,
	getSQCompareDetails,
	disbApprove,
	disbReport,
} from './disbursement.services';
import {
	getAllListSuccess,
	getAllListFailure,
	getAllListChanged,
	getDisbursementMemberDetailsSuccess,
	getDisbursementMemberDetailsFailure,
	getDisbursementMemberDetailsChanged,
	disbursementRemarksSuccess,
	disbursementRemarksFailure,
	disbursementRemarksChanged,
	disbursementConfirmationListSuccess,
	disbursementConfirmationListFailure,
	disbursementConfirmationListChanged,
	disbursementRejectMemberSuccess,
	disbursementRejectMemberFailure,
	disbursementRejectMemberChanged,
	getDisbursementSQMemberDetailsSuccess,
	getDisbursementSQMemberDetailsFailure,
	getDisbursementSQMemberDetailsChanged,
	disbApproveMemberSuccess,
	disbApproveMemberFailure,
	disbApproveMemberChanged,
	disbursementReportSuccess,
	disbursementReportFailure,
	disbursementReportChanged,
} from './disbursement.actions';
import { encodeJson } from '../enode-decode';

export function* createListOfDisbursement({ payload }) {
	try {
		const jsonData = {
			Limit: payload.limit,
			isCustomer: 0,
			CompanyID: payload.companyID,
			From: 'DISBURSEMENT',
			ActiveStatus: payload.activeStatus,
		};
		const encodeData = encodeJson(jsonData, generalTypesAction.MEMBER);
		const responseData = yield call(getAllCompare, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllListFailure(responseData.data));
		} else {
			yield put(getAllListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getAllListFailure(error));
	}
}

export function* createListOfCompareReset() {
	yield put(getAllListChanged());
}

export function* compareDetailStart({ payload: { memberID, companyID } }) {
	try {
		const jsonData = {
			MemberID: memberID,
			CompanyID: companyID,
		};
		const encodeData = encodeJson(jsonData, generalTypesAction.MEMBER);
		const responseData = yield call(getCompareDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getDisbursementMemberDetailsFailure(responseData.data));
		} else {
			yield put(getDisbursementMemberDetailsSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getDisbursementMemberDetailsFailure(error));
	}
}

export function* compareDetailReset() {
	yield put(getDisbursementMemberDetailsChanged());
}

export function* createRemarksLog({ payload: { compareRemarks } }) {
	try {
		const jsonData = {
			RemarksArr: JSON.stringify(compareRemarks.logs).replace(/"/g, "'"),
			MemberID: compareRemarks.memberID,
			UserID: compareRemarks.userID,
			CompanyID: compareRemarks.companyID,
		};
		const encodeData = encodeJson(jsonData, generalTypesAction.REMARKS);
		const responseData = yield call(addMemberRemarks, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(disbursementRemarksFailure(responseData.data));
		} else {
			yield put(disbursementRemarksSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(disbursementRemarksFailure(error));
	}
}

export function* createRemarksLogReset() {
	yield put(disbursementRemarksChanged());
}

export function* createFilterListOfCompare({ payload: { searchVal, companyID } }) {
	try {
		const jsonData = {
			SearchVal: searchVal,
			CompanyID: companyID,
		};
		const encodeData = encodeJson(jsonData, generalTypesAction.MEMBER);
		const responseData = yield call(getAllCompare, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllListFailure(responseData.data));
		} else {
			yield put(getAllListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getAllListFailure(error));
	}
}

export function* createConfirmationList({ payload }) {
	try {
		const jsonData = {
			GroupID: payload.groupID,
			CompanyID: payload.companyID,
		};
		const encodeData = encodeJson(jsonData, generalTypesAction.MEMBER);
		const responseData = yield call(getConfirmationDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(disbursementConfirmationListFailure(responseData.data));
		} else {
			yield put(disbursementConfirmationListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(disbursementConfirmationListFailure(error));
	}
}

export function* createConfirmationListReset() {
	yield put(disbursementConfirmationListChanged());
}

export function* startRejectMember({ payload: { rejectMemberData } }) {
	try {
		const jsonData = {
			MemberID: rejectMemberData.memberID,
			GroupID: rejectMemberData.groupID,
			RejectNotes: rejectMemberData.rejectNotes,
			UserID: rejectMemberData.userID,
			CompanyID: rejectMemberData.companyID,
		};
		const RejectData = encodeJson(jsonData, generalTypesAction.MEMBER);
		const responseData = yield call(rejectMember, RejectData);
		if (responseData.data.statusCode === '100') {
			yield put(disbursementRejectMemberFailure(responseData.data));
		} else {
			yield put(disbursementRejectMemberSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(disbursementRejectMemberFailure(error));
	}
}

export function* startRejectMemberReset() {
	yield put(disbursementRejectMemberChanged());
}

export function* getAllDisbursementList() {
	yield takeLatest(compareActionTypes.DISBURSEMENT_LIST_START, createListOfDisbursement);
}

export function* getAllDisbursementListReset() {
	yield takeLatest(compareActionTypes.DISBURSEMENT_LIST_RESET, createListOfCompareReset);
}

export function* getDisbursementDetail() {
	yield takeLatest(compareActionTypes.DISBURSEMENT_MEMBER_DETAILS_START, compareDetailStart);
}

export function* getDisbursementDetailReset() {
	yield takeLatest(compareActionTypes.DISBURSEMENT_MEMBER_DETAILS_RESET, compareDetailReset);
}

export function* disbursementRemarksLogStart() {
	yield takeLatest(compareActionTypes.DISBURSEMENT_REMARKS_LOG_START, createRemarksLog);
}

export function* disbursementRemarksLogReset() {
	yield takeLatest(compareActionTypes.DISBURSEMENT_REMARKS_LOG_RESET, createRemarksLogReset);
}

export function* getFilterCompareList() {
	yield takeLatest(compareActionTypes.DISBURSEMENT_FILTER_SEARCH_START, createFilterListOfCompare);
}

export function* disbursementConfirmationListStart() {
	yield takeLatest(
		compareActionTypes.DISBURSEMENT_CONFIRMATINO_RESPONSE_START,
		createConfirmationList,
	);
}

export function* disbursementConfirmationListReset() {
	yield takeLatest(
		compareActionTypes.DISBURSEMENT_CONFIRMATINO_RESPONSE_RESET,
		createConfirmationListReset,
	);
}

export function* disbursementRejectMemberStart() {
	yield takeLatest(compareActionTypes.DISBURSEMENT_REJECT_MEMBER_START, startRejectMember);
}

export function* disbursementRejectMemberReset() {
	yield takeLatest(compareActionTypes.DISBURSEMENT_REJECT_MEMBER_RESET, startRejectMemberReset);
}

// SQ SECTION

export function* compareSQDetailStart({ payload }) {
	try {
		const jsonData = {
			MemberID: payload.memberID,
			CompanyID: payload.companyID,
			LinkCompanyID: payload.linkCompanyID,
			UserID: payload.userID,
			DatabaseName: payload.databasename,
		};
		const encodeData = encodeJson(jsonData, generalTypesAction.MEMBER);
		const responseData = yield call(getSQCompareDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getDisbursementSQMemberDetailsFailure(responseData.data));
		} else {
			yield put(getDisbursementSQMemberDetailsSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getDisbursementSQMemberDetailsFailure(error));
	}
}

export function* compareSQDetailReset() {
	yield put(getDisbursementSQMemberDetailsChanged());
}
export function* getSQDisbursementDetail() {
	yield takeLatest(compareActionTypes.DISBURSEMENT_SQ_MEMBER_DETAILS_START, compareSQDetailStart);
}

export function* getSQDisbursementDetailReset() {
	yield takeLatest(compareActionTypes.DISBURSEMENT_SQ_MEMBER_DETAILS_RESET, compareSQDetailReset);
}

// DISBURSEMENT APPROVE SECTION
export function* startDisbApproveMember({ payload }) {
	try {
		const jsonData = {
			MemberID: payload.memberID,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			AcceptedID: payload.AcceptedCompanyID,
			LoanRequestID: payload.LoanRequestID,
			Status: payload.LoanStatus,
		};
		const encodeData = encodeJson(jsonData, generalTypesAction.LOANINDEX);
		const responseData = yield call(disbApprove, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(disbApproveMemberFailure(responseData.data));
		} else {
			yield put(disbApproveMemberSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(disbApproveMemberFailure(error));
	}
}

export function* startDisbApproveMemberReset() {
	yield put(disbApproveMemberChanged());
}
export function* disbAproveMemberStart() {
	yield takeLatest(compareActionTypes.DISBURSEMENT_MEMBER_START, startDisbApproveMember);
}

export function* disbAproveMemberReset() {
	yield takeLatest(compareActionTypes.DISBURSEMENT_MEMBER_RESET, startDisbApproveMemberReset);
}

// REPORT SECTION
export function* startDisbReport({ payload }) {
	try {
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			BranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, generalTypesAction.DISBUSREMENT);
		const responseData = yield call(disbReport, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(disbursementReportFailure(responseData.data));
		} else {
			yield put(disbursementReportSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(disbursementReportFailure(error));
	}
}

export function* startDisbReportReset() {
	yield put(disbursementReportChanged());
}
export function* disbReportStart() {
	yield takeLatest(compareActionTypes.DISBURSEMENT_REPORT_START, startDisbReport);
}

export function* disbReportReset() {
	yield takeLatest(compareActionTypes.DISBURSEMENT_REPORT_RESET, startDisbReportReset);
}

export function* disbursementSaga() {
	yield all([
		call(getAllDisbursementList),
		call(getAllDisbursementListReset),
		call(getDisbursementDetail),
		call(getDisbursementDetailReset),
		call(disbursementRemarksLogStart),
		call(disbursementRemarksLogReset),
		call(getFilterCompareList),
		call(disbursementConfirmationListStart),
		call(disbursementConfirmationListReset),
		call(disbursementRejectMemberStart),
		call(disbursementRejectMemberReset),
		// SQ SECTION
		call(getSQDisbursementDetail),
		call(getSQDisbursementDetailReset),
		// DISBURSEMENT APPROVE SECTION
		call(disbAproveMemberStart),
		call(disbAproveMemberReset),
		// REPORT SECTION
		call(disbReportStart),
		call(disbReportReset),
	]);
}
