/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import departmentActionTypes from './department.types';

const INITIAL_STATE = {
	departmentStatus: null,
	departmentResponse: null,
	departmentDeleteRes: null,
	allDepartmentList: null,
};

const departmentReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case departmentActionTypes.DEPARTMENT_CREATE_REPSONSE_SUCCESS:
			return { ...state, departmentResponse: action.payload, departmentStatus: 'Success' };

		case departmentActionTypes.DEPARTMENT_CREATE_REPSONSE_FAILURE:
			return { ...state, departmentResponse: action.payload, departmentStatus: 'Failure' };

		case departmentActionTypes.DEPARTMENT_CREATE_REPSONSE_CHANGED:
			return { ...state, departmentResponse: null, departmentStatus: null };
		case departmentActionTypes.DEPARTMENT_UPDATE_REPSONSE_SUCCESS:
			return { ...state, departmentResponse: action.payload, departmentStatus: 'Success' };

		case departmentActionTypes.DEPARTMENT_UPDATE_REPSONSE_FAILURE:
			return { ...state, departmentResponse: action.payload, departmentStatus: 'Failure' };

		case departmentActionTypes.DEPARTMENT_UPDATE_REPSONSE_CHANGED:
			return { ...state, departmentResponse: null, departmentStatus: null };
		case departmentActionTypes.DEPARTMENT_DELETE_REPSONSE_SUCCESS:
			return { ...state, departmentDeleteRes: action.payload, departmentStatus: 'Success' };

		case departmentActionTypes.DEPARTMENT_DELETE_REPSONSE_FAILURE:
			return { ...state, departmentDeleteRes: action.payload, departmentStatus: 'Failure' };
		case departmentActionTypes.DEPARTMENT_DELETE_REPSONSE_CHANGED:
			return { ...state, departmentDeleteRes: null, departmentStatus: null };
		case departmentActionTypes.DEPARTMENT_LIST_SUCCESS:
			return { ...state, allDepartmentList: action.payload, departmentStatus: 'Success' };
		case departmentActionTypes.DEPARTMENT_LIST_FAILURE:
			return { ...state, allDepartmentList: action.payload, departmentStatus: 'Success' };
		default:
			return state;
	}
};

export default departmentReducer;
