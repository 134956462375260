/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import staffActionTypes from './staff.types';

export const createStaffSuccess = (Staff) => ({
	type: staffActionTypes.STAFF_CREATE_REPSONSE_SUCCESS,
	payload: Staff,
});

export const createStaffFailure = (error) => ({
	type: staffActionTypes.STAFF_CREATE_REPSONSE_FAILURE,
	payload: error,
});

export const createStaffStart = (StaffDetails) => ({
	type: staffActionTypes.STAFF_CREATE_REPSONSE_START,
	payload: StaffDetails,
});

export const staffCreateChanged = () => ({
	type: staffActionTypes.STAFF_CREATE_REPSONSE_CHANGED,
});

export const staffCreateReset = () => ({
	type: staffActionTypes.STAFF_CREATE_REPSONSE_RESET,
});

export const updateStaffSuccess = (Staff) => ({
	type: staffActionTypes.STAFF_UPDATE_REPSONSE_SUCCESS,
	payload: Staff,
});

export const updateStaffFailure = (error) => ({
	type: staffActionTypes.STAFF_UPDATE_REPSONSE_FAILURE,
	payload: error,
});

export const updateStaffStart = (StaffDetails) => ({
	type: staffActionTypes.STAFF_UPDATE_REPSONSE_START,
	payload: StaffDetails,
});

export const staffUpdateChanged = () => ({
	type: staffActionTypes.STAFF_UPDATE_REPSONSE_CHANGED,
});

export const staffUpdateReset = () => ({
	type: staffActionTypes.STAFF_UPDATE_REPSONSE_RESET,
});

export const deleteStaffSuccess = (Staff) => ({
	type: staffActionTypes.STAFF_DELETE_REPSONSE_SUCCESS,
	payload: Staff,
});

export const deleteStaffFailure = (error) => ({
	type: staffActionTypes.STAFF_DELETE_REPSONSE_FAILURE,
	payload: error,
});

export const deleteStaffStart = (StaffDetails) => ({
	type: staffActionTypes.STAFF_DELETE_REPSONSE_START,
	payload: StaffDetails,
});

export const staffDeleteChanged = () => ({
	type: staffActionTypes.STAFF_DELETE_REPSONSE_CHANGED,
});

export const staffDeleteReset = () => ({
	type: staffActionTypes.STAFF_DELETE_REPSONSE_RESET,
});

export const getAllList = (Staff) => ({
	type: staffActionTypes.STAFF_LIST_START,
	payload: Staff,
});

export const getAllListSuccess = (Staff) => ({
	type: staffActionTypes.STAFF_LIST_SUCCESS,
	payload: Staff,
});

export const getAllListFailure = (error) => ({
	type: staffActionTypes.STAFF_LIST_FAILURE,
	payload: error,
});

export const getAllListChanged = () => ({
	type: staffActionTypes.STAFF_LIST_CHANGED,
});

export const getAllListReset = () => ({
	type: staffActionTypes.STAFF_LIST_RESET,
});

export const getFilterListStart = (StaffDetails) => ({
	type: staffActionTypes.STAFF_GET_FILTER_SEARCH_LIST,
	payload: StaffDetails,
});