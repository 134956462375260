/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import branchActionTypes from './branch.types';
import generalTypesAction from '../genera.types';
import { getEndodeJsonData } from '../common/common.utils';

const INITIAL_STATE = {
	branchCreateStatus: null,
	branchCreateResponse: null,
	branchUpdateStatus: null,
	branchUpdateResponse: null,
	branchDeleteStatus: null,
	branchDeleteResponse: null,
	allBranchList: null,
	allBranchStatus: null,
	branchSelectedData: null,
	postCodeStatus: null,
	postCodeResponse: null,
	branchPinCodeStatus: null,
	branchPinCodeResponse: null,
	branchPinCodeDeleteStatus: null,
	branchPinCodeDeleteResponse: null,
	allBranchPinCodeList: null,
};

// eslint-disable-next-line default-param-last
const branchReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case branchActionTypes.BRANCH_CREATE_REPSONSE_SUCCESS:
			return {
				...state,
				branchCreateResponse: action.payload,
				branchCreateStatus: 'Success',
			};
		case branchActionTypes.BRANCH_CREATE_REPSONSE_FAILURE:
			return {
				...state,
				branchCreateResponse: action.payload,
				branchCreateStatus: 'Failure',
			};
		case branchActionTypes.BRANCH_CREATE_REPSONSE_CHANGED:
			return { ...state, branchCreateResponse: null, branchCreateStatus: null };

		case branchActionTypes.BRANCH_UPDATE_REPSONSE_SUCCESS:
			return {
				...state,
				branchUpdateResponse: action.payload,
				branchUpdateStatus: 'Success',
			};
		case branchActionTypes.BRANCH_UPDATE_REPSONSE_FAILURE:
			return {
				...state,
				branchUpdateResponse: action.payload,
				branchUpdateStatus: 'Failure',
			};
		case branchActionTypes.BRANCH_UPDATE_REPSONSE_CHANGED:
			return { ...state, branchUpdateResponse: null, branchUpdateStatus: null };

		case branchActionTypes.BRANCH_DELETE_REPSONSE_SUCCESS:
			return {
				...state,
				branchDeleteResponse: action.payload,
				branchDeleteStatus: 'Success',
			};
		case branchActionTypes.BRANCH_DELETE_REPSONSE_FAILURE:
			return {
				...state,
				branchDeleteResponse: action.payload,
				branchDeleteStatus: 'Failure',
			};
		case branchActionTypes.BRANCH_DELETE_REPSONSE_CHANGED:
			return { ...state, branchDeleteResponse: null, branchDeleteStatus: null };

		case branchActionTypes.BRANCH_LIST_SUCCESS:
			return {
				...state,
				allBranchList: getEndodeJsonData(action.payload, generalTypesAction.BRANCH),
				allBranchStatus: 'Success',
			};
		case branchActionTypes.BRANCH_LIST_FAILURE:
			return { ...state, allBranchList: null, allBranchStatus: 'Failure' };
		case branchActionTypes.BRANCH_LIST_CHANGED:
			return { ...state, allBranchStatus: null };

		case branchActionTypes.BRANCH_SELECTED_ID:
			return { ...state, branchSelectedData: action.payload };

		case branchActionTypes.POSTCODE_CREATE_RESPONSE_SUCCESS:
			return { ...state, postCodeResponse: action.payload, postCodeStatus: 'Success' };

		case branchActionTypes.POSTCODE_CREATE_RESPONSE_FAILURE:
			return { ...state, postCodeStatus: 'Failure' };

		case branchActionTypes.POSTCODE_CREATE_RESPONSE_CHANGED:
			return { ...state, postCodeResponse: null, postCodeStatus: null };

		case branchActionTypes.BRANCH_PINCODE_RESPONSE_SUCCESS:
			return {
				...state,
				branchPinCodeResponse: action.payload,
				branchPinCodeStatus: 'Success',
			};
		case branchActionTypes.BRANCH_PINCODE_RESPONSE_FAILURE:
			return {
				...state,
				branchPinCodeResponse: action.payload,
				branchPinCodeStatus: 'Failure',
			};
		case branchActionTypes.BRANCH_PINCODE_RESPONSE_CHANGED:
			return { ...state, branchPinCodeResponse: null, branchPinCodeStatus: null };

		case branchActionTypes.BRANCH_PINCODE_DELETE_SUCCESS:
			return {
				...state,
				branchPinCodeDeleteResponse: action.payload,
				branchPinCodeDeleteStatus: 'Success',
			};
		case branchActionTypes.BRANCH_PINCODE_DELETE_FAILURE:
			return {
				...state,
				branchPinCodeDeleteResponse: action.payload,
				branchPinCodeDeleteStatus: 'Failure',
			};
		case branchActionTypes.BRANCH_PINCODE_DELETE_CHANGED:
			return { ...state, branchPinCodeDeleteResponse: null, branchPinCodeDeleteStatus: null };

		case branchActionTypes.BRANCH_PINCODE_LIST_SUCCESS:
			return {
				...state,
				allBranchPinCodeList: action.payload,
			};
		case branchActionTypes.BRANCH_PINCODE_LIST_FAILURE:
			return {
				...state,
				allBranchPinCodeList: action.payload,
			};
		case branchActionTypes.BRANCH_PINCODE_LIST_CHANGED:
			return { ...state, allBranchPinCodeList: null };

		default:
			return state;
	}
};

export default branchReducer;
