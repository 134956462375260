/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import userTypeActionTypes from './userType.types';
import generalTypesAction from '../genera.types';
import {
	createUserType,
	getAllUserType,
	updateUserType,
	deleteUserType,
} from './userType.services';
import {
	getAllUserTypeListSuccess,
	getAllUserTypeListFailure,
	getAllUserTypeListChanged,
	createUserTypeSuccess,
	createUserTypeFailure,
	userTypeCreateChanged,
	updateUserTypeSuccess,
	updateUserTypeFailure,
	userTypeUpdateChanged,
	deleteUserTypeSuccess,
	deleteUserTypeFailure,
	userTypeDeleteChanged,
} from './userType.actions';
import { encodeJson } from '../enode-decode';

export function* createNewUserType({ payload }) {
	try {
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserType: payload.userType,
		};
		const UserTypeData = encodeJson(jsonData, generalTypesAction.USERTYPE);
		const responseData = yield call(createUserType, UserTypeData);
		if (responseData.data.statusCode === '100') {
			yield put(createUserTypeFailure(responseData.data));
		} else {
			yield put(createUserTypeSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createUserTypeFailure(error));
	}
}

export function* updateUserTypeDetails({ payload }) {
	try {
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserTypeID: payload.userTypeID,
			UserType: payload.userType,
		};
		const UserTypeData = encodeJson(jsonData, generalTypesAction.USERTYPE);
		const responseData = yield call(updateUserType, UserTypeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateUserTypeFailure(responseData.data));
		} else {
			yield put(updateUserTypeSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateUserTypeFailure(error));
	}
}

export function* deleteUserTypeDetails({ payload }) {
	try {
		const jsonData = {
			UserID: payload.userID,
			CompanyID: payload.companyID,
			UserTypeID: payload.userTypeID,
		};
		const UserTypeData = encodeJson(jsonData, generalTypesAction.USERTYPE);
		const responseData = yield call(deleteUserType, UserTypeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteUserTypeFailure(responseData.data));
		} else {
			yield put(deleteUserTypeSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteUserTypeFailure(error));
	}
}

export function* createListOfUserType({ payload }) {
	try {
		const jsonData = {
			Limit: payload.limit,
			CompanyID: payload.companyID,
		};
		const UserTypeData = encodeJson(jsonData, generalTypesAction.USERTYPE);
		const responseData = yield call(getAllUserType, UserTypeData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(getAllUserTypeListFailure(responseData.data));
		} else {
			yield put(getAllUserTypeListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getAllUserTypeListFailure(error));
	}
}

export function* userTypeFilterList({ payload }) {
	try {
		const jsonData = {
			SearchVal: payload.searchValue,
			CompanyID: payload.companyID,
			Limit: payload.limit,
		};
		const UserTypeData = encodeJson(jsonData, generalTypesAction.USERTYPE);
		const responseData = yield call(getAllUserType, UserTypeData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(getAllUserTypeListFailure(responseData.data));
		} else {
			yield put(getAllUserTypeListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getAllUserTypeListFailure(error));
	}
}

export function* createUserTypeResponseReset() {
	yield put(userTypeCreateChanged());
}

export function* updateUserTypeResponseReset() {
	yield put(userTypeUpdateChanged());
}

export function* deleteUserTypeResponseReset() {
	yield put(userTypeDeleteChanged());
}

export function* createListOfUserTypeReset() {
	yield put(getAllUserTypeListChanged());
}

export function* userTypeCreateStart() {
	yield takeLatest(userTypeActionTypes.USERTYPE_CREATE_REPSONSE_START, createNewUserType);
}

export function* userTypeCreateResponseReset() {
	yield takeLatest(
		userTypeActionTypes.USERTYPE_CREATE_REPSONSE_RESET,
		createUserTypeResponseReset,
	);
}

export function* userTypeUpdateStart() {
	yield takeLatest(userTypeActionTypes.USERTYPE_UPDATE_REPSONSE_START, updateUserTypeDetails);
}

export function* userTypeUpdateResponseReset() {
	yield takeLatest(
		userTypeActionTypes.USERTYPE_UPDATE_REPSONSE_RESET,
		updateUserTypeResponseReset,
	);
}

export function* userTypeDeleteStart() {
	yield takeLatest(userTypeActionTypes.USERTYPE_DELETE_REPSONSE_START, deleteUserTypeDetails);
}

export function* userTypeDeleteResponseReset() {
	yield takeLatest(
		userTypeActionTypes.USERTYPE_DELETE_REPSONSE_RESET,
		deleteUserTypeResponseReset,
	);
}

export function* getAllUserTypeList() {
	yield takeLatest(userTypeActionTypes.USERTYPE_LIST_START, createListOfUserType);
}

export function* getAllUserTypeListReset() {
	yield takeLatest(userTypeActionTypes.USERTYPE_LIST_RESET, createListOfUserTypeReset);
}

export function* getAllUserTypeFilterList() {
	yield takeLatest(userTypeActionTypes.USERTYPE_GET_FILTER_SEARCH_LIST, userTypeFilterList);
}

export function* userTypeSaga() {
	yield all([
		call(getAllUserTypeList),
		call(getAllUserTypeListReset),
		call(userTypeCreateStart),
		call(userTypeCreateResponseReset),
		call(userTypeUpdateStart),
		call(userTypeUpdateResponseReset),
		call(userTypeDeleteStart),
		call(userTypeDeleteResponseReset),
		call(getAllUserTypeFilterList),
	]);
}
