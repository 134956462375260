/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import productActionTypes from './product.types';
import generalTypesAction from '../genera.types';
import { getAllProduct, createProduct, updateProduct, fetchProduct } from './product.services';
import {
	getAllListSuccess,
	getAllListFailure,
	getAllListChanged,
	productCreateSuccess,
	productCreateFailure,
	productCreateChanged,
	productUpdateSuccess,
	productUpdateFailure,
	productUpdateChanged,
	productFetchSuccess,
	productFetchFailure,
	productFetchChanged,
} from './product.actions';
import { encodeJson } from '../enode-decode';

export function* getListOfProduct({ payload }) {
	try {
		const jsonData = {
			Limit: payload.limit,
			CompanyID: payload.companyID,
		};
		const ProductData = encodeJson(jsonData, generalTypesAction.PRODUCT);
		const responseData = yield call(getAllProduct, ProductData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(getAllListFailure(responseData.data));
		} else {
			yield put(getAllListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getAllListFailure(error));
	}
}

export function* productFilterList({ payload }) {
	try {
		const jsonData = {
			SearchVal: payload.searchVal,
			CompanyID: payload.companyID,
		};
		const ProductData = encodeJson(jsonData, generalTypesAction.PRODUCT);
		const responseData = yield call(getAllProduct, ProductData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(getAllListFailure(responseData.data));
		} else {
			yield put(getAllListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getAllListFailure(error));
	}
}

export function* getListOfProductReset() {
	yield put(getAllListChanged());
}
export function* getAllProductList() {
	yield takeLatest(productActionTypes.PRODUCT_LIST_START, getListOfProduct);
}

export function* getAllProductListReset() {
	yield takeLatest(productActionTypes.PRODUCT_LIST_RESET, getListOfProductReset);
}

export function* getAllProductFilterList() {
	yield takeLatest(productActionTypes.PRODUCT_GET_FILTER_SEARCH_LIST, productFilterList);
}

// CREATE SECTION
export function* createListOfProduct({ payload }) {
	try {
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			ProductName: payload.productName,
			Limit: payload.limit,
			Tenure: payload.tenure,
			Percentage: payload.percentage,
			LoanType: payload.loanType,
			TypeCollection: payload.typeCollection,
			CollectionPayDay: payload.collectionPayDay ? 1 : 0,
			ProcessingFee: payload.processingFee,
			Penalty: payload.penalty,
			IsPenaltyCheck: payload.isPenaltyCheck ? 1 : 0,
			IsPenaltyPercentageCheck: payload.isPenaltyPercentageCheck ? 1 : 0,
			LoanSchedule: JSON.stringify(payload.loanSchedule).replace(/"/g, "'"),
		};
		const ProductData = encodeJson(jsonData, generalTypesAction.PRODUCT);
		const responseData = yield call(createProduct, ProductData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(productCreateFailure(responseData.data));
		} else {
			yield put(productCreateSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(productCreateFailure(error));
	}
}

export function* createListOfProductReset() {
	yield put(productCreateChanged());
}

export function* productCreateStart() {
	yield takeLatest(productActionTypes.PRODUCT_CREATE_START, createListOfProduct);
}

export function* productCreateReset() {
	yield takeLatest(productActionTypes.PRODUCT_CREATE_RESET, createListOfProductReset);
}

// UDPATE SECTION
export function* updateListOfProduct({ payload }) {
	try {
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			ProductName: payload.productName,
			ProductID: payload.productID,
			Limit: payload.limit,
			Tenure: payload.tenure,
			Percentage: payload.percentage,
			LoanType: payload.loanType,
			TypeCollection: payload.typeCollection,
			CollectionPayDay: payload.collectionPayDay ? 1 : 0,
			ProcessingFee: payload.processingFee,
			Penalty: payload.penalty,
			IsPenaltyCheck: payload.isPenaltyCheck ? 1 : 0,
			IsPenaltyPercentageCheck: payload.isPenaltyPercentageCheck ? 1 : 0,
			LoanSchedule: JSON.stringify(payload.loanSchedule).replace(/"/g, "'"),
		};
		const ProductData = encodeJson(jsonData, generalTypesAction.PRODUCT);
		const responseData = yield call(updateProduct, ProductData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(productUpdateFailure(responseData.data));
		} else {
			yield put(productUpdateSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(productUpdateFailure(error));
	}
}

export function* updateListOfProductReset() {
	yield put(productUpdateChanged());
}

export function* productUpdateStart() {
	yield takeLatest(productActionTypes.PRODUCT_UPDATE_START, updateListOfProduct);
}

export function* productUpdateReset() {
	yield takeLatest(productActionTypes.PRODUCT_UPDATE_RESET, updateListOfProductReset);
}

// UDPATE SECTION
export function* deleteListOfProduct({ payload }) {
	try {
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			ProductID: payload.productID,
			IsActive: 0,
		};
		const ProductData = encodeJson(jsonData, generalTypesAction.PRODUCT);
		const responseData = yield call(updateProduct, ProductData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(productUpdateFailure(responseData.data));
		} else {
			yield put(productUpdateSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(productUpdateFailure(error));
	}
}

export function* deleteListOfProductReset() {
	yield put(productUpdateChanged());
}

export function* productDeleteStart() {
	yield takeLatest(productActionTypes.PRODUCT_DELETE_START, deleteListOfProduct);
}

export function* productDeleteReset() {
	yield takeLatest(productActionTypes.PRODUCT_DELETE_RESET, deleteListOfProductReset);
}

// FETCH SECTION
export function* fetchListOfProduct({ payload }) {
	try {
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			ProductID: payload.productID,
			CollectionDay: payload.collectionDay,
		};
		const ProductData = encodeJson(jsonData, generalTypesAction.PRODUCT);
		const responseData = yield call(fetchProduct, ProductData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(productFetchFailure(responseData.data));
		} else {
			yield put(productFetchSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(productFetchFailure(error));
	}
}

export function* fetchListOfProductReset() {
	yield put(productFetchChanged());
}

export function* productFetchStart() {
	yield takeLatest(productActionTypes.PRODUCT_FETCH_START, fetchListOfProduct);
}

export function* productFetchReset() {
	yield takeLatest(productActionTypes.PRODUCT_FETCH_RESET, fetchListOfProductReset);
}

export function* productSaga() {
	yield all([
		call(getAllProductList),
		call(getAllProductListReset),
		call(getAllProductFilterList),
		call(productCreateStart),
		call(productCreateReset),
		call(productUpdateStart),
		call(productUpdateReset),
		call(productDeleteStart),
		call(productDeleteReset),
		call(productFetchStart),
		call(productFetchReset),
	]);
}
