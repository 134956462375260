import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import USERS from '../../common/data/userDummyData';
import { selectCurrentUser } from '../../redux/login/login.selector';

export const UserAvatar = ({ srcSet, src }) => {
	return (
		<div className='user-avatar'>
			<img srcSet={srcSet} src={src} alt='Avatar' width={128} height={128} />
		</div>
	);
};
UserAvatar.propTypes = {
	src: PropTypes.string.isRequired,
	srcSet: PropTypes.string,
};
UserAvatar.defaultProps = {
	srcSet: null,
};

const User = () => {
	// const navigate = useNavigate();
	const getCurrentUserLoginDetails = useSelector(selectCurrentUser);
	return (
		<div
			role='presentation'
			className='user'
			// onClick={() =>
			// 		navigate(`${demoPages.appointment.subMenu.employeeID.path}/${USERS.JOHN.id}`)
			// 	}
		>
			{getCurrentUserLoginDetails !== null ? (
				<>
					{getCurrentUserLoginDetails.data[0].UserPhoto !== undefined && getCurrentUserLoginDetails.data[0].UserPhoto !== null ? (
						<UserAvatar
							srcSet={`${getCurrentUserLoginDetails.data[0].UserPhoto}`}
							src={`${getCurrentUserLoginDetails.data[0].UserPhoto}`}
						/>
					) : (
						<UserAvatar srcSet={USERS.JOHN.srcSet} src={USERS.JOHN.src} />
					)}

					<div className='user-info'>
						<div className='user-name'>{`${getCurrentUserLoginDetails.data[0].UserName}`}</div>
						<div className='user-sub-title'>{getCurrentUserLoginDetails.data[0].UserPhone}</div>
					</div>
				</>
			) : (
				<>
					<UserAvatar srcSet={USERS.JOHN.srcSet} src={USERS.JOHN.src} />
					<div className='user-info'>
						<div className='user-name'>{`${USERS.JOHN.name} ${USERS.JOHN.surname}`}</div>
						<div className='user-sub-title'>{USERS.JOHN.position}</div>
					</div>
				</>
			)}
		</div>
	);
};

export default User;
