/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import ndcActionTypes from './memberNDC.types';
import generalTypesAction from '../genera.types';
import { getEndodeJsonData } from '../common/common.utils';

const INITIAL_STATE = {
	ndcListStatus: null,
	ndcListResponse: null,

	ndcApproveStatus: null,
	ndcApproveResponse: null,
};

// eslint-disable-next-line default-param-last
const ndcReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ndcActionTypes.NDC_LIST_REPSONSE_SUCCESS:
			return {
				...state,
				ndcListResponse: getEndodeJsonData(action.payload, generalTypesAction.NDC),
				ndcListStatus: 'Success',
			};
		case ndcActionTypes.NDC_LIST_REPSONSE_FAILURE:
			return { ...state, ndcListResponse: null, ndcListStatus: 'Failure' };
		case ndcActionTypes.NDC_LIST_REPSONSE_CHANGED:
			return { ...state, ndcListStatus: null };

		case ndcActionTypes.NDC_APPROVE_RESPONSE_SUCCESS:
			return { ...state, ndcApproveResponse: action.payload, ndcApproveStatus: 'Success' };
		case ndcActionTypes.NDC_APPROVE_RESPONSE_FAILURE:
			return { ...state, ndcApproveResponse: action.payload, ndcApproveStatus: 'Failure' };
		case ndcActionTypes.NDC_APPROVE_RESPONSE_CHANGED:
			return { ...state, ndcApproveResponse: null, ndcApproveStatus: null };

		default:
			return state;
	}
};

export default ndcReducer;
