/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import bankActionTypes from './bank.types';

const INITIAL_STATE = {

	memberBankAnalyseList: null,
	memberBankAnalyseListStatus: null,

	memberUpdateBankAnalyseList: null,
	memberUpdateBankAnalyseListStatus: null,

	memberBankInkredoList: null,
	memberBankInkredoListStatus: null,

	memberUpdateBankInkredoJsonList: null,
	memberUpdateBankInkredoJsonListStatus: null,
};

// eslint-disable-next-line default-param-last
const bankReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {

		case bankActionTypes.MEMBER_ANALYSE_BANK_STATEMENT_SUCCESS:
			return {
				...state,
				memberBankAnalyseList: action.payload,
				memberBankAnalyseListStatus: 'Success',
			};
		case bankActionTypes.MEMBER_ANALYSE_BANK_STATEMENT_FAILURE:
			return {
				...state,
				memberBankAnalyseList: action.payload,
				memberBankAnalyseListStatus: 'Failure',
			};
		case bankActionTypes.MEMBER_ANALYSE_BANK_STATEMENT_CHANGED:
			return { ...state, memberBankAnalyseList: null, memberBankAnalyseListStatus: null };

		case bankActionTypes.UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_SUCCESS:
			return {
				...state,
				memberUpdateBankAnalyseList: action.payload,
				memberUpdateBankAnalyseListStatus: 'Success',
			};
		case bankActionTypes.UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_FAILURE:
			return {
				...state,
				memberUpdateBankAnalyseList: action.payload,
				memberUpdateBankAnalyseListStatus: 'Failure',
			};
		case bankActionTypes.UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_CHANGED:
			return {
				...state,
				memberUpdateBankAnalyseList: null,
				memberUpdateBankAnalyseListStatus: null,
			};

		case bankActionTypes.MEMBER_INKREDO_BANK_STATEMENT_SUCCESS:
			return {
				...state,
				memberBankInkredoList: action.payload,
				memberBankInkredoListStatus: 'Success',
			};
		case bankActionTypes.MEMBER_INKREDO_BANK_STATEMENT_FAILURE:
			return {
				...state,
				memberBankInkredoList: action.payload,
				memberBankInkredoListStatus: 'Failure',
			};
		case bankActionTypes.MEMBER_INKREDO_BANK_STATEMENT_CHANGED:
			return { ...state, memberBankInkredoList: null, memberBankInkredoListStatus: null };

		case bankActionTypes.UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_JSON_SUCCESS:
			return {
				...state,
				memberUpdateBankInkredoJsonList: action.payload,
				memberUpdateBankInkredoJsonListStatus: 'Success',
			};
		case bankActionTypes.UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_JSON_FAILURE:
			return {
				...state,
				memberUpdateBankInkredoJsonList: action.payload,
				memberUpdateBankInkredoJsonListStatus: 'Failure',
			};
		case bankActionTypes.UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_JSON_CHANGED:
			return {
				...state,
				memberUpdateBankInkredoJsonList: null,
				memberUpdateBankInkredoJsonListStatus: null,
			};

		default:
			return state;
	}
};

export default bankReducer;
