/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import ndcActionTypes from './memberNDC.types';

export const ndcListStart = (StaffDetails) => ({
	type: ndcActionTypes.NDC_LIST_REPSONSE_START,
	payload: StaffDetails,
});

export const ndcListSuccess = (Staff) => ({
	type: ndcActionTypes.NDC_LIST_REPSONSE_SUCCESS,
	payload: Staff,
});

export const ndcListFailure = (error) => ({
	type: ndcActionTypes.NDC_LIST_REPSONSE_FAILURE,
	payload: error,
});

export const ndcListChanged = () => ({
	type: ndcActionTypes.NDC_LIST_REPSONSE_CHANGED,
});

export const ndcListReset = () => ({
	type: ndcActionTypes.NDC_LIST_REPSONSE_RESET,
});

export const ndcApproveStart = (StaffDetails) => ({
	type: ndcActionTypes.NDC_APPROVE_RESPONSE_START,
	payload: StaffDetails,
});

export const ndcApproveSuccess = (Staff) => ({
	type: ndcActionTypes.NDC_APPROVE_RESPONSE_SUCCESS,
	payload: Staff,
});

export const ndcApproveFailure = (error) => ({
	type: ndcActionTypes.NDC_APPROVE_RESPONSE_FAILURE,
	payload: error,
});

export const ndcApproveChanged = () => ({
	type: ndcActionTypes.NDC_APPROVE_RESPONSE_CHANGED,
});

export const ndcApproveReset = () => ({
	type: ndcActionTypes.NDC_APPROVE_RESPONSE_RESET,
});
