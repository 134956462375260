/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import profileActionTypes from './profile.types';

export const getProfileDetailStart = (Profile) => ({
	type: profileActionTypes.PROFILE_REPSONSE_START,
	payload: Profile,
});

export const getProfileDetailSuccess = (ProfileDetails) => ({
	type: profileActionTypes.PROFILE_REPSONSE_SUCCESS,
	payload: ProfileDetails,
});

export const getProfileDetailFailure = (error) => ({
	type: profileActionTypes.PROFILE_REPSONSE_FAILURE,
	payload: error,
});

export const getProfileDetailChanged = () => ({
	type: profileActionTypes.PROFILE_REPSONSE_CHANGED,
});

export const getProfileDetailReset = () => ({
	type: profileActionTypes.PROFILE_REPSONSE_RESET,
});

export const getProfileUpdateStart = (Profile) => ({
	type: profileActionTypes.PROFILE_UPDATE_REPSONSE_START,
	payload: Profile,
});

export const getProfileUpdateSuccess = (ProfileUpdates) => ({
	type: profileActionTypes.PROFILE_UPDATE_REPSONSE_SUCCESS,
	payload: ProfileUpdates,
});

export const getProfileUpdateFailure = (error) => ({
	type: profileActionTypes.PROFILE_UPDATE_REPSONSE_FAILURE,
	payload: error,
});

export const getProfileUpdateChanged = () => ({
	type: profileActionTypes.PROFILE_UPDATE_REPSONSE_CHANGED,
});

export const getProfileUpdateReset = () => ({
	type: profileActionTypes.PROFILE_UPDATE_REPSONSE_RESET,
});

