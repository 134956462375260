/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import TargetActionTypes from './target.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	addTargetResponse: null,
	getTargetListResponse: null,
	getTargetListArr: [],
	fetchTargetDetailsResponse: null,
	fetchTargetDetailsArr: [],
	fetchTargetDetailListArr: [],
	deleteTargetResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TargetActionTypes.ADD_TARGET_SUCCESS:
			return { ...state, addTargetResponse: action.payload };

		case TargetActionTypes.ADD_TARGET_FAILURE:
			return { ...state, addTargetResponse: action.payload };

		case TargetActionTypes.ADD_TARGET_RESPONSE_CHANGED:
			return { ...state, addTargetResponse: null };

		case TargetActionTypes.GET_TARGET_LIST_SUCCESS:
			return {
				...state,
				getTargetListResponse: action.payload,
				getTargetListArr: docodeResponse(action.payload, 'GetTargetListKey'),
			};

		case TargetActionTypes.GET_TARGET_LIST_FAILURE:
			return { ...state, getTargetListResponse: action.payload };

		case TargetActionTypes.GET_TARGET_LIST_RESPONSE_CHANGED:
			return { ...state, getTargetListResponse: null };

		case TargetActionTypes.FETCH_TARGET_START:
			return { ...state, fetchTargetDetailsArr: [], fetchTargetDetailListArr: [] };

		// case GeneralActionTypes.IS_EDIT_DETAILS_START:
		// 	return { ...state, fetchTargetDetailsArr: [], fetchTargetDetailListArr: [] };

		case TargetActionTypes.FETCH_TARGET_RESPONSE_ARRAY_RESET:
			return { ...state, fetchTargetDetailsArr: [], fetchTargetDetailListArr: [] };

		case TargetActionTypes.FETCH_TARGET_SUCCESS:
			return {
				...state,
				fetchTargetDetailsResponse: action.payload,
				fetchTargetDetailsArr: docodeResponse(action.payload, 'GetTargetDetails'),
				fetchTargetDetailListArr: state.fetchTargetDetailsArr,
			};

		case TargetActionTypes.FETCH_TARGET_FAILURE:
			return { ...state, fetchTargetDetailsResponse: action.payload };

		case TargetActionTypes.FETCH_TARGET_RESPONSE_CHANGED:
			return {
				...state,
				fetchTargetDetailsResponse: null,
			};

		case TargetActionTypes.DELETE_TARGET_SUCCESS:
			return { ...state, deleteTargetResponse: action.payload };

		case TargetActionTypes.DELETE_TARGET_FAILURE:
			return { ...state, deleteTargetResponse: action.payload };

		case TargetActionTypes.DELETE_TARGET_RESPONSE_CHANGED:
			return { ...state, deleteTargetResponse: null };

		default:
			return state;
	}
};
