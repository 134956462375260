import React from 'react';
import Lottie from 'react-lottie';
import { useMeasure } from 'react-use';
import classNames from 'classnames';
import useDarkMode from '../../hooks/useDarkMode';
// import Icon from '../../components/icon/Icon';
import heart from '../../assets/lottie/heart.json';

const Footer = () => {
	const [ref, { height }] = useMeasure();

	const currentYear = new Date().getFullYear();
	const root = document.documentElement;
	root.style.setProperty('--footer-height', `${height}px`);

	const { darkModeStatus } = useDarkMode();
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: heart,
		rendererSettings: {
		   preserveAspectRatio: "xMidYMid slice",
		},
	};

	const alignItem = {
		alignItems: 'center',
	};
	return (
		<footer ref={ref} className='footer'>
			<div className='container-fluid'>
				<div className='row' style={alignItem}>
					{/* <div className='col'>
						<span className='fw-light'>
							Copyright © {currentYear} - Rights{' '} */}
					{/* <Icon icon='HeartFill' size='lg' className='me-1' color='danger' /> */}
					{/* <Lottie options={defaultOptions} height={50} width={50} /> */}
					{/* Kapiital Kapslock
						</span>
					</div> */}
					<div className='col'>
						<div className='row' style={alignItem}>
							<span className='fw-light col-auto'>
								Copyright © {currentYear} - Rights{' '}
							</span>
							<div className='col-auto'>
								<Lottie options={defaultOptions} height={50} width={40} />
							</div>
							<span className='fw-light  col-auto'>Kapiital Kapslock</span>
						</div>
					</div>
					<div className='col-auto'>
						<a
							href='/'
							className={classNames('text-decoration-none', {
								'link-dark': !darkModeStatus,
								'link-light': darkModeStatus,
							})}>
							<small className='fw-bold'>PaisaNow</small>
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
