/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { PAISANOW_API_URL } from '../const';

export const ndcListApi = (ndcData) => {
	const data = {
		NDCListToken: ndcData,
	};
	const url = `${PAISANOW_API_URL}NDCList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const ndcApproveApi = (ndcApproveData) => {
	const data = {
		NDCApprovalToken: ndcApproveData,
	};
	const url = `${PAISANOW_API_URL}ApproveNDCMember`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
