/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import TargetActionTypes from './target.types';

/** ***************** ADD TARGET START *********************** */
export const addTargetStart = (addData) => ({
	type: TargetActionTypes.ADD_TARGET_START,
	payload: addData,
});

export const addTargetSuccess = (successData) => ({
	type: TargetActionTypes.ADD_TARGET_SUCCESS,
	payload: successData,
});

export const addTargetFailure = (failureData) => ({
	type: TargetActionTypes.ADD_TARGET_FAILURE,
	payload: failureData,
});

export const addTargetResponseResetStart = () => ({
	type: TargetActionTypes.ADD_TARGET_RESPONSE_RESET,
});

export const addTargetResponseChanged = () => ({
	type: TargetActionTypes.ADD_TARGET_RESPONSE_CHANGED,
});

/** ***************** ADD TARGET END *********************** */

/** ***************** GET TARGET START *********************** */
export const getTargetStart = (getData) => ({
	type: TargetActionTypes.GET_TARGET_LIST_START,
	payload: getData,
});

export const getTargetSuccess = (successData) => ({
	type: TargetActionTypes.GET_TARGET_LIST_SUCCESS,
	payload: successData,
});

export const getTargetFailure = (failureData) => ({
	type: TargetActionTypes.GET_TARGET_LIST_FAILURE,
	payload: failureData,
});

export const getTargetResponseResetStart = () => ({
	type: TargetActionTypes.GET_TARGET_LIST_RESPONSE_RESET,
});

export const getTargetResponseChanged = () => ({
	type: TargetActionTypes.GET_TARGET_LIST_RESPONSE_CHANGED,
});

/** ***************** GET TARGET END *********************** */

/** ***************** FETCH TARGET START *********************** */
export const fetchTargetStart = (fetchData) => ({
	type: TargetActionTypes.FETCH_TARGET_START,
	payload: fetchData,
});

export const fetchTargetSuccess = (successData) => ({
	type: TargetActionTypes.FETCH_TARGET_SUCCESS,
	payload: successData,
});

export const fetchTargetFailure = (failureData) => ({
	type: TargetActionTypes.FETCH_TARGET_FAILURE,
	payload: failureData,
});

export const fetchTargetResponseResetStart = () => ({
	type: TargetActionTypes.FETCH_TARGET_RESPONSE_RESET,
});

export const fetchTargetResponseChanged = () => ({
	type: TargetActionTypes.FETCH_TARGET_RESPONSE_CHANGED,
});

export const fetchTargetResponseArrayReset = () => ({
	type: TargetActionTypes.FETCH_TARGET_RESPONSE_ARRAY_RESET,
});

/** ***************** FETCH TARGET END *********************** */

/** ***************** DELETE TARGET START *********************** */
export const deleteTargetStart = (deleteData) => ({
	type: TargetActionTypes.DELETE_TARGET_START,
	payload: deleteData,
});

export const deleteTargetSuccess = (successData) => ({
	type: TargetActionTypes.DELETE_TARGET_SUCCESS,
	payload: successData,
});

export const deleteTargetFailure = (failureData) => ({
	type: TargetActionTypes.DELETE_TARGET_FAILURE,
	payload: failureData,
});

export const deleteTargetResponseResetStart = () => ({
	type: TargetActionTypes.DELETE_TARGET_RESPONSE_RESET,
});

export const deleteTargetResponseChanged = () => ({
	type: TargetActionTypes.DELETE_TARGET_RESPONSE_CHANGED,
});

/** ***************** DELETE TARGET END *********************** */
