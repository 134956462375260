/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import branchActionTypes from './branch.types';

export const createBranchSuccess = (Branch) => ({
	type: branchActionTypes.BRANCH_CREATE_REPSONSE_SUCCESS,
	payload: Branch,
});

export const createBranchFailure = (error) => ({
	type: branchActionTypes.BRANCH_CREATE_REPSONSE_FAILURE,
	payload: error,
});

export const createBranchStart = (BranchDetails) => ({
	type: branchActionTypes.BRANCH_CREATE_REPSONSE_START,
	payload: BranchDetails,
});

export const branchCreateChanged = () => ({
	type: branchActionTypes.BRANCH_CREATE_REPSONSE_CHANGED,
});

export const branchCreateReset = () => ({
	type: branchActionTypes.BRANCH_CREATE_REPSONSE_RESET,
});

export const updateBranchSuccess = (Branch) => ({
	type: branchActionTypes.BRANCH_UPDATE_REPSONSE_SUCCESS,
	payload: Branch,
});

export const updateBranchFailure = (error) => ({
	type: branchActionTypes.BRANCH_UPDATE_REPSONSE_FAILURE,
	payload: error,
});

export const updateBranchStart = (BranchDetails) => ({
	type: branchActionTypes.BRANCH_UPDATE_REPSONSE_START,
	payload: BranchDetails,
});

export const branchUpdateChanged = () => ({
	type: branchActionTypes.BRANCH_UPDATE_REPSONSE_CHANGED,
});

export const branchUpdateReset = () => ({
	type: branchActionTypes.BRANCH_UPDATE_REPSONSE_RESET,
});

export const deleteBranchSuccess = (Branch) => ({
	type: branchActionTypes.BRANCH_DELETE_REPSONSE_SUCCESS,
	payload: Branch,
});

export const deleteBranchFailure = (error) => ({
	type: branchActionTypes.BRANCH_DELETE_REPSONSE_FAILURE,
	payload: error,
});

export const deleteBranchStart = (BranchDetails) => ({
	type: branchActionTypes.BRANCH_DELETE_REPSONSE_START,
	payload: BranchDetails,
});

export const branchDeleteChanged = () => ({
	type: branchActionTypes.BRANCH_DELETE_REPSONSE_CHANGED,
});

export const branchDeleteReset = () => ({
	type: branchActionTypes.BRANCH_DELETE_REPSONSE_RESET,
});

export const getAllBranchList = (Branch) => ({
	type: branchActionTypes.BRANCH_LIST_START,
	payload: Branch,
});

export const getAllListSuccess = (Branch) => ({
	type: branchActionTypes.BRANCH_LIST_SUCCESS,
	payload: Branch,
});

export const getAllListFailure = (error) => ({
	type: branchActionTypes.BRANCH_LIST_FAILURE,
	payload: error,
});

export const getAllListChanged = () => ({
	type: branchActionTypes.BRANCH_LIST_CHANGED,
});

export const getAllListReset = () => ({
	type: branchActionTypes.BRANCH_LIST_RESET,
});

export const getSelectedID = (BranchData) => ({
	type: branchActionTypes.BRANCH_SELECTED_ID,
	payload: BranchData,
});

export const postCodeCreateStart = (postCodeCredentils) => ({
	type: branchActionTypes.POSTCODE_CREATE_RESPONSE_START,
	payload: postCodeCredentils,
});

export const postCodeCreateSuccess = (postCode) => ({
	type: branchActionTypes.POSTCODE_CREATE_RESPONSE_SUCCESS,
	payload: postCode,
});

export const postCodeCreateFailure = (error) => ({
	type: branchActionTypes.POSTCODE_CREATE_RESPONSE_FAILURE,
	payload: error,
});

export const postCodeResponseChanged = () => ({
	type: branchActionTypes.POSTCODE_CREATE_RESPONSE_CHANGED,
});

export const postCodeResponseReset = () => ({
	type: branchActionTypes.POSTCODE_CREATE_RESPONSE_RESET,
});

export const createBranchPinCodeSuccess = (Branch) => ({
	type: branchActionTypes.BRANCH_PINCODE_RESPONSE_SUCCESS,
	payload: Branch,
});

export const createBranchPinCodeFailure = (error) => ({
	type: branchActionTypes.BRANCH_PINCODE_RESPONSE_FAILURE,
	payload: error,
});

export const createBranchPinCodeStart = (BranchDetails) => ({
	type: branchActionTypes.BRANCH_PINCODE_RESPONSE_START,
	payload: BranchDetails,
});

export const branchCreatePinCodeChanged = () => ({
	type: branchActionTypes.BRANCH_PINCODE_RESPONSE_CHANGED,
});

export const branchCreatePinCodeReset = () => ({
	type: branchActionTypes.BRANCH_PINCODE_RESPONSE_RESET,
});

export const deleteBranchPinCodeSuccess = (Branch) => ({
	type: branchActionTypes.BRANCH_PINCODE_DELETE_SUCCESS,
	payload: Branch,
});

export const deleteBranchPinCodeFailure = (error) => ({
	type: branchActionTypes.BRANCH_PINCODE_DELETE_FAILURE,
	payload: error,
});

export const deleteBranchPinCodeStart = (BranchDetails) => ({
	type: branchActionTypes.BRANCH_PINCODE_DELETE_START,
	payload: BranchDetails,
});

export const branchDeletePinCodeChanged = () => ({
	type: branchActionTypes.BRANCH_PINCODE_DELETE_CHANGED,
});

export const branchDeletePinCodeReset = () => ({
	type: branchActionTypes.BRANCH_PINCODE_DELETE_RESET,
});

export const getAllBranchPinCodeList = (Branch) => ({
	type: branchActionTypes.BRANCH_PINCODE_LIST_START,
	payload: Branch,
});

export const getAllBranchPinCodeSuccess = (Branch) => ({
	type: branchActionTypes.BRANCH_PINCODE_LIST_SUCCESS,
	payload: Branch,
});

export const getAllBranchPinCodeFailure = (Branch) => ({
	type: branchActionTypes.BRANCH_PINCODE_LIST_FAILURE,
	payload: Branch,
});

export const getAllBranchPinCodeChanged = () => ({
	type: branchActionTypes.BRANCH_PINCODE_LIST_CHANGED,
});

export const getAllBranchPinCodeReset = () => ({
	type: branchActionTypes.BRANCH_PINCODE_LIST_RESET,
});

export const getFilterListStart = (Branch) => ({
	type: branchActionTypes.BRANCH_FILTER_SEARCH_LIST_START,
	payload: Branch,
});
