/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import questionnaireActionTypes from './questionnaire.types';
import generalTypesAction from '../genera.types';
import { getEndodeJsonData } from '../common/common.utils';

const INITIAL_STATE = {
	questionnaireCreateStatus: null,
	questionnaireCreateResponse: null,
	questionnaireUpdateStatus: null,
	questionnaireUpdateResponse: null,
	questionnaireDeleteStatus: null,
	questionnaireDeleteResponse: null,
	questionnaireFetchStatus: null,
	questionnaireFetchResponse: null,
	allQuestionnaireList: null,
	allQuestionnaireListStatus: null,
};

// eslint-disable-next-line default-param-last
const questionnaireReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case questionnaireActionTypes.QUESTIONNAIRE_LIST_SUCCESS:
			return {
				...state,
				allQuestionnaireList: getEndodeJsonData(
					action.payload,
					generalTypesAction.QUESTIONNAIRE,
				),
				allQuestionnaireListStatus: 'Success',
			};
		case questionnaireActionTypes.QUESTIONNAIRE_LIST_FAILURE:
			return { ...state, allQuestionnaireList: null, allQuestionnaireListStatus: 'Failure' };
		case questionnaireActionTypes.QUESTIONNAIRE_LIST_CHANGED:
			return { ...state, allQuestionnaireListStatus: null };

		case questionnaireActionTypes.QUESTIONNAIRE_CREATE_REPSONSE_SUCCESS:
			return {
				...state,
				questionnaireCreateResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.QUESTIONNAIRE,
				),
				questionnaireCreateStatus: 'Success',
			};
		case questionnaireActionTypes.QUESTIONNAIRE_CREATE_REPSONSE_FAILURE:
			return {
				...state,
				questionnaireCreateResponse: null,
				questionnaireCreateStatus: 'Failure',
			};
		case questionnaireActionTypes.QUESTIONNAIRE_CREATE_REPSONSE_CHANGED:
			return { ...state, questionnaireCreateResponse: null, questionnaireCreateStatus: null };

		case questionnaireActionTypes.QUESTIONNAIRE_UPDATE_REPSONSE_SUCCESS:
			return {
				...state,
				questionnaireUpdateResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.QUESTIONNAIRE,
				),
				questionnaireUpdateStatus: 'Success',
			};
		case questionnaireActionTypes.QUESTIONNAIRE_UPDATE_REPSONSE_FAILURE:
			return {
				...state,
				questionnaireUpdateResponse: null,
				questionnaireUpdateStatus: 'Failure',
			};
		case questionnaireActionTypes.QUESTIONNAIRE_UPDATE_REPSONSE_CHANGED:
			return { ...state, questionnaireUpdateResponse: null, questionnaireUpdateStatus: null };

		case questionnaireActionTypes.QUESTIONNAIRE_DELETE_REPSONSE_SUCCESS:
			return {
				...state,
				questionnaireDeleteResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.QUESTIONNAIRE,
				),
				questionnaireDeleteStatus: 'Success',
			};
		case questionnaireActionTypes.QUESTIONNAIRE_DELETE_REPSONSE_FAILURE:
			return {
				...state,
				questionnaireDeleteResponse: null,
				questionnaireDeleteStatus: 'Failure',
			};
		case questionnaireActionTypes.QUESTIONNAIRE_DELETE_REPSONSE_CHANGED:
			return { ...state, questionnaireDeleteResponse: null, questionnaireDeleteStatus: null };

		case questionnaireActionTypes.QUESTIONNAIRE_FETCH_REPSONSE_SUCCESS:
			return {
				...state,
				questionnaireFetchResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.QUESTIONNAIRE,
				),
				questionnaireFetchStatus: 'Success',
			};
		case questionnaireActionTypes.QUESTIONNAIRE_FETCH_REPSONSE_FAILURE:
			return {
				...state,
				questionnaireFetchResponse: null,
				questionnaireFetchStatus: 'Failure',
			};
		case questionnaireActionTypes.QUESTIONNAIRE_FETCH_REPSONSE_CHANGED:
			return { ...state, questionnaireFetchResponse: null, questionnaireFetchStatus: null };

		default:
			return state;
	}
};

export default questionnaireReducer;
