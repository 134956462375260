/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { PAISANOW_API_URL } from '../const';

export const targetDetailsAdd = (addDetails) => {
	const data = {
		AddTargetDetailsToken: addDetails,
	};
	const url = `${PAISANOW_API_URL}addTargetDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const targetListGet = (listData) => {
	const data = {
		GetTargetListToken: listData,
	};
	const url = `${PAISANOW_API_URL}getTargetListDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const targetDetailsFetch = (fetchData) => {
	const data = {
		GetTargetDetailsDetailsToken: fetchData,
	};
	const url = `${PAISANOW_API_URL}getTargetDetailsBasedOnValue`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const targetDetailsDelete = (deleteData) => {
	const data = {
		DeleteTargetDetailsToken: deleteData,
	};
	const url = `${PAISANOW_API_URL}deleteTargetDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
