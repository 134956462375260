const companyActionTypes = {
	COMPANY_CREATE_REPSONSE_START: 'company_create_response_start',
	COMPANY_CREATE_REPSONSE_SUCCESS: 'company_create_response_success',
	COMPANY_CREATE_REPSONSE_FAILURE: 'company_create_response_failure',
	COMPANY_CREATE_REPSONSE_CHANGED: 'company_create_response_changed',
	COMPANY_CREATE_REPSONSE_RESET: 'company_create_response_reset',

	COMPANY_UPDATE_REPSONSE_START: 'company_update_response_start',
	COMPANY_UPDATE_REPSONSE_SUCCESS: 'company_update_response_success',
	COMPANY_UPDATE_REPSONSE_FAILURE: 'company_update_response_failure',
	COMPANY_UPDATE_REPSONSE_CHANGED: 'company_update_response_changed',
	COMPANY_UPDATE_REPSONSE_RESET: 'company_update_response_reset',

	COMPANY_DELETE_REPSONSE_START: 'company_delete_response_start',
	COMPANY_DELETE_REPSONSE_SUCCESS: 'company_delete_response_success',
	COMPANY_DELETE_REPSONSE_FAILURE: 'company_delete_response_failure',
	COMPANY_DELETE_REPSONSE_CHANGED: 'company_delete_response_changed',
	COMPANY_DELETE_REPSONSE_RESET: 'company_delete_response_reset',

	COMPANY_GET_ALL_LIST: 'company_get_all_list',
	COMPANY_LIST_START: 'company_list_start',
	COMPANY_LIST_SUCCESS: 'company_list_success',
	COMPANY_LIST_FAILURE: 'company_list_failure',
	COMPANY_LIST_ID: 'company_list_id',
	COMPANY_LIST_NAME: 'company_list_name',

	COMPANY_LIST_SELECT_START: 'COMPANY_LIST_SELECT_START',
	COMPANY_LIST_SELECT_SUCCESS: 'COMPANY_LIST_SELECT_SUCCESS',
	COMPANY_LIST_SELECT_FAILURE: 'COMPANY_LIST_SELECT_FAILURE',
	COMPANY_LIST_SELECT_CHANGED: 'COMPANY_LIST_SELECT_CHANGED',
	COMPANY_LIST_SELECT_RESET: 'COMPANY_LIST_SELECT_RESET',

	COMPANY_LMS_CALL_START: 'COMPANY_LMS_CALL_START',
	COMPANY_LMS_CALL_SUCCESS: 'COMPANY_LMS_CALL_SUCCESS',
	COMPANY_LMS_CALL_FAILURE: 'COMPANY_LMS_CALL_FAILURE',
	COMPANY_LMS_CALL_CHANGED: 'COMPANY_LMS_CALL_CHANGED',
	COMPANY_LMS_CALL_RESET: 'COMPANY_LMS_CALL_RESET',

	// UNDER WRITING COND SECTION
	COMPANY_COND_LIST_START: 'COMPANY_COND_LIST_START',
	COMPANY_COND_LIST_SUCCESS: 'COMPANY_COND_LIST_SUCCESS',
	COMPANY_COND_LIST_FAILURE: 'COMPANY_COND_LIST_FAILURE',
	COMPANY_COND_LIST_CHANGED: 'COMPANY_COND_LIST_CHANGED',
	COMPANY_COND_LIST_RESET: 'COMPANY_COND_LIST_RESET',

	COMPANY_COND_CREATE_START: 'COMPANY_COND_CREATE_START',
	COMPANY_COND_CREATE_SUCCESS: 'COMPANY_COND_CREATE_SUCCESS',
	COMPANY_COND_CREATE_FAILURE: 'COMPANY_COND_CREATE_FAILURE',
	COMPANY_COND_CREATE_CHANGED: 'COMPANY_COND_CREATE_CHANGED',
	COMPANY_COND_CREATE_RESET: 'COMPANY_COND_CREATE_RESET',

	COMPANY_COND_UPDATE_START: 'COMPANY_COND_UPDATE_START',
	COMPANY_COND_UPDATE_SUCCESS: 'COMPANY_COND_UPDATE_SUCCESS',
	COMPANY_COND_UPDATE_FAILURE: 'COMPANY_COND_UPDATE_FAILURE',
	COMPANY_COND_UPDATE_CHANGED: 'COMPANY_COND_UPDATE_CHANGED',
	COMPANY_COND_UPDATE_RESET: 'COMPANY_COND_UPDATE_RESET',

	// LOAN SCHEDULE SECTION
	COMPANY_LOAN_SCHEDULE_START: 'COMPANY_LOAN_SCHEDULE_START',
	COMPANY_LOAN_SCHEDULE_SUCCESS: 'COMPANY_LOAN_SCHEDULE_SUCCESS',
	COMPANY_LOAN_SCHEDULE_FAILURE: 'COMPANY_LOAN_SCHEDULE_FAILURE',
	COMPANY_LOAN_SCHEDULE_CHANGED: 'COMPANY_LOAN_SCHEDULE_CHANGED',
	COMPANY_LOAN_SCHEDULE_RESET: 'COMPANY_LOAN_SCHEDULE_RESET',
};
export default companyActionTypes;
