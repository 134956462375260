/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { PAISANOW_API_URL } from '../const';

export const getProfile = (ProfileData) => {
	const data = {
		CompanyProfileToken: ProfileData,
	};
	const url = `${PAISANOW_API_URL}GetCompanyProfile`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateProfile = (ProfileData) => {
	const data = {
		CompanyProfileUpdateToken: ProfileData,
	};
	const url = `${PAISANOW_API_URL}UpdateCompanyProfile`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
