/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import disbursementActionTypes from './disbursement.types';
import generalTypesAction from '../genera.types';
import { getEndodeJsonData } from '../common/common.utils';

const INITIAL_STATE = {
	allDisbursementList: null,
	allDisbursementListStatus: null,

	disbursementMemberDetailStatus: null,
	disbursementMemberDetailResponse: null,

	disbursementCustomerData: null,
	disbursementCustomerRemarksData: null,

	disbursementRemarksLogStatus: null,
	disbursementRemarksLogResponse: null,

	disbursementLeaderStore: null,

	disbursementConfirmationListStatus: null,
	disbursementConfirmationListResponse: null,

	disbursementRejectMemberStatus: null,
	disbursementRejectMemberResponse: null,

	// SQ SECTION
	disbursementSQMemberDetailStatus: null,
	disbursementSQMemberDetailResponse: null,

	// DISBURSEMENT APPROVE SECTION
	disbApproveMemberStatus: null,
	disbApproveMemberResponse: null,

	// REPORT SECTION
	disbReportStatus: null,
	disbReportResponse: null,
};

// eslint-disable-next-line default-param-last
const disbursementReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case disbursementActionTypes.DISBURSEMENT_LIST_SUCCESS:
			return {
				...state,
				allDisbursementList: getEndodeJsonData(action.payload, generalTypesAction.MEMBER),
				allDisbursementListStatus: 'Success',
			};
		case disbursementActionTypes.DISBURSEMENT_LIST_FAILURE:
			return { ...state, allDisbursementList: null, allDisbursementListStatus: 'Failure' };
		case disbursementActionTypes.DISBURSEMENT_LIST_CHANGED:
			return { ...state, allDisbursementListStatus: null };

		case disbursementActionTypes.DISBURSEMENT_MEMBER_DETAILS_SUCCESS:
			return {
				...state,
				disbursementMemberDetailResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.MEMBER,
				),
				disbursementMemberDetailStatus: 'Success',
			};
		case disbursementActionTypes.DISBURSEMENT_MEMBER_DETAILS_FAILURE:
			return {
				...state,
				disbursementMemberDetailResponse: null,
				disbursementMemberDetailStatus: 'Failure',
			};
		case disbursementActionTypes.DISBURSEMENT_MEMBER_DETAILS_CHANGED:
			return { ...state, disbursementMemberDetailStatus: null };

		case disbursementActionTypes.DISBURSEMENT_LIST_ID:
			return { ...state, disbursementCustomerData: action.payload };

		case disbursementActionTypes.DISBURSEMENT_REMARKS_VALUES:
			return { ...state, disbursementCustomerRemarksData: action.payload };

		case disbursementActionTypes.DISBURSEMENT_REMARKS_LOG_SUCCESS:
			return {
				...state,
				disbursementRemarksLogResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.REMARKS,
				),
				disbursementRemarksLogStatus: 'Success',
			};
		case disbursementActionTypes.DISBURSEMENT_REMARKS_LOG_FAILURE:
			return {
				...state,
				disbursementRemarksLogResponse: null,
				disbursementRemarksLogStatus: 'Failure',
			};
		case disbursementActionTypes.DISBURSEMENT_REMARKS_LOG_CHANGED:
			return { ...state, disbursementRemarksLogStatus: null };

		case disbursementActionTypes.DISBURSEMENT_LEADER_DATA_STORE:
			return { ...state, disbursementLeaderStore: action.payload };

		case disbursementActionTypes.DISBURSEMENT_LEADER_DATA_STORE_RESET:
			return { ...state, disbursementLeaderStore: null };

		case disbursementActionTypes.DISBURSEMENT_CONFIRMATINO_RESPONSE_SUCCESS:
			return {
				...state,
				disbursementConfirmationListResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.MEMBER,
				),
				disbursementConfirmationListStatus: 'Success',
			};
		case disbursementActionTypes.DISBURSEMENT_CONFIRMATINO_RESPONSE_FAILURE:
			return {
				...state,
				disbursementConfirmationListResponse: null,
				disbursementConfirmationListStatus: 'Failure',
			};
		case disbursementActionTypes.DISBURSEMENT_CONFIRMATINO_RESPONSE_CHANGED:
			return { ...state, disbursementConfirmationListStatus: null };

		case disbursementActionTypes.DISBURSEMENT_REJECT_MEMBER_SUCCESS:
			return {
				...state,
				disbursementRejectMemberResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.MEMBER,
				),
				disbursementRejectMemberStatus: 'Success',
			};
		case disbursementActionTypes.DISBURSEMENT_REJECT_MEMBER_FAILURE:
			return {
				...state,
				disbursementRejectMemberResponse: null,
				disbursementRejectMemberStatus: 'Failure',
			};
		case disbursementActionTypes.DISBURSEMENT_REJECT_MEMBER_CHANGED:
			return { ...state, disbursementRejectMemberStatus: null };

		// SQ SECTION
		case disbursementActionTypes.DISBURSEMENT_SQ_MEMBER_DETAILS_SUCCESS:
			return {
				...state,
				disbursementSQMemberDetailResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.MEMBER,
				),
				disbursementSQMemberDetailStatus: 'Success',
			};
		case disbursementActionTypes.DISBURSEMENT_SQ_MEMBER_DETAILS_FAILURE:
			return {
				...state,
				disbursementSQMemberDetailResponse: null,
				disbursementSQMemberDetailStatus: 'Failure',
			};
		case disbursementActionTypes.DISBURSEMENT_SQ_MEMBER_DETAILS_CHANGED:
			return { ...state, disbursementSQMemberDetailStatus: null };

		// DISBURSEMENT APPROVE SECTION
		case disbursementActionTypes.DISBURSEMENT_MEMBER_SUCCESS:
			return {
				...state,
				disbApproveMemberResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.LOANINDEX,
				),
				disbApproveMemberStatus: 'Success',
			};
		case disbursementActionTypes.DISBURSEMENT_MEMBER_FAILURE:
			return {
				...state,
				disbApproveMemberResponse: null,
				disbApproveMemberStatus: 'Failure',
			};
		case disbursementActionTypes.DISBURSEMENT_MEMBER_CHANGED:
			return { ...state, disbApproveMemberStatus: null };

		// REPORT SECTION
		case disbursementActionTypes.DISBURSEMENT_REPORT_SUCCESS:
			return {
				...state,
				disbReportResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.DISBUSREMENT,
				),
				disbReportStatus: 'Success',
			};
		case disbursementActionTypes.DISBURSEMENT_REPORT_FAILURE:
			return {
				...state,
				disbReportResponse: null,
				disbReportStatus: 'Failure',
			};
		case disbursementActionTypes.DISBURSEMENT_REPORT_CHANGED:
			return { ...state, disbReportStatus: null };

		default:
			return state;
	}
};

export default disbursementReducer;
