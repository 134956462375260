/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import profileActionTypes from './profile.types';
import generalTypesAction from '../genera.types';
import { getEndodeJsonData } from '../common/common.utils';

const INITIAL_STATE = {
	profileDetailsStatus: null,
	profileDetailsResponse: null,
	profileUpdateDetailsStatus: null,
	profileUpdateDetailsResponse: null,
};

// eslint-disable-next-line default-param-last
const profileReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case profileActionTypes.PROFILE_REPSONSE_SUCCESS:
			return {
				...state,
				profileDetailsResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.COMPANY,
				),
				profileDetailsStatus: 'Success',
			};
		case profileActionTypes.PROFILE_REPSONSE_FAILURE:
			return {
				...state,
				profileDetailsResponse: null,
				profileDetailsStatus: 'Failure',
			};
		case profileActionTypes.PROFILE_REPSONSE_CHANGED:
			return { ...state, profileDetailsStatus: null };

		case profileActionTypes.PROFILE_UPDATE_REPSONSE_SUCCESS:
			return {
				...state,
				profileUpdateDetailsResponse: action.payload,
				profileUpdateDetailsStatus: 'Success',
			};
		case profileActionTypes.PROFILE_UPDATE_REPSONSE_FAILURE:
			return {
				...state,
				profileUpdateDetailsResponse: action.payload,
				profileUpdateDetailsStatus: 'Failure',
			};
		case profileActionTypes.PROFILE_UPDATE_REPSONSE_CHANGED:
			return {
				...state,
				profileUpdateDetailsResponse: null,
				profileUpdateDetailsStatus: null,
			};
		default:
			return state;
	}
};

export default profileReducer;
