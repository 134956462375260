/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import compareActionTypes from './compare.types';
import generalTypesAction from '../genera.types';
import { getEndodeJsonData } from '../common/common.utils';

const INITIAL_STATE = {
	allCompareList: null,
	allCompareListStatus: null,

	compareMemberDetailStatus: null,
	compareMemberDetailResponse: null,

	compareCustomerData: null,
	compareCustomerRemarksData: null,

	compareRemarksLogStatus: null,
	compareRemarksLogResponse: null,

	compareLeaderStore: null,

	compareConfirmationListStatus: null,
	compareConfirmationListResponse: null,

	compareRejectMemberStatus: null,
	compareRejectMemberResponse: null,

	// SQ SECTION
	compareSQMemberDetailStatus: null,
	compareSQMemberDetailResponse: null,
};

// eslint-disable-next-line default-param-last
const compareReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case compareActionTypes.COMPARE_LIST_SUCCESS:
			return {
				...state,
				allCompareList: getEndodeJsonData(action.payload, generalTypesAction.MEMBER),
				allCompareListStatus: 'Success',
			};
		case compareActionTypes.COMPARE_LIST_FAILURE:
			return { ...state, allCompareList: null, allCompareListStatus: 'Failure' };
		case compareActionTypes.COMPARE_LIST_CHANGED:
			return { ...state, allCompareListStatus: null };

		case compareActionTypes.COMPARE_MEMBER_DETAILS_SUCCESS:
			return {
				...state,
				compareMemberDetailResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.MEMBER,
				),
				compareMemberDetailStatus: 'Success',
			};
		case compareActionTypes.COMPARE_MEMBER_DETAILS_FAILURE:
			return {
				...state,
				compareMemberDetailResponse: null,
				compareMemberDetailStatus: 'Failure',
			};
		case compareActionTypes.COMPARE_MEMBER_DETAILS_CHANGED:
			return { ...state, compareMemberDetailStatus: null };

		case compareActionTypes.COMPARE_LIST_ID:
			return { ...state, compareCustomerData: action.payload };

		case compareActionTypes.COMPARE_REMARKS_VALUES:
			return { ...state, compareCustomerRemarksData: action.payload };

		case compareActionTypes.COMPARE_REMARKS_LOG_SUCCESS:
			return {
				...state,
				compareRemarksLogResponse: getEndodeJsonData(action.payload, generalTypesAction.REMARKS),
				compareRemarksLogStatus: 'Success',
			};
		case compareActionTypes.COMPARE_REMARKS_LOG_FAILURE:
			return {
				...state,
				compareRemarksLogResponse: null,
				compareRemarksLogStatus: 'Failure',
			};
		case compareActionTypes.COMPARE_REMARKS_LOG_CHANGED:
			return { ...state, compareRemarksLogStatus: null };

		case compareActionTypes.COMPARE_LEADER_DATA_STORE:
			return { ...state, compareLeaderStore: action.payload };

		case compareActionTypes.COMPARE_LEADER_DATA_STORE_RESET:
			return { ...state, compareLeaderStore: null };

		case compareActionTypes.COMPARE_CONFIRMATINO_RESPONSE_SUCCESS:
			return {
				...state,
				compareConfirmationListResponse: getEndodeJsonData(action.payload, generalTypesAction.MEMBER),
				compareConfirmationListStatus: 'Success',
			};
		case compareActionTypes.COMPARE_CONFIRMATINO_RESPONSE_FAILURE:
			return { ...state, compareConfirmationListResponse: null, compareConfirmationListStatus: 'Failure' };
		case compareActionTypes.COMPARE_CONFIRMATINO_RESPONSE_CHANGED:
			return { ...state, compareConfirmationListStatus: null };

		case compareActionTypes.COMPARE_REJECT_MEMBER_SUCCESS:
			return {
				...state,
				compareRejectMemberResponse: getEndodeJsonData(action.payload, generalTypesAction.MEMBER),
				compareRejectMemberStatus: 'Success',
			};
		case compareActionTypes.COMPARE_REJECT_MEMBER_FAILURE:
			return {
				...state,
				compareRejectMemberResponse: null,
				compareRejectMemberStatus: 'Failure',
			};
		case compareActionTypes.COMPARE_REJECT_MEMBER_CHANGED:
			return { ...state, compareRejectMemberStatus: null };

		// SQ SECTION
		case compareActionTypes.COMPARE_SQ_MEMBER_DETAILS_SUCCESS:
			return {
				...state,
				compareSQMemberDetailResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.MEMBER,
				),
				compareSQMemberDetailStatus: 'Success',
			};
		case compareActionTypes.COMPARE_SQ_MEMBER_DETAILS_FAILURE:
			return {
				...state,
				compareSQMemberDetailResponse: null,
				compareSQMemberDetailStatus: 'Failure',
			};
		case compareActionTypes.COMPARE_SQ_MEMBER_DETAILS_CHANGED:
			return { ...state, compareSQMemberDetailStatus: null };

		default:
			return state;
	}
};

export default compareReducer;
