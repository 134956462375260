/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import loginActionTypes from './login.types';

export const signInSuccess = (user) => ({
	type: loginActionTypes.SIGN_IN_SUCCESS,
	payload: user,
});

export const signInFailure = (error) => ({
	type: loginActionTypes.SIGN_IN_FAILURE,
	payload: error,
});

export const signInStart = (userCredentials) => ({
	type: loginActionTypes.SIGN_IN_START,
	payload: userCredentials,
});

export const signInChanged = () => ({
	type: loginActionTypes.SIGN_IN_CHANGED,
});

export const signInReset = () => ({
	type: loginActionTypes.SIGN_IN_RESET,
});

export const loginCompany = (companyID) => ({
	type: loginActionTypes.SIGN_IN_COMPANYID,
	payload: companyID,
});

export const loginUser = (userID) => ({
	type: loginActionTypes.SIGN_IN_USER,
	payload: userID,
});

export const loginUserType = (userType) => ({
	type: loginActionTypes.SIGN_IN_USERTYPE,
	payload: userType,
});

export const signOutChanged = () => ({
	type: loginActionTypes.SIGN_OUT_CHANGED,
});

export const signOutReset = () => ({
	type: loginActionTypes.SIGN_OUT_RESET,
});