/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import disbursementActionTypes from './disbursement.types';

export const getAllList = (Disbursement) => ({
	type: disbursementActionTypes.DISBURSEMENT_LIST_START,
	payload: Disbursement,
});

export const getAllListSuccess = (Disbursement) => ({
	type: disbursementActionTypes.DISBURSEMENT_LIST_SUCCESS,
	payload: Disbursement,
});

export const getAllListFailure = (error) => ({
	type: disbursementActionTypes.DISBURSEMENT_LIST_FAILURE,
	payload: error,
});

export const getAllListChanged = () => ({
	type: disbursementActionTypes.DISBURSEMENT_LIST_CHANGED,
});

export const getAllListReset = () => ({
	type: disbursementActionTypes.DISBURSEMENT_LIST_RESET,
});

export const getDisbursementMemberDetailsStart = (Disbursement) => ({
	type: disbursementActionTypes.DISBURSEMENT_MEMBER_DETAILS_START,
	payload: Disbursement,
});

export const getDisbursementMemberDetailsSuccess = (Disbursement) => ({
	type: disbursementActionTypes.DISBURSEMENT_MEMBER_DETAILS_SUCCESS,
	payload: Disbursement,
});

export const getDisbursementMemberDetailsFailure = (error) => ({
	type: disbursementActionTypes.DISBURSEMENT_MEMBER_DETAILS_FAILURE,
	payload: error,
});

export const getDisbursementMemberDetailsChanged = () => ({
	type: disbursementActionTypes.DISBURSEMENT_MEMBER_DETAILS_CHANGED,
});

export const getDisbursementMemberDetailsReset = () => ({
	type: disbursementActionTypes.DISBURSEMENT_MEMBER_DETAILS_RESET,
});

export const getSelectedCustomerID = (CustomerData) => ({
	type: disbursementActionTypes.DISBURSEMENT_LIST_ID,
	payload: CustomerData,
});

export const selectedMemberRemarks = (CustomerData) => ({
	type: disbursementActionTypes.DISBURSEMENT_REMARKS_VALUES,
	payload: CustomerData,
});

export const disbursementRemarksStart = (remarksDetails) => ({
	type: disbursementActionTypes.DISBURSEMENT_REMARKS_LOG_START,
	payload: remarksDetails,
});

export const disbursementRemarksSuccess = (Disbursement) => ({
	type: disbursementActionTypes.DISBURSEMENT_REMARKS_LOG_SUCCESS,
	payload: Disbursement,
});

export const disbursementRemarksFailure = (error) => ({
	type: disbursementActionTypes.DISBURSEMENT_REMARKS_LOG_FAILURE,
	payload: error,
});

export const disbursementRemarksChanged = () => ({
	type: disbursementActionTypes.DISBURSEMENT_REMARKS_LOG_CHANGED,
});

export const disbursementRemarksReset = () => ({
	type: disbursementActionTypes.DISBURSEMENT_REMARKS_LOG_RESET,
});


export const disbursementFilterSearchStart = (searchVal) => ({
	type: disbursementActionTypes.DISBURSEMENT_FILTER_SEARCH_START,
	payload: searchVal,
});

export const disbursementLeaderDataStore = (storeVal) => ({
	type: disbursementActionTypes.DISBURSEMENT_LEADER_DATA_STORE,
	payload: storeVal,
});

export const disbursementLeaderDataStoreReset = () => ({
	type: disbursementActionTypes.DISBURSEMENT_LEADER_DATA_STORE_RESET,
});

export const disbursementConfirmationListStart = (disbursementGroup) => ({
	type: disbursementActionTypes.DISBURSEMENT_CONFIRMATINO_RESPONSE_START,
	payload: disbursementGroup,
});

export const disbursementConfirmationListSuccess = (success) => ({
	type: disbursementActionTypes.DISBURSEMENT_CONFIRMATINO_RESPONSE_SUCCESS,
	payload: success,
});

export const disbursementConfirmationListFailure = (error) => ({
	type: disbursementActionTypes.DISBURSEMENT_CONFIRMATINO_RESPONSE_FAILURE,
	payload: error,
});

export const disbursementConfirmationListChanged = () => ({
	type: disbursementActionTypes.DISBURSEMENT_CONFIRMATINO_RESPONSE_CHANGED,
});

export const disbursementConfirmationListReset = () => ({
	type: disbursementActionTypes.DISBURSEMENT_CONFIRMATINO_RESPONSE_RESET,
});

export const disbursementRejectMemberStart = (RejectMemberDetails) => ({
	type: disbursementActionTypes.DISBURSEMENT_REJECT_MEMBER_START,
	payload: RejectMemberDetails,
});

export const disbursementRejectMemberSuccess = (success) => ({
	type: disbursementActionTypes.DISBURSEMENT_REJECT_MEMBER_SUCCESS,
	payload: success,
});

export const disbursementRejectMemberFailure = (error) => ({
	type: disbursementActionTypes.DISBURSEMENT_REJECT_MEMBER_FAILURE,
	payload: error,
});

export const disbursementRejectMemberChanged = () => ({
	type: disbursementActionTypes.DISBURSEMENT_REJECT_MEMBER_CHANGED,
});

export const disbursementRejectMemberReset = () => ({
	type: disbursementActionTypes.DISBURSEMENT_REJECT_MEMBER_RESET,
});

// SQUARENOW SECTION

export const getDisbursementSQMemberDetailsStart = (Disbursement) => ({
	type: disbursementActionTypes.DISBURSEMENT_SQ_MEMBER_DETAILS_START,
	payload: Disbursement,
});

export const getDisbursementSQMemberDetailsSuccess = (Disbursement) => ({
	type: disbursementActionTypes.DISBURSEMENT_SQ_MEMBER_DETAILS_SUCCESS,
	payload: Disbursement,
});

export const getDisbursementSQMemberDetailsFailure = (error) => ({
	type: disbursementActionTypes.DISBURSEMENT_SQ_MEMBER_DETAILS_FAILURE,
	payload: error,
});

export const getDisbursementSQMemberDetailsChanged = () => ({
	type: disbursementActionTypes.DISBURSEMENT_SQ_MEMBER_DETAILS_CHANGED,
});

export const getDisbursementSQMemberDetailsReset = () => ({
	type: disbursementActionTypes.DISBURSEMENT_SQ_MEMBER_DETAILS_RESET,
});

// DISBURSEMENT APPROVE SECTION
export const disbApproveMemberStart = (RejectMemberDetails) => ({
	type: disbursementActionTypes.DISBURSEMENT_MEMBER_START,
	payload: RejectMemberDetails,
});

export const disbApproveMemberSuccess = (success) => ({
	type: disbursementActionTypes.DISBURSEMENT_MEMBER_SUCCESS,
	payload: success,
});

export const disbApproveMemberFailure = (error) => ({
	type: disbursementActionTypes.DISBURSEMENT_MEMBER_FAILURE,
	payload: error,
});

export const disbApproveMemberChanged = () => ({
	type: disbursementActionTypes.DISBURSEMENT_MEMBER_CHANGED,
});

export const disbApproveMemberReset = () => ({
	type: disbursementActionTypes.DISBURSEMENT_MEMBER_RESET,
});

// REPORT SECTION
export const disbursementReportStart = (data) => ({
	type: disbursementActionTypes.DISBURSEMENT_REPORT_START,
	payload: data,
});

export const disbursementReportSuccess = (success) => ({
	type: disbursementActionTypes.DISBURSEMENT_REPORT_SUCCESS,
	payload: success,
});

export const disbursementReportFailure = (error) => ({
	type: disbursementActionTypes.DISBURSEMENT_REPORT_FAILURE,
	payload: error,
});

export const disbursementReportChanged = () => ({
	type: disbursementActionTypes.DISBURSEMENT_REPORT_CHANGED,
});

export const disbursementReportReset = () => ({
	type: disbursementActionTypes.DISBURSEMENT_REPORT_RESET,
});