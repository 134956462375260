/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import TargetActionTypes from './target.types';
import {
	targetDetailsAdd,
	targetListGet,
	targetDetailsFetch,
	targetDetailsDelete,
} from './target.services';
import {
	addTargetSuccess,
	addTargetFailure,
	addTargetResponseChanged,
	getTargetSuccess,
	getTargetFailure,
	getTargetResponseChanged,
	fetchTargetSuccess,
	fetchTargetFailure,
	fetchTargetResponseChanged,
	deleteTargetSuccess,
	deleteTargetFailure,
	deleteTargetResponseChanged,
} from './target.actions';
import { encodeJson } from '../enode-decode';

/** *********************  START ADD BRANCH TARGET DETAILS  ******************** */
export function* addTargetDetails({ payload }) {
	try {
		const key = 'AddTargetDetails';
		const jsonData = {
			TargetType: payload.targetType,
			TargetFor: payload.targetFor,
			TargetDate: payload.targetDate,
			TargetListArr: payload.targetListArr,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(targetDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addTargetFailure(responseData.data));
		} else {
			yield put(addTargetSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addTargetFailure(error));
	}
}

export function* resetAddTargetResponse() {
	yield put(addTargetResponseChanged());
}

export function* onAddTargetDetails() {
	yield takeLatest(TargetActionTypes.ADD_TARGET_START, addTargetDetails);
}

export function* onAddTargetDetailsResponseReset() {
	yield takeLatest(TargetActionTypes.ADD_TARGET_RESPONSE_RESET, resetAddTargetResponse);
}
/** *********************  END ADD BRANCH TARGET DETAILS ******************** */

/** *********************  START GET BRANCH TARGET LIST  ******************** */
export function* getTargetList({ payload }) {
	try {
		const key = 'GetTargetListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			Limit: payload.limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(targetListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getTargetFailure(responseData.data));
		} else {
			yield put(getTargetSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getTargetFailure(error));
	}
}

export function* resetGetTargetListResponse() {
	yield put(getTargetResponseChanged());
}

export function* onGetTargetListDetails() {
	yield takeLatest(TargetActionTypes.GET_TARGET_LIST_START, getTargetList);
}

export function* onGetTargetListResponseReset() {
	yield takeLatest(TargetActionTypes.GET_TARGET_LIST_RESPONSE_RESET, resetGetTargetListResponse);
}
/** *********************  END GET BRANCH TARGET LIST ******************** */

/** *********************  START FETCH BRANCH TARGET DETAILS  ******************** */
export function* fetchTargetDetails({ payload }) {
	try {
		const key = 'GetTargetDetails';
		const jsonData = {
			TargetType: payload.targetType,
			TargetFor: payload.targetFor,
			TargetDate: payload.targetDate,
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(targetDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchTargetFailure(responseData.data));
		} else {
			yield put(fetchTargetSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchTargetFailure(error));
	}
}

export function* resetFetchTargetDetailsResponse() {
	yield put(fetchTargetResponseChanged());
}

export function* onFetchTargetDetails() {
	yield takeLatest(TargetActionTypes.FETCH_TARGET_START, fetchTargetDetails);
}

export function* onFetchTargetDetailsResponseReset() {
	yield takeLatest(
		TargetActionTypes.FETCH_TARGET_RESPONSE_RESET,
		resetFetchTargetDetailsResponse,
	);
}
/** *********************  END FETCH BRANCH TARGET DETAILS ******************** */

/** *********************  START DELETE BRANCH TARGET DETAILS  ******************** */
export function* deleteTargetDetails({ payload }) {
	try {
		const key = 'DeleteTargetKeyDetails';
		const jsonData = {
			CompanyID: payload.companyID,
			TargetID: payload.targetID,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(targetDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteTargetFailure(responseData.data));
		} else {
			yield put(deleteTargetSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteTargetFailure(error));
	}
}

export function* resetDeleteTargetDetailsResponse() {
	yield put(deleteTargetResponseChanged());
}

export function* onDeleteTargetDetails() {
	yield takeLatest(TargetActionTypes.DELETE_TARGET_START, deleteTargetDetails);
}

export function* onDeleteTargetDetailsResponseReset() {
	yield takeLatest(
		TargetActionTypes.DELETE_TARGET_RESPONSE_RESET,
		resetDeleteTargetDetailsResponse,
	);
}
/** *********************  END DELETE BRANCH TARGET DETAILS ******************** */

export function* targetSaga() {
	yield all([
		call(onAddTargetDetails),
		call(onAddTargetDetailsResponseReset),
		call(onGetTargetListDetails),
		call(onGetTargetListResponseReset),
		call(onFetchTargetDetails),
		call(onFetchTargetDetailsResponseReset),
		call(onDeleteTargetDetails),
		call(onDeleteTargetDetailsResponseReset),
	]);
}
