/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
// import JWT from 'expo-jwt';
import ndcActionTypes from './memberNDC.types';
import generalTypesAction from '../genera.types';
import { ndcListApi, ndcApproveApi } from './memberNDC.services';
import {
	ndcListSuccess,
	ndcListFailure,
	ndcListChanged,
	ndcApproveSuccess,
	ndcApproveFailure,
	ndcApproveChanged,
} from './memberNDC.actions';
import { encodeJson } from '../enode-decode';

export function* getAllNDCListStart({ payload: { companyID } }) {
	try {
		const jsonData = {
			CompanyID: companyID,
		};
		const NDCData = encodeJson(jsonData, generalTypesAction.NDC);
		console.log('NDCData', NDCData);
		const responseData = yield call(ndcListApi, NDCData);
		if (responseData.data.statusCode === '100') {
			yield put(ndcListFailure(responseData.data));
		} else {
			yield put(ndcListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(ndcListFailure(error));
	}
}

export function* ndcApproveResponseStart({ payload: { approveData } }) {
	try {
		const jsonData = {
			UserID: approveData.userID,
			MemberID: approveData.memberID,
			LoanRequestID: approveData.loanRequestID,
			LoanInfoID: approveData.loanInfoID,
			CompanyID: approveData.companyID,
		};
		const NDCApproveData = encodeJson(jsonData, generalTypesAction.NDC);
		console.log('NDCApproveData', NDCApproveData);
		const responseData = yield call(ndcApproveApi, NDCApproveData);
		if (responseData.data.statusCode === '100') {
			yield put(ndcApproveFailure(responseData.data));
		} else {
			yield put(ndcApproveSuccess(responseData.data));
		}
	} catch (error) {
		yield put(ndcApproveFailure(error));
	}
}

export function* getAllNDCListResponseReset() {
	yield put(ndcListChanged());
}

export function* ndcApproveResponseReset() {
	yield put(ndcApproveChanged());
}

export function* getAllNDCList() {
	yield takeLatest(ndcActionTypes.NDC_LIST_REPSONSE_START, getAllNDCListStart);
}

export function* getAllNDCListReset() {
	yield takeLatest(ndcActionTypes.NDC_LIST_REPSONSE_RESET, getAllNDCListResponseReset);
}

export function* ndcApproveStart() {
	yield takeLatest(ndcActionTypes.NDC_APPROVE_RESPONSE_START, ndcApproveResponseStart);
}

export function* ndcApproveReset() {
	yield takeLatest(ndcActionTypes.NDC_APPROVE_RESPONSE_RESET, ndcApproveResponseReset);
}

export function* ndcSaga() {
	yield all([
		call(getAllNDCList),
		call(getAllNDCListReset),
		call(ndcApproveStart),
		call(ndcApproveReset),
	]);
}
