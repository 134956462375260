const bankActionTypes = {
	MEMBER_ANALYSE_BANK_STATEMENT_START: 'member_analyse_bank_statement_start',
	MEMBER_ANALYSE_BANK_STATEMENT_SUCCESS: 'member_analyse_bank_statement_success',
	MEMBER_ANALYSE_BANK_STATEMENT_FAILURE: 'member_analyse_bank_statement_failure',
	MEMBER_ANALYSE_BANK_STATEMENT_CHANGED: 'member_analyse_bank_statement_changed',
	MEMBER_ANALYSE_BANK_STATEMENT_RESET: 'member_analyse_bank_statement_reset',

	UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_START: 'update_member_analyse_bank_statement_start',
	UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_SUCCESS: 'update_member_analyse_bank_statement_success',
	UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_FAILURE: 'update_member_analyse_bank_statement_failure',
	UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_CHANGED: 'update_member_analyse_bank_statement_changed',
	UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_RESET: 'update_member_analyse_bank_statement_reset',

	MEMBER_INKREDO_BANK_STATEMENT_START: 'member_inkredo_bank_statement_start',
	MEMBER_INKREDO_BANK_STATEMENT_SUCCESS: 'member_inkredo_bank_statement_success',
	MEMBER_INKREDO_BANK_STATEMENT_FAILURE: 'member_inkredo_bank_statement_failure',
	MEMBER_INKREDO_BANK_STATEMENT_CHANGED: 'member_inkredo_bank_statement_changed',
	MEMBER_INKREDO_BANK_STATEMENT_RESET: 'member_inkredo_bank_statement_reset',

	UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_JSON_START:
		'update_member_analyse_bank_statement_json_start',
	UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_JSON_SUCCESS:
		'update_member_analyse_bank_statement_json_success',
	UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_JSON_FAILURE:
		'update_member_analyse_bank_statement_json_failure',
	UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_JSON_CHANGED:
		'update_member_analyse_bank_statement_json_changed',
	UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_JSON_RESET:
		'update_member_analyse_bank_statement_json_reset',
};
export default bankActionTypes;
