const ndcActionTypes = {
	NDC_LIST_REPSONSE_START: 'ndc_list_response_start',
	NDC_LIST_REPSONSE_SUCCESS: 'ndc_list_response_success',
	NDC_LIST_REPSONSE_FAILURE: 'ndc_list_response_failure',
	NDC_LIST_REPSONSE_CHANGED: 'ndc_list_response_changed',
	NDC_LIST_REPSONSE_RESET: 'ndc_list_response_reset',

	NDC_APPROVE_RESPONSE_START: 'ndc_approve_response_start',
	NDC_APPROVE_RESPONSE_SUCCESS: 'ndc_approve_response_success',
	NDC_APPROVE_RESPONSE_FAILURE: 'ndc_approve_response_failure',
	NDC_APPROVE_RESPONSE_CHANGED: 'ndc_approve_response_changed',
	NDC_APPROVE_RESPONSE_RESET: 'ndc_approve_response_reset',
};
export default ndcActionTypes;
