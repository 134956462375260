const profileActionTypes = {
	PROFILE_REPSONSE_START: 'profile_response_start',
	PROFILE_REPSONSE_SUCCESS: 'profile_response_success',
	PROFILE_REPSONSE_FAILURE: 'profile_response_failure',
	PROFILE_REPSONSE_CHANGED: 'profile_response_changed',
	PROFILE_REPSONSE_RESET: 'profile_response_reset',

	PROFILE_UPDATE_REPSONSE_START: 'profile_update_response_start',
	PROFILE_UPDATE_REPSONSE_SUCCESS: 'profile_update_response_success',
	PROFILE_UPDATE_REPSONSE_FAILURE: 'profile_update_response_failure',
	PROFILE_UPDATE_REPSONSE_CHANGED: 'profile_update_response_changed',
	PROFILE_UPDATE_REPSONSE_RESET: 'profile_update_response_reset',
};
export default profileActionTypes;
