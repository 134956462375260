const TargetActionTypes = {
	ADD_TARGET_START: 'add_target_start',
	ADD_TARGET_SUCCESS: 'add_target_success',
	ADD_TARGET_FAILURE: 'add_target_failure',
	ADD_TARGET_RESPONSE_RESET: 'add_target_response_reset',
	ADD_TARGET_RESPONSE_CHANGED: 'add_target_response_changed',

	GET_TARGET_LIST_START: 'get_target_list_start',
	GET_TARGET_LIST_SUCCESS: 'get_target_list_success',
	GET_TARGET_LIST_FAILURE: 'get_target_list_failure',
	GET_TARGET_LIST_RESPONSE_RESET: 'get_target_list_response_reset',
	GET_TARGET_LIST_RESPONSE_CHANGED: 'get_target_list_response_changed',

	FETCH_TARGET_START: 'fetch_target_start',
	FETCH_TARGET_SUCCESS: 'fetch_target_success',
	FETCH_TARGET_FAILURE: 'fetch_target_failure',
	FETCH_TARGET_RESPONSE_RESET: 'fetch_target_response_reset',
	FETCH_TARGET_RESPONSE_CHANGED: 'fetch_target_response_changed',
	FETCH_TARGET_RESPONSE_ARRAY_RESET: 'fetch_target_response_array_reset',

	DELETE_TARGET_START: 'delete_target_start',
	DELETE_TARGET_SUCCESS: 'delete_target_success',
	DELETE_TARGET_FAILURE: 'delete_target_failure',
	DELETE_TARGET_RESPONSE_RESET: 'delete_target_response_reset',
	DELETE_TARGET_RESPONSE_CHANGED: 'delete_target_response_changed',
};
export default TargetActionTypes;
