/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import RepaymentReportActionTypes from './repaymentReport.types';
import generalTypesAction from '../genera.types';
import { getAllRepaymentReport } from './repaymentReport.services';
import {
	getAllRepaymentListSuccess,
	getAllRepaymentListFailure,
	getAllRepaymentListChanged,
} from './repaymentReport.actions';
import { encodeJson } from '../enode-decode';

export function* getListOfRepayment({ payload }) {
	try {
		const jsonData = {
			Limit: payload.limit,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			BranchID: payload.branchID,
		};
		const encodedData = encodeJson(jsonData, generalTypesAction.REPAYMENT);
		const responseData = yield call(getAllRepaymentReport, encodedData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(getAllRepaymentListFailure(responseData.data));
		} else {
			yield put(getAllRepaymentListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getAllRepaymentListFailure(error));
	}
}

export function* repayementFilterList({ payload }) {
	try {
		const jsonData = {
			SearchVal: payload.searchVal,
			CompanyID: payload.companyID,
		};
		const encodedData = encodeJson(jsonData, generalTypesAction.REPAYMENT);
		const responseData = yield call(getAllRepaymentReport, encodedData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(getAllRepaymentListFailure(responseData.data));
		} else {
			yield put(getAllRepaymentListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getAllRepaymentListFailure(error));
	}
}

export function* getListOfRepaymentReset() {
	yield put(getAllRepaymentListChanged());
}
export function* getAllRepaymentList() {
	yield takeLatest(RepaymentReportActionTypes.REPAYMENT_REPORT_LIST_START, getListOfRepayment);
}

export function* getAllRepaymentListReset() {
	yield takeLatest(RepaymentReportActionTypes.REPAYMENT_REPORT_LIST_RESET, getListOfRepaymentReset);
}

export function* getAllRepaymentFilterList() {
	yield takeLatest(RepaymentReportActionTypes.REPAYMENT_REPORT_GET_FILTER_SEARCH_LIST, repayementFilterList);
}
export function* repaymentReportSaga() {
	yield all([
		call(getAllRepaymentList),
		call(getAllRepaymentListReset),
		call(getAllRepaymentFilterList),
	]);
}
