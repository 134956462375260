/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import companyActionTypes from './company.types';
import generalTypesAction from '../genera.types';
import { getEndodeJsonData } from '../common/common.utils';

const INITIAL_STATE = {
	companyCreateStatus: null,
	companyCreateResponse: null,
	companyUpdateStatus: null,
	companyUpdateResponse: null,
	companyDeleteStatus: null,
	companyDeleteResponse: null,
	allCompanyList: null,
	// LMS CALL SECTION
	companyLMSCallStatus: null,
	companyLMSCallResponse: null,
	// LMS CALL SECTION
	companyListSelectStatus: null,
	companyListSelectResponse: null,
	// LMS CALL SECTION
	companyCondListStatus: null,
	companyCondListResponse: null,

	companyCondCreateStatus: null,
	companyCondCreateResponse: null,

	companyCondUpdateStatus: null,
	companyCondUpdateResponse: null,

	// LOAN SCHEDULE SECTION
	companyLoanScheduleStatus: null,
	companyLoanScheduleResponse: null,
};

// eslint-disable-next-line default-param-last
const companyReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case companyActionTypes.COMPANY_CREATE_REPSONSE_SUCCESS:
			return {
				...state,
				companyCreateResponse: action.payload,
				companyCreateStatus: 'Success',
			};
		case companyActionTypes.COMPANY_CREATE_REPSONSE_FAILURE:
			return {
				...state,
				companyCreateResponse: action.payload,
				companyCreateStatus: 'Failure',
			};
		case companyActionTypes.COMPANY_CREATE_REPSONSE_CHANGED:
			return { ...state, companyCreateResponse: null, companyCreateStatus: null };

		case companyActionTypes.COMPANY_UPDATE_REPSONSE_SUCCESS:
			return {
				...state,
				companyUpdateResponse: action.payload,
				companyUpdateStatus: 'Success',
			};
		case companyActionTypes.COMPANY_UPDATE_REPSONSE_FAILURE:
			return {
				...state,
				companyUpdateResponse: action.payload,
				companyUpdateStatus: 'Failure',
			};
		case companyActionTypes.COMPANY_UPDATE_REPSONSE_CHANGED:
			return { ...state, companyUpdateResponse: null, companyUpdateStatus: null };

		case companyActionTypes.COMPANY_DELETE_REPSONSE_SUCCESS:
			return {
				...state,
				companyDeleteResponse: action.payload,
				companyDeleteStatus: 'Success',
			};
		case companyActionTypes.COMPANY_DELETE_REPSONSE_FAILURE:
			return {
				...state,
				companyDeleteResponse: action.payload,
				companyDeleteStatus: 'Failure',
			};
		case companyActionTypes.COMPANY_DELETE_REPSONSE_CHANGED:
			return { ...state, companyDeleteResponse: null, companyDeleteStatus: null };

		case companyActionTypes.COMPANY_LIST_SUCCESS:
			return { ...state, allCompanyList: action.payload, companyStatus: 'Success' };
		case companyActionTypes.COMPANY_LIST_FAILURE:
			return { ...state, allCompanyList: action.payload, companyStatus: 'Failure' };

		// LMS CALL SECTION
		case companyActionTypes.COMPANY_LMS_CALL_SUCCESS:
			return {
				...state,
				companyLMSCallResponse: action.payload,
				companyLMSCallStatus: 'Success',
			};
		case companyActionTypes.COMPANY_LMS_CALL_FAILURE:
			return {
				...state,
				companyLMSCallResponse: null,
				companyLMSCallStatus: 'Failure',
			};
		case companyActionTypes.COMPANY_LMS_CALL_CHANGED:
			return { ...state, companyLMSCallStatus: null };

		// COMPANY LIST SELECT
		case companyActionTypes.COMPANY_LIST_SELECT_SUCCESS:
			return {
				...state,
				companyListSelectResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.COMPANY,
				),
				companyListSelectStatus: 'Success',
			};
		case companyActionTypes.COMPANY_LIST_SELECT_FAILURE:
			return {
				...state,
				companyListSelectResponse: null,
				companyListSelectStatus: 'Failure',
			};
		case companyActionTypes.COMPANY_LIST_SELECT_CHANGED:
			return { ...state, companyListSelectStatus: null };

		// COMPANY COND LIST SELECT
		case companyActionTypes.COMPANY_COND_LIST_SUCCESS:
			return {
				...state,
				companyCondListResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.COMPANY,
				),
				companyCondListStatus: 'Success',
			};
		case companyActionTypes.COMPANY_COND_LIST_FAILURE:
			return {
				...state,
				companyCondListResponse: null,
				companyCondListStatus: 'Failure',
			};
		case companyActionTypes.COMPANY_COND_LIST_CHANGED:
			return { ...state, companyCondListStatus: null };

		// COMPANY COND CREATE SELECT
		case companyActionTypes.COMPANY_COND_CREATE_SUCCESS:
			return {
				...state,
				companyCondCreateResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.COMPANY,
				),
				companyCondCreateStatus: 'Success',
			};
		case companyActionTypes.COMPANY_COND_CREATE_FAILURE:
			return {
				...state,
				companyCondCreateResponse: null,
				companyCondCreateStatus: 'Failure',
			};
		case companyActionTypes.COMPANY_COND_CREATE_CHANGED:
			return { ...state, companyCondCreateStatus: null };

		// COMPANY COND UPDATE SELECT
		case companyActionTypes.COMPANY_COND_UPDATE_SUCCESS:
			return {
				...state,
				companyCondUpdateResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.COMPANY,
				),
				companyCondUpdateStatus: 'Success',
			};
		case companyActionTypes.COMPANY_COND_UPDATE_FAILURE:
			return {
				...state,
				companyCondUpdateResponse: null,
				companyCondUpdateStatus: 'Failure',
			};
		case companyActionTypes.COMPANY_COND_UPDATE_CHANGED:
			return { ...state, companyCondUpdateStatus: null };
		// LOAN SCHEDULE SECTION
		case companyActionTypes.COMPANY_LOAN_SCHEDULE_SUCCESS:
			return {
				...state,
				companyLoanScheduleResponse: action.payload,
				companyLoanScheduleStatus: 'Success',
			};
		case companyActionTypes.COMPANY_LOAN_SCHEDULE_FAILURE:
			return {
				...state,
				companyLoanScheduleResponse: null,
				companyLoanScheduleStatus: 'Failure',
			};
		case companyActionTypes.COMPANY_LOAN_SCHEDULE_CHANGED:
			return { ...state, companyLoanScheduleStatus: null };
		default:
			return state;
	}
};

export default companyReducer;
