const departmentActionTypes = {
	DEPARTMENT_CREATE_REPSONSE_START: 'department_create_response_start',
	DEPARTMENT_CREATE_REPSONSE_SUCCESS: 'department_create_response_success',
	DEPARTMENT_CREATE_REPSONSE_FAILURE: 'department_create_response_failure',
	DEPARTMENT_CREATE_REPSONSE_CHANGED: 'department_create_response_changed',
	DEPARTMENT_UPDATE_REPSONSE_START: 'department_update_response_start',
	DEPARTMENT_UPDATE_REPSONSE_SUCCESS: 'department_update_response_success',
	DEPARTMENT_UPDATE_REPSONSE_FAILURE: 'department_update_response_failure',
	DEPARTMENT_UPDATE_REPSONSE_CHANGED: 'department_update_response_changed',
	DEPARTMENT_DELETE_REPSONSE_START: 'department_delete_response_start',
	DEPARTMENT_DELETE_REPSONSE_SUCCESS: 'department_delete_response_success',
	DEPARTMENT_DELETE_REPSONSE_FAILURE: 'department_delete_response_failure',
	DEPARTMENT_DELETE_REPSONSE_CHANGED: 'department_delete_response_changed',
	DEPARTMENT_GET_ALL_LIST: 'department_get_all_list',
	DEPARTMENT_LIST_START: 'department_list_start',
	DEPARTMENT_LIST_SUCCESS: 'department_list_success',
	DEPARTMENT_LIST_FAILURE: 'department_list_failure',
	DEPARTMENT_LIST_ID: 'department_list_id',
	DEPARTMENT_LIST_NAME: 'department_list_name',
};
export default departmentActionTypes;
