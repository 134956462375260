const staffActionTypes = {
	STAFF_CREATE_REPSONSE_START: 'staff_create_response_start',
	STAFF_CREATE_REPSONSE_SUCCESS: 'staff_create_response_success',
	STAFF_CREATE_REPSONSE_FAILURE: 'staff_create_response_failure',
	STAFF_CREATE_REPSONSE_CHANGED: 'staff_create_response_changed',
	STAFF_CREATE_REPSONSE_RESET: 'staff_create_response_reset',

	STAFF_UPDATE_REPSONSE_START: 'staff_update_response_start',
	STAFF_UPDATE_REPSONSE_SUCCESS: 'staff_update_response_success',
	STAFF_UPDATE_REPSONSE_FAILURE: 'staff_update_response_failure',
	STAFF_UPDATE_REPSONSE_CHANGED: 'staff_update_response_changed',
	STAFF_UPDATE_REPSONSE_RESET: 'staff_update_response_reset',

	STAFF_DELETE_REPSONSE_START: 'staff_delete_response_start',
	STAFF_DELETE_REPSONSE_SUCCESS: 'staff_delete_response_success',
	STAFF_DELETE_REPSONSE_FAILURE: 'staff_delete_response_failure',
	STAFF_DELETE_REPSONSE_CHANGED: 'staff_delete_response_changed',
	STAFF_DELETE_REPSONSE_RESET: 'staff_delete_response_reset',

	STAFF_GET_ALL_LIST: 'staff_get_all_list',
	STAFF_LIST_START: 'staff_list_start',
	STAFF_LIST_SUCCESS: 'staff_list_success',
	STAFF_LIST_FAILURE: 'staff_list_failure',
	STAFF_LIST_CHANGED: 'staff_list_changed',
	STAFF_LIST_RESET: 'staff_list_reset',
	STAFF_LIST_ID: 'staff_list_id',
	STAFF_LIST_NAME: 'staff_list_name',

	STAFF_GET_FILTER_SEARCH_LIST: 'staff_get_filter_search_list',
};
export default staffActionTypes;
