const loanIndexActionTypes = {
	SET_LOAN_LIMIT_START: 'set_loan_limit_start',
	SET_LOAN_LIMIT_SUCCESS: 'set_loan_limit_success',
	SET_LOAN_LIMIT_FAILURE: 'set_loan_limit_failure',
	SET_LOAN_LIMIT_CHANGED: 'set_loan_limit_changed',
	SET_LOAN_LIMIT_RESET: 'set_loan_limit_reset',

	LOAN_DISBURSEMENT_OFFLINE_START: 'loan_disbursement_offline_start',
	LOAN_DISBURSEMENT_OFFLINE_SUCCESS: 'loan_disbursement_offline_success',
	LOAN_DISBURSEMENT_OFFLINE_FAILURE: 'loan_disbursement_offline_failure',
	LOAN_DISBURSEMENT_OFFLINE_CHANGED: 'loan_disbursement_offline_changed',
	LOAN_DISBURSEMENT_OFFLINE_RESET: 'loan_disbursement_offline_reset',

	SET_LOAN_LIMIT_GROUP_START: 'SET_LOAN_LIMIT_GROUP_START',
	SET_LOAN_LIMIT_GROUP_SUCCESS: 'SET_LOAN_LIMIT_GROUP_SUCCESS',
	SET_LOAN_LIMIT_GROUP_FAILURE: 'SET_LOAN_LIMIT_GROUP_FAILURE',
	SET_LOAN_LIMIT_GROUP_CHANGED: 'SET_LOAN_LIMIT_GROUP_CHANGED',
	SET_LOAN_LIMIT_GROUP_RESET: 'SET_LOAN_LIMIT_GROUP_RESET',

	SET_LOAN_PRODUCT_START: 'set_loan_product_start',
	SET_LOAN_PRODUCT_SUCCESS: 'set_loan_product_success',
	SET_LOAN_PRODUCT_FAILURE: 'set_loan_product_failure',
	SET_LOAN_PRODUCT_CHANGED: 'set_loan_product_changed',
	SET_LOAN_PRODUCT_RESET: 'set_loan_product_reset',

	LOAN_REQUEST_CALCULATION: 'loan_request_calculation',
};
export default loanIndexActionTypes;
