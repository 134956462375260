/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { PAISANOW_API_URL } from '../const';

export const getAllQuestionnaire = (QuestionnaireData) => {
	const data = {
		QuestionnaireListToken: QuestionnaireData,
	};
	const url = `${PAISANOW_API_URL}GetAllQuestionnaireList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createQuestionnaire = (QuestionnaireData) => {
	const data = {
		QuestionnaireCreateToken: QuestionnaireData,
	};
	const url = `${PAISANOW_API_URL}AddQuestionnaireDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateQuestionnaire = (QuestionnaireData) => {
	const data = {
		QuestionnaireUpdateToken: QuestionnaireData,
	};
	const url = `${PAISANOW_API_URL}UpdateQuestionnaireDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteQuestionnaire = (QuestionnaireData) => {
	const data = {
		QuestionnaireDeleteToken: QuestionnaireData,
	};
	const url = `${PAISANOW_API_URL}DeleteQuestionnaireDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchQuestionnaire = (QuestionnaireData) => {
	const data = {
		QuestionnaireFetchToken: QuestionnaireData,
	};
	const url = `${PAISANOW_API_URL}FetchQuestionnaireDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

