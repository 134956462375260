const userTypeActionTypes = {
	USERTYPE_CREATE_REPSONSE_START: 'USERTYPE_CREATE_REPSONSE_START',
	USERTYPE_CREATE_REPSONSE_SUCCESS: 'USERTYPE_CREATE_REPSONSE_SUCCESS',
	USERTYPE_CREATE_REPSONSE_FAILURE: 'USERTYPE_CREATE_REPSONSE_FAILURE',
	USERTYPE_CREATE_REPSONSE_CHANGED: 'USERTYPE_CREATE_REPSONSE_CHANGED',
	USERTYPE_CREATE_REPSONSE_RESET: 'USERTYPE_CREATE_REPSONSE_RESET',

	USERTYPE_UPDATE_REPSONSE_START: 'USERTYPE_UPDATE_REPSONSE_START',
	USERTYPE_UPDATE_REPSONSE_SUCCESS: 'USERTYPE_UPDATE_REPSONSE_SUCCESS',
	USERTYPE_UPDATE_REPSONSE_FAILURE: 'USERTYPE_UPDATE_REPSONSE_FAILURE',
	USERTYPE_UPDATE_REPSONSE_CHANGED: 'USERTYPE_UPDATE_REPSONSE_CHANGED',
	USERTYPE_UPDATE_REPSONSE_RESET: 'USERTYPE_UPDATE_REPSONSE_RESET',

	USERTYPE_DELETE_REPSONSE_START: 'USERTYPE_DELETE_REPSONSE_START',
	USERTYPE_DELETE_REPSONSE_SUCCESS: 'USERTYPE_DELETE_REPSONSE_SUCCESS',
	USERTYPE_DELETE_REPSONSE_FAILURE: 'USERTYPE_DELETE_REPSONSE_FAILURE',
	USERTYPE_DELETE_REPSONSE_CHANGED: 'USERTYPE_DELETE_REPSONSE_CHANGED',
	USERTYPE_DELETE_REPSONSE_RESET: 'USERTYPE_DELETE_REPSONSE_RESET',

	USERTYPE_GET_ALL_LIST: 'USERTYPE_GET_ALL_LIST',

	USERTYPE_LIST_START: 'USERTYPE_LIST_START',
	USERTYPE_LIST_SUCCESS: 'USERTYPE_LIST_SUCCESS',
	USERTYPE_LIST_FAILURE: 'USERTYPE_LIST_FAILURE',
	USERTYPE_LIST_CHANGED: 'USERTYPE_LIST_CHANGED',
	USERTYPE_LIST_RESET: 'USERTYPE_LIST_RESET',

	USERTYPE_GET_FILTER_SEARCH_LIST: 'USERTYPE_GET_FILTER_SEARCH_LIST',
};
export default userTypeActionTypes;
