/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import companyActionTypes from './company.types';

export const createCompanySuccess = (Company) => ({
	type: companyActionTypes.COMPANY_CREATE_REPSONSE_SUCCESS,
	payload: Company,
});

export const createCompanyFailure = (error) => ({
	type: companyActionTypes.COMPANY_CREATE_REPSONSE_FAILURE,
	payload: error,
});

export const createCompanyStart = (CompanyDetails) => ({
	type: companyActionTypes.COMPANY_CREATE_REPSONSE_START,
	payload: CompanyDetails,
});

export const companyCreateChanged = () => ({
	type: companyActionTypes.COMPANY_CREATE_REPSONSE_CHANGED,
});

export const companyCreateReset = () => ({
	type: companyActionTypes.COMPANY_CREATE_REPSONSE_RESET,
});

export const updateCompanySuccess = (Company) => ({
	type: companyActionTypes.COMPANY_UPDATE_REPSONSE_SUCCESS,
	payload: Company,
});

export const updateCompanyFailure = (error) => ({
	type: companyActionTypes.COMPANY_UPDATE_REPSONSE_FAILURE,
	payload: error,
});

export const updateCompanyStart = (CompanyDetails) => ({
	type: companyActionTypes.COMPANY_UPDATE_REPSONSE_START,
	payload: CompanyDetails,
});

export const companyUpdateChanged = () => ({
	type: companyActionTypes.COMPANY_UPDATE_REPSONSE_CHANGED,
});

export const companyUpdateReset = () => ({
	type: companyActionTypes.COMPANY_UPDATE_REPSONSE_RESET,
});

export const deleteCompanySuccess = (Company) => ({
	type: companyActionTypes.COMPANY_DELETE_REPSONSE_SUCCESS,
	payload: Company,
});

export const deleteCompanyFailure = (error) => ({
	type: companyActionTypes.COMPANY_DELETE_REPSONSE_FAILURE,
	payload: error,
});

export const createDeleteStart = (CompanyDetails) => ({
	type: companyActionTypes.COMPANY_DELETE_REPSONSE_START,
	payload: CompanyDetails,
});

export const companyDeleteChanged = () => ({
	type: companyActionTypes.COMPANY_DELETE_REPSONSE_CHANGED,
});

export const companyDeleteReset = () => ({
	type: companyActionTypes.COMPANY_DELETE_REPSONSE_RESET,
});

export const getAllList = (Company) => ({
	type: companyActionTypes.COMPANY_LIST_START,
	payload: Company,
});

export const getAllListSuccess = (Company) => ({
	type: companyActionTypes.COMPANY_LIST_SUCCESS,
	payload: Company,
});

export const getAllListFailure = (error) => ({
	type: companyActionTypes.COMPANY_LIST_FAILURE,
	payload: error,
});

// COMPANY LMS CALL SECTION
export const lmsCallStart = (data) => ({
	type: companyActionTypes.COMPANY_LMS_CALL_START,
	payload: data,
});

export const lmsCallSuccess = (data) => ({
	type: companyActionTypes.COMPANY_LMS_CALL_SUCCESS,
	payload: data,
});

export const lmsCallFailure = (error) => ({
	type: companyActionTypes.COMPANY_LMS_CALL_FAILURE,
	payload: error,
});

export const lmsCallChanged = () => ({
	type: companyActionTypes.COMPANY_LMS_CALL_CHANGED,
});

export const lmsCallReset = () => ({
	type: companyActionTypes.COMPANY_LMS_CALL_RESET,
});

// COMPANY LIST SELECT 
export const companyListSelectStart = (data) => ({
	type: companyActionTypes.COMPANY_LIST_SELECT_START,
	payload: data,
});

export const companyListSelectSuccess = (data) => ({
	type: companyActionTypes.COMPANY_LIST_SELECT_SUCCESS,
	payload: data,
});

export const companyListSelectFailure = (error) => ({
	type: companyActionTypes.COMPANY_LIST_SELECT_FAILURE,
	payload: error,
});

export const companyListSelectChanged = () => ({
	type: companyActionTypes.COMPANY_LIST_SELECT_CHANGED,
});

export const companyListSelectReset = () => ({
	type: companyActionTypes.COMPANY_LIST_SELECT_RESET,
});

// COMPANY COND SECTION 
export const companyCondListStart = (data) => ({
	type: companyActionTypes.COMPANY_COND_LIST_START,
	payload: data,
});

export const companyCondListSuccess = (data) => ({
	type: companyActionTypes.COMPANY_COND_LIST_SUCCESS,
	payload: data,
});

export const companyCondListFailure = (error) => ({
	type: companyActionTypes.COMPANY_COND_LIST_FAILURE,
	payload: error,
});

export const companyCondListChanged = () => ({
	type: companyActionTypes.COMPANY_COND_LIST_CHANGED,
});

export const companyCondListReset = () => ({
	type: companyActionTypes.COMPANY_COND_LIST_RESET,
});

// COMPANY COND SECTION CREATE
export const companyCondCreateStart = (data) => ({
	type: companyActionTypes.COMPANY_COND_CREATE_START,
	payload: data,
});

export const companyCondCreateSuccess = (data) => ({
	type: companyActionTypes.COMPANY_COND_CREATE_SUCCESS,
	payload: data,
});

export const companyCondCreateFailure = (error) => ({
	type: companyActionTypes.COMPANY_COND_CREATE_FAILURE,
	payload: error,
});

export const companyCondCreateChanged = () => ({
	type: companyActionTypes.COMPANY_COND_CREATE_CHANGED,
});

export const companyCondCreateReset = () => ({
	type: companyActionTypes.COMPANY_COND_CREATE_RESET,
});


// COMPANY COND SECTION UPDATE
export const companyCondUpdateStart = (data) => ({
	type: companyActionTypes.COMPANY_COND_UPDATE_START,
	payload: data,
});

export const companyCondUpdateSuccess = (data) => ({
	type: companyActionTypes.COMPANY_COND_UPDATE_SUCCESS,
	payload: data,
});

export const companyCondUpdateFailure = (error) => ({
	type: companyActionTypes.COMPANY_COND_UPDATE_FAILURE,
	payload: error,
});

export const companyCondUpdateChanged = () => ({
	type: companyActionTypes.COMPANY_COND_UPDATE_CHANGED,
});

export const companyCondUpdateReset = () => ({
	type: companyActionTypes.COMPANY_COND_UPDATE_RESET,
});
// COMPANY LOAN SCHEDULE SECTION
export const companyLoanScheduleStart = (data) => ({
	type: companyActionTypes.COMPANY_LOAN_SCHEDULE_START,
	payload: data,
});

export const companyLoanScheduleSuccess = (data) => ({
	type: companyActionTypes.COMPANY_LOAN_SCHEDULE_SUCCESS,
	payload: data,
});

export const companyLoanScheduleFailure = (error) => ({
	type: companyActionTypes.COMPANY_LOAN_SCHEDULE_FAILURE,
	payload: error,
});

export const companyLoanScheduleChanged = () => ({
	type: companyActionTypes.COMPANY_LOAN_SCHEDULE_CHANGED,
});

export const companyLoanScheduleReset = () => ({
	type: companyActionTypes.COMPANY_LOAN_SCHEDULE_RESET,
});