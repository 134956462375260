/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
// import JWT from 'expo-jwt';
import compareActionTypes from './compare.types';
import generalTypesAction from '../genera.types';
import {
	getAllCompare,
	getCompareDetails,
	addMemberRemarks,
	getConfirmationDetails,
	rejectMember,
	getSQCompareDetails,
} from './compare.services';
import {
	getAllListSuccess,
	getAllListFailure,
	getAllListChanged,
	getCompareMemberDetailsSuccess,
	getCompareMemberDetailsFailure,
	getCompareMemberDetailsChanged,
	compareRemarksSuccess,
	compareRemarksFailure,
	compareRemarksChanged,
	compareConfirmationListSuccess,
	compareConfirmationListFailure,
	compareConfirmationListChanged,
	compareRejectMemberSuccess,
	compareRejectMemberFailure,
	compareRejectMemberChanged,
	getCompareSQMemberDetailsSuccess,
	getCompareSQMemberDetailsFailure,
	getCompareSQMemberDetailsChanged,
} from './compare.actions';
import { encodeJson } from '../enode-decode';

export function* createListOfCompare({ payload }) {
	try {
		const jsonData = {
			Limit: payload.limit,
			isCustomer: 0,
			CompanyID: payload.companyID,
			CreateStatus: payload.createStatus,
			Status: payload.status,
		};
		const CompareData = encodeJson(jsonData, generalTypesAction.MEMBER);
		const responseData = yield call(getAllCompare, CompareData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllListFailure(responseData.data));
		} else {
			yield put(getAllListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getAllListFailure(error));
	}
}

export function* createListOfCompareReset() {
	yield put(getAllListChanged());
}

export function* compareDetailStart({ payload: { memberID, companyID } }) {
	try {
		const jsonData = {
			MemberID: memberID,
			CompanyID: companyID,
		};
		const CompareData = encodeJson(jsonData, generalTypesAction.MEMBER);
		const responseData = yield call(getCompareDetails, CompareData);
		if (responseData.data.statusCode === '100') {
			yield put(getCompareMemberDetailsFailure(responseData.data));
		} else {
			yield put(getCompareMemberDetailsSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getCompareMemberDetailsFailure(error));
	}
}

export function* compareDetailReset() {
	yield put(getCompareMemberDetailsChanged());
}

export function* createRemarksLog({ payload: { compareRemarks } }) {
	try {
		const jsonData = {
			RemarksArr: JSON.stringify(compareRemarks.logs).replace(/"/g, "'"),
			MemberID: compareRemarks.memberID,
			UserID: compareRemarks.userID,
			CompanyID: compareRemarks.companyID,
		};
		const CompareData = encodeJson(jsonData, generalTypesAction.REMARKS);
		const responseData = yield call(addMemberRemarks, CompareData);
		if (responseData.data.statusCode === '100') {
			yield put(compareRemarksFailure(responseData.data));
		} else {
			yield put(compareRemarksSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(compareRemarksFailure(error));
	}
}

export function* createRemarksLogReset() {
	yield put(compareRemarksChanged());
}

export function* createFilterListOfCompare({ payload: { searchVal, companyID } }) {
	try {
		const jsonData = {
			SearchVal: searchVal,
			CompanyID: companyID,
		};
		const CompareData = encodeJson(jsonData, generalTypesAction.MEMBER);
		const responseData = yield call(getAllCompare, CompareData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllListFailure(responseData.data));
		} else {
			yield put(getAllListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getAllListFailure(error));
	}
}

export function* createConfirmationList({ payload }) {
	try {
		const jsonData = {
			GroupID: payload.groupID,
			CompanyID: payload.companyID,
		};
		const CompareConfirmationData = encodeJson(jsonData, generalTypesAction.MEMBER);
		const responseData = yield call(getConfirmationDetails, CompareConfirmationData);
		if (responseData.data.statusCode === '100') {
			yield put(compareConfirmationListFailure(responseData.data));
		} else {
			yield put(compareConfirmationListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(compareConfirmationListFailure(error));
	}
}

export function* createConfirmationListReset() {
	yield put(compareConfirmationListChanged());
}

export function* startRejectMember({ payload: { rejectMemberData } }) {
	try {
		const jsonData = {
			MemberID: rejectMemberData.memberID,
			GroupID: rejectMemberData.groupID,
			RejectNotes: rejectMemberData.rejectNotes,
			UserID: rejectMemberData.userID,
			CompanyID: rejectMemberData.companyID,
		};
		const RejectData = encodeJson(jsonData, generalTypesAction.MEMBER);
		const responseData = yield call(rejectMember, RejectData);
		if (responseData.data.statusCode === '100') {
			yield put(compareRejectMemberFailure(responseData.data));
		} else {
			yield put(compareRejectMemberSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(compareRejectMemberFailure(error));
	}
}

export function* startRejectMemberReset() {
	yield put(compareRejectMemberChanged());
}

export function* getAllCompareList() {
	yield takeLatest(compareActionTypes.COMPARE_LIST_START, createListOfCompare);
}

export function* getAllCompareListReset() {
	yield takeLatest(compareActionTypes.COMPARE_LIST_RESET, createListOfCompareReset);
}

export function* getCompareDetail() {
	yield takeLatest(compareActionTypes.COMPARE_MEMBER_DETAILS_START, compareDetailStart);
}

export function* getCompareDetailReset() {
	yield takeLatest(compareActionTypes.COMPARE_MEMBER_DETAILS_RESET, compareDetailReset);
}

export function* compareRemarksLogStart() {
	yield takeLatest(compareActionTypes.COMPARE_REMARKS_LOG_START, createRemarksLog);
}

export function* compareRemarksLogReset() {
	yield takeLatest(compareActionTypes.COMPARE_REMARKS_LOG_RESET, createRemarksLogReset);
}

export function* getFilterCompareList() {
	yield takeLatest(compareActionTypes.COMPARE_FILTER_SEARCH_START, createFilterListOfCompare);
}

export function* compareConfirmationListStart() {
	yield takeLatest(
		compareActionTypes.COMPARE_CONFIRMATINO_RESPONSE_START,
		createConfirmationList,
	);
}

export function* compareConfirmationListReset() {
	yield takeLatest(
		compareActionTypes.COMPARE_CONFIRMATINO_RESPONSE_RESET,
		createConfirmationListReset,
	);
}

export function* compareRejectMemberStart() {
	yield takeLatest(compareActionTypes.COMPARE_REJECT_MEMBER_START, startRejectMember);
}

export function* compareRejectMemberReset() {
	yield takeLatest(compareActionTypes.COMPARE_REJECT_MEMBER_RESET, startRejectMemberReset);
}

// SQ SECTION

export function* compareSQDetailStart({ payload }) {
	try {
		const jsonData = {
			MemberID: payload.memberID,
			CompanyID: payload.companyID,
			LinkCompanyID: payload.linkCompanyID,
			UserID: payload.userID,
			DatabaseName: payload.databasename,
		};
		const CompareData = encodeJson(jsonData, generalTypesAction.MEMBER);
		const responseData = yield call(getSQCompareDetails, CompareData);
		if (responseData.data.statusCode === '100') {
			yield put(getCompareSQMemberDetailsFailure(responseData.data));
		} else {
			yield put(getCompareSQMemberDetailsSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getCompareSQMemberDetailsFailure(error));
	}
}

export function* compareSQDetailReset() {
	yield put(getCompareSQMemberDetailsChanged());
}
export function* getSQCompareDetail() {
	yield takeLatest(compareActionTypes.COMPARE_SQ_MEMBER_DETAILS_START, compareSQDetailStart);
}

export function* getSQCompareDetailReset() {
	yield takeLatest(compareActionTypes.COMPARE_SQ_MEMBER_DETAILS_RESET, compareSQDetailReset);
}

export function* compareSaga() {
	yield all([
		call(getAllCompareList),
		call(getAllCompareListReset),
		call(getCompareDetail),
		call(getCompareDetailReset),
		call(compareRemarksLogStart),
		call(compareRemarksLogReset),
		call(getFilterCompareList),
		call(compareConfirmationListStart),
		call(compareConfirmationListReset),
		call(compareRejectMemberStart),
		call(compareRejectMemberReset),
		// SQ SECTION
		call(getSQCompareDetail),
		call(getSQCompareDetailReset),
	]);
}
