/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import JWT from 'expo-jwt';
import memberActionTypes from './member.types';
import {
	getBorrowersList,
	getMemberContacts,
	getMemberBank,
	getBorrowersDetails,
	getMemberLoan,
	memberReject,
} from './member.services';
import generalTypesAction from '../genera.types';
import {
	allMemberBorrowerSuccess,
	allMemberBorrowerFailure,
	allMemberBorrowerChanged,
	memberBorrowerSuccess,
	memberBorrowerFailure,
	memberBorrowerChanged,
	memberContactFetchSuccess,
	memberContactFetchFailure,
	memberContactFetchChanged,
	memberBankFetchSuccess,
	memberBankFetchFailure,
	memberBankFetchChanged,
	memberBorrowerLoanDetailsSuccess,
	memberBorrowerLoanDetailsFailure,
	memberBorrowerLoanDetailsChanged,
	memberRejectSuccess,
	memberRejectFailure,
	memberRejectChanged,
} from './member.actions';
import { encodeJson } from '../enode-decode';

export function* memberBorrowerlist({ payload }) {
	try {
		const jsonData = {
			Limit: payload.limit,
			CompanyID: payload.companyID,
			Status: payload.status,
		};
		const memberData = encodeJson(jsonData, generalTypesAction.MEMBER);
		const responseData = yield call(getBorrowersList, memberData);
		if (responseData.data.statusCode === '100') {
			yield put(allMemberBorrowerFailure(responseData.data));
		} else {
			yield put(allMemberBorrowerSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(allMemberBorrowerFailure(error));
	}
}

export function* memberBorrowerlistReset() {
	yield put(allMemberBorrowerChanged());
}

export function* memberBorrowerDetails({ payload: { memberdata } }) {
	try {
		const jsonData = {
			MemberID: memberdata.memberID,
			CompanyID: memberdata.companyID,
		};
		const memberData = encodeJson(jsonData, generalTypesAction.MEMBER);
		console.log('memberData', memberData);
		const responseData = yield call(getBorrowersDetails, memberData);
		if (responseData.data.statusCode === '100') {
			yield put(memberBorrowerFailure(responseData.data));
		} else {
			const DecodeStaffData = JWT.decode(
				responseData.data.response,
				generalTypesAction.MEMBER,
			);
			yield put(memberBorrowerSuccess(DecodeStaffData.data));
		}
	} catch (error) {
		yield put(memberBorrowerFailure(error));
	}
}

export function* memberBorrowerDetailsReset() {
	yield put(memberBorrowerChanged());
}

export function* memberContactList({ payload: { contactID, id } }) {
	try {
		const jsonData = {
			ContactID: contactID,
			MemberID: id,
		};
		const memberData = encodeJson(jsonData, generalTypesAction.CONTACTFETCH);
		const responseData = yield call(getMemberContacts, memberData);
		if (responseData.data.statusCode === '100') {
			yield put(memberContactFetchFailure(responseData.data));
		} else {
			const DecodeContactData = JWT.decode(
				responseData.data.response,
				generalTypesAction.CONTACTFETCH,
			);
			yield put(memberContactFetchSuccess(DecodeContactData.data));
		}
	} catch (error) {
		yield put(memberContactFetchFailure(error));
	}
}

export function* memberContactListReset() {
	yield put(memberContactFetchChanged());
}

export function* memberBankList({ payload: { id } }) {
	try {
		const jsonData = {
			MemberID: id,
		};
		const memberData = encodeJson(jsonData, generalTypesAction.BANK);
		const responseData = yield call(getMemberBank, memberData);
		if (responseData.data.statusCode === '100') {
			yield put(memberBankFetchFailure(responseData.data));
		} else {
			const DecodeContactData = JWT.decode(
				responseData.data.response,
				generalTypesAction.BANK,
			);
			yield put(memberBankFetchSuccess(DecodeContactData.data));
		}
	} catch (error) {
		yield put(memberBankFetchFailure(error));
	}
}

export function* memberBankListReset() {
	yield put(memberBankFetchChanged());
}

export function* memberLoanList({ payload: { id, companyID } }) {
	try {
		const jsonData = {
			MemberID: id,
			CompanyID: companyID,
		};
		const memberData = encodeJson(jsonData, generalTypesAction.LOANINDEX);
		console.log('loanFetch', memberData);
		const responseData = yield call(getMemberLoan, memberData);
		if (responseData.data.statusCode === '100') {
			yield put(memberBorrowerLoanDetailsFailure(responseData.data));
		} else {
			const DecodeContactData = JWT.decode(
				responseData.data.response,
				generalTypesAction.LOANINDEX,
			);
			yield put(memberBorrowerLoanDetailsSuccess(DecodeContactData.data));
		}
	} catch (error) {
		yield put(memberBorrowerLoanDetailsFailure(error));
	}
}

export function* memberLoanListReset() {
	yield put(memberBorrowerLoanDetailsChanged());
}

export function* memberBorrowerFilterlist({ payload: { searchVal, companyID } }) {
	try {
		const jsonData = {
			SearchVal: searchVal,
			CompanyID: companyID,
		};
		const memberData = encodeJson(jsonData, generalTypesAction.MEMBER);
		const responseData = yield call(getBorrowersList, memberData);
		if (responseData.data.statusCode === '100') {
			yield put(allMemberBorrowerFailure(responseData.data));
		} else {
			yield put(allMemberBorrowerSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(allMemberBorrowerFailure(error));
	}
}

export function* getBorrowerlist() {
	yield takeLatest(memberActionTypes.MEMBER_GET_ALL_LIST_START, memberBorrowerlist);
}

export function* getBorrowerlistReset() {
	yield takeLatest(memberActionTypes.MEMBER_GET_ALL_RESET, memberBorrowerlistReset);
}

export function* getBorrowerDetails() {
	yield takeLatest(memberActionTypes.MEMBER_GET_DETAILS_START, memberBorrowerDetails);
}

export function* getBorrowerDetailsReset() {
	yield takeLatest(memberActionTypes.MEMBER_GET_DETAILS_RESET, memberBorrowerDetailsReset);
}

export function* getContactList() {
	yield takeLatest(memberActionTypes.MEMBER_CONTACT_FETCH_START, memberContactList);
}

export function* getContactListReset() {
	yield takeLatest(memberActionTypes.MEMBER_CONTACT_FETCH_RESET, memberContactListReset);
}

export function* getBankList() {
	yield takeLatest(memberActionTypes.MEMBER_BANK_FETCH_START, memberBankList);
}

export function* getBankListReset() {
	yield takeLatest(memberActionTypes.MEMBER_BANK_FETCH_RESET, memberBankListReset);
}

export function* getLoanList() {
	yield takeLatest(memberActionTypes.MEMBER_GET_LOAN_DETAILS_START, memberLoanList);
}

export function* getLoanListReset() {
	yield takeLatest(memberActionTypes.MEMBER_GET_LOAN_DETAILS_RESET, memberLoanListReset);
}

export function* getBorrowerFilterlist() {
	yield takeLatest(memberActionTypes.MEMBER_GET_FILTER_SEARCH_START, memberBorrowerFilterlist);
}

// REJECT SECTION

export function* memberRejectCall({ payload }) {
	try {
		const jsonData = {
			MemberID: payload.memberID,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			type: 'MEMBER',
			notes: payload.logNotes,
		};
		const memberData = encodeJson(jsonData, generalTypesAction.MEMBER);
		const responseData = yield call(memberReject, memberData);
		if (responseData.data.statusCode === '100') {
			yield put(memberRejectFailure(responseData.data));
		} else {
			yield put(memberRejectSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(memberRejectFailure(error));
	}
}

export function* memberRejectCallReset() {
	yield put(memberRejectChanged());
}

export function* getMemberReject() {
	yield takeLatest(memberActionTypes.MEMBER_REJECT_START, memberRejectCall);
}

export function* getMemberRejectReset() {
	yield takeLatest(memberActionTypes.MEMBER_REJECT_RESET, memberRejectCallReset);
}

export function* memberSaga() {
	yield all([
		call(getBorrowerlist),
		call(getBorrowerlistReset),
		call(getBorrowerDetails),
		call(getBorrowerDetailsReset),
		call(getContactList),
		call(getContactListReset),
		call(getBankList),
		call(getBankListReset),
		call(getLoanList),
		call(getLoanListReset),
		call(getBorrowerFilterlist),
		call(getMemberReject),
		call(getMemberRejectReset),
	]);
}
