/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import userTypeActionTypes from './userType.types';
import generalTypesAction from '../genera.types';
import { getEndodeJsonData } from '../common/common.utils';

const INITIAL_STATE = {
	userTypeCreateStatus: null,
	userTypeCreateResponse: null,
	userTypeUpdateStatus: null,
	userTypeUpdateResponse: null,
	userTypeDeleteStatus: null,
	userTypeDeleteResponse: null,
	allUserTypeList: null,
	allUserTypeListStatus: null,
};

// eslint-disable-next-line default-param-last
const userTypeReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case userTypeActionTypes.USERTYPE_CREATE_REPSONSE_SUCCESS:
			return {
				...state,
				userTypeCreateResponse: action.payload,
				userTypeCreateStatus: 'Success',
			};
		case userTypeActionTypes.USERTYPE_CREATE_REPSONSE_FAILURE:
			return {
				...state,
				userTypeCreateResponse: action.payload,
				userTypeCreateStatus: 'Failure',
			};
		case userTypeActionTypes.USERTYPE_CREATE_REPSONSE_CHANGED:
			return { ...state, userTypeCreateResponse: null, userTypeCreateStatus: null };

		case userTypeActionTypes.USERTYPE_UPDATE_REPSONSE_SUCCESS:
			return {
				...state,
				userTypeUpdateResponse: action.payload,
				userTypeUpdateStatus: 'Success',
			};
		case userTypeActionTypes.USERTYPE_UPDATE_REPSONSE_FAILURE:
			return {
				...state,
				userTypeUpdateResponse: action.payload,
				userTypeUpdateStatus: 'Failure',
			};
		case userTypeActionTypes.USERTYPE_UPDATE_REPSONSE_CHANGED:
			return { ...state, userTypeUpdateResponse: null, userTypeUpdateStatus: null };

		case userTypeActionTypes.USERTYPE_DELETE_REPSONSE_SUCCESS:
			return {
				...state,
				userTypeDeleteResponse: action.payload,
				userTypeDeleteStatus: 'Success',
			};
		case userTypeActionTypes.USERTYPE_DELETE_REPSONSE_FAILURE:
			return {
				...state,
				userTypeDeleteResponse: action.payload,
				userTypeDeleteStatus: 'Failure',
			};
		case userTypeActionTypes.USERTYPE_DELETE_REPSONSE_CHANGED:
			return { ...state, userTypeDeleteResponse: null, userTypeDeleteStatus: null };

		case userTypeActionTypes.USERTYPE_LIST_SUCCESS:
			return {
				...state,
				allUserTypeList: getEndodeJsonData(action.payload, generalTypesAction.USERTYPE),
				allUserTypeListStatus: 'Success',
			};
		case userTypeActionTypes.USERTYPE_LIST_FAILURE:
			return { ...state, allUserTypeList: null, allUserTypeListStatus: 'Failure' };
		case userTypeActionTypes.USERTYPE_LIST_CHANGED:
			return { ...state, allUserTypeListStatus: null };
		default:
			return state;
	}
};

export default userTypeReducer;
