/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { PAISANOW_API_URL } from '../const';

export const createProduct = (ProductData) => {
	const data = {
		ProductCreateToken: ProductData,
	};
	const url = `${PAISANOW_API_URL}AddProductDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateProduct = (ProductData) => {
	const data = {
		ProductUpdateToken: ProductData,
	};
	const url = `${PAISANOW_API_URL}UpdateProductDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getAllProduct = (ProductData) => {
	const data = {
		ProductListToken: ProductData,
	};
	const url = `${PAISANOW_API_URL}GetAllProductDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchProduct = (ProductData) => {
	const data = {
		ProductFetchToken: ProductData,
	};
	const url = `${PAISANOW_API_URL}FetchProductDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
