/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { PAISANOW_API_URL } from '../const';

export const getAllCompare = (CompareData) => {
	const data = {
		CompareMemberListToken: CompareData,
	};
	const url = `${PAISANOW_API_URL}CompareMemberFetch`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getCompareDetails = (CompareData) => {
	const data = {
		CompareMemberDetailsToken: CompareData,
	};
	const url = `${PAISANOW_API_URL}CompareMemberFetchDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const addMemberRemarks = (RemarksData) => {
	const data = {
		RemarksToken: RemarksData,
	};
	const url = `${PAISANOW_API_URL}AddRemarksDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getConfirmationDetails = (ConfirmationData) => {
	const data = {
		CompareConfirmationListToken: ConfirmationData,
	};
	const url = `${PAISANOW_API_URL}CompareConfirmationList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const rejectMember = (RejectData) => {
	const data = {
		MemberRejectToken: RejectData,
	};
	const url = `${PAISANOW_API_URL}RejectMemberDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// SQ SECTION 
export const getSQCompareDetails = (CompareData) => {
	const data = {
		CompareMemberDetailsToken: CompareData,
	};
	const url = `${PAISANOW_API_URL}CompareSQMemberFetchDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// DISBURSEMENT APPROVE SECTION
export const disbApprove = (disbData) => {
	const data = {
		LoanDisbusementToken: disbData,
	};
	const url = `${PAISANOW_API_URL}DisbursementApproval`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
// REPORT SECTION
export const disbReport = (disbData) => {
	const data = {
		LoanDisbusementReportToken: disbData,
	};
	const url = `${PAISANOW_API_URL}GetDisbursementReport`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};