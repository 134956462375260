const disbursementActionTypes = {
	DISBURSEMENT_LIST_START: 'disbursement_list_start',
	DISBURSEMENT_LIST_SUCCESS: 'disbursement_list_success',
	DISBURSEMENT_LIST_FAILURE: 'disbursement_list_failure',
	DISBURSEMENT_LIST_CHANGED: 'disbursement_list_changed',
	DISBURSEMENT_LIST_RESET: 'disbursement_list_reset',

	DISBURSEMENT_MEMBER_DETAILS_START: 'disbursement_member_details_start',
	DISBURSEMENT_MEMBER_DETAILS_SUCCESS: 'disbursement_member_details_success',
	DISBURSEMENT_MEMBER_DETAILS_FAILURE: 'disbursement_member_details_failure',
	DISBURSEMENT_MEMBER_DETAILS_CHANGED: 'disbursement_member_details_changed',
	DISBURSEMENT_MEMBER_DETAILS_RESET: 'disbursement_member_details_reset',

	DISBURSEMENT_SQ_MEMBER_DETAILS_START: 'disbursement_sq_member_details_start',
	DISBURSEMENT_SQ_MEMBER_DETAILS_SUCCESS: 'disbursement_sq_member_details_success',
	DISBURSEMENT_SQ_MEMBER_DETAILS_FAILURE: 'disbursement_sq_member_details_failure',
	DISBURSEMENT_SQ_MEMBER_DETAILS_CHANGED: 'disbursement_sq_member_details_changed',
	DISBURSEMENT_SQ_MEMBER_DETAILS_RESET: 'disbursement_sq_member_details_reset',

	SQDISBURSEMENT_MEMBER_DETAILS_START: 'sqdisbursement_member_details_start',
	SQDISBURSEMENT_MEMBER_DETAILS_SUCCESS: 'sqdisbursement_member_details_success',
	SQDISBURSEMENT_MEMBER_DETAILS_FAILURE: 'sqdisbursement_member_details_failure',
	SQDISBURSEMENT_MEMBER_DETAILS_CHANGED: 'sqdisbursement_member_details_changed',
	SQDISBURSEMENT_MEMBER_DETAILS_RESET: 'sqdisbursement_member_details_reset',

	DISBURSEMENT_LIST_ID: 'disbursement_list_id',
	DISBURSEMENT_LIST_NAME: 'disbursement_list_name',

	DISBURSEMENT_REMARKS_LOG_START: 'disbursement_remarks_log_start',
	DISBURSEMENT_REMARKS_LOG_SUCCESS: 'disbursement_remarks_log_success',
	DISBURSEMENT_REMARKS_LOG_FAILURE: 'disbursement_remarks_log_failure',
	DISBURSEMENT_REMARKS_LOG_CHANGED: 'disbursement_remarks_log_changed',
	DISBURSEMENT_REMARKS_LOG_RESET: 'disbursement_remarks_log_reset',

	DISBURSEMENT_FETCH_REMARKS_RESPONSE_START: 'disbursement_confirmation_response_start',
	DISBURSEMENT_FETCH_REMARKS_RESPONSE_SUCCESS: 'disbursement_confirmation_response_success',
	DISBURSEMENT_FETCH_REMARKS_RESPONSE_FAILURE: 'disbursement_confirmation_response_failure',
	DISBURSEMENT_FETCH_REMARKS_RESPONSE_CHANGED: 'disbursement_confirmation_response_changed',
	DISBURSEMENT_FETCH_REMARKS_RESPONSE_RESET: 'disbursement_confirmation_response_reset',

	DISBURSEMENT_REMARKS_VALUES: 'disbursement_remarks_values',

	DISBURSEMENT_FILTER_SEARCH_START: 'disbursement_filter_search_start',

	DISBURSEMENT_LEADER_DATA_STORE: 'disbursement_leader_data_store',
	DISBURSEMENT_LEADER_DATA_STORE_RESET: 'disbursement_leader_data_store_reset',

	DISBURSEMENT_CONFIRMATINO_RESPONSE_START: 'disbursement_confirmation_response_start',
	DISBURSEMENT_CONFIRMATINO_RESPONSE_SUCCESS: 'disbursement_confirmation_response_success',
	DISBURSEMENT_CONFIRMATINO_RESPONSE_FAILURE: 'disbursement_confirmation_response_failure',
	DISBURSEMENT_CONFIRMATINO_RESPONSE_CHANGED: 'disbursement_confirmation_response_changed',
	DISBURSEMENT_CONFIRMATINO_RESPONSE_RESET: 'disbursement_confirmation_response_reset',

	DISBURSEMENT_REJECT_MEMBER_START: 'disbursement_reject_member_start',
	DISBURSEMENT_REJECT_MEMBER_SUCCESS: 'disbursement_reject_member_success',
	DISBURSEMENT_REJECT_MEMBER_FAILURE: 'disbursement_reject_member_failure',
	DISBURSEMENT_REJECT_MEMBER_CHANGED: 'disbursement_reject_member_changed',
	DISBURSEMENT_REJECT_MEMBER_RESET: 'disbursement_reject_member_reset',

	DISBURSEMENT_MEMBER_START: 'disbursement_reject_member_start',
	DISBURSEMENT_MEMBER_SUCCESS: 'disbursement_member_success',
	DISBURSEMENT_MEMBER_FAILURE: 'disbursement_member_failure',
	DISBURSEMENT_MEMBER_CHANGED: 'disbursement_member_changed',
	DISBURSEMENT_MEMBER_RESET: 'disbursement_member_reset',


	// REPORT SECTION
	DISBURSEMENT_REPORT_START: 'DISBURSEMENT_REPORT_START',
	DISBURSEMENT_REPORT_SUCCESS: 'DISBURSEMENT_REPORT_SUCCESS',
	DISBURSEMENT_REPORT_FAILURE: 'DISBURSEMENT_REPORT_FAILURE',
	DISBURSEMENT_REPORT_CHANGED: 'DISBURSEMENT_REPORT_CHANGED',
	DISBURSEMENT_REPORT_RESET: 'DISBURSEMENT_REPORT_RESET',
};
export default disbursementActionTypes;
