const generalTypesAction = {
	DASHBOARD: 'DASHBOARD',
	LOGIN_KEY: 'WEBLOGIN',
	COMPANY: 'COMPANY',
	BRANCH: 'BRANCH',
	BRANCHPINCODE: 'PINCODE',
	STAFF: 'STAFF',
	MEMBER: 'Member',
	REMARKS: 'Remarks',
	CONTACTFETCH: 'ContactFetch',
	BANK: 'Bank',
	LOANINDEX: 'LoanIndex',
	NDC: 'NDC',
	CBCHECK: 'CBCHECK',
	HIGHMARK: 'HIGHMARK',
	QUESTIONNAIRE: 'QUESTIONNAIRE',
	PRODUCT: 'PRODUCT',
	DEMAND: 'DEMAND',
	DISBUSREMENT: 'Disbursement',
	REPAYMENT: 'REPAYMENT',
	PAISANOWPUSH: 'PAISANOWPUSH',
	USERTYPE: 'USERTYPE',
};

export default generalTypesAction;
