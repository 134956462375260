/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { PAISANOW_API_URL } from '../const';

export const createUserType = (UserTypeData) => {
	const data = {
		UserTypeCreateToken: UserTypeData,
	};
	const url = `${PAISANOW_API_URL}AddUserTypeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateUserType = (UserTypeData) => {
	const data = {
		UserTypeUpdateToken: UserTypeData,
	};
	const url = `${PAISANOW_API_URL}UpdateUserTypeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteUserType = (UserTypeData) => {
	const data = {
		UserTypeDeleteToken: UserTypeData,
	};
	const url = `${PAISANOW_API_URL}DeleteUserTypeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getAllUserType = (UserTypeData) => {
	const data = {
		UserTypeListToken: UserTypeData,
	};
	const url = `${PAISANOW_API_URL}GetAllUserTypeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
