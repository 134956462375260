/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import blockChainActionTypes from './blockChain.types';

const INITIAL_STATE = {
	memberBlockChainLoanApproval: null,
	memberBlockChainLoanApprovalStatus: null,
};

// eslint-disable-next-line default-param-last
const bankReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case blockChainActionTypes.MEMBER_BLOCK_CHAIN_LOAN_APPROVAL_SUCCESS:
			return {
				...state,
				memberBlockChainLoanApproval: action.payload,
				memberBlockChainLoanApprovalStatus: 'Success',
			};
		case blockChainActionTypes.MEMBER_BLOCK_CHAIN_LOAN_APPROVAL_FAILURE:
			return {
				...state,
				memberBlockChainLoanApproval: action.payload,
				memberBlockChainLoanApprovalStatus: 'Failure',
			};
		case blockChainActionTypes.MEMBER_BLOCK_CHAIN_LOAN_APPROVAL_CHANGED:
			return {
				...state,
				memberBlockChainLoanApproval: null,
				memberBlockChainLoanApprovalStatus: null,
			};

		default:
			return state;
	}
};

export default bankReducer;
