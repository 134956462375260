const loginActionTypes = {
	SET_CURRENT_USER: 'SET_CURRENT_USER',
	SIGN_IN_START: 'SIGN_IN_START',
	SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
	SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
	SIGN_IN_CHANGED: 'SIGN_IN_CHANGED',
	SIGN_IN_RESET: 'SIGN_IN_RESET',

	SIGN_OUT_CHANGED: 'SIGN_OUT_CHANGED',
	SIGN_OUT_RESET: 'SIGN_OUT_RESET',

	SIGN_IN_COMPANYID: 'SIGN_IN_COMPANYID',
	SIGN_IN_USER: 'SIGN_IN_USER',
	SIGN_IN_USERTYPE: 'SIGN_IN_USERTYPE',
};
export default loginActionTypes;
