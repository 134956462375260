const DemandReportActionTypes = {
	DEMAND_REPORT_LIST_START: 'DEMAND_REPORT_LIST_START',
	DEMAND_REPORT_LIST_SUCCESS: 'DEMAND_REPORT_LIST_SUCCESS',
	DEMAND_REPORT_LIST_FAILURE: 'DEMAND_REPORT_LIST_FAILURE',
	DEMAND_REPORT_LIST_CHANGED: 'DEMAND_REPORT_LIST_CHANGED',
	DEMAND_REPORT_LIST_RESET: 'DEMAND_REPORT_LIST_RESET',

	DEMAND_REPORT_GET_FILTER_SEARCH_LIST: 'DEMAND_REPORT_GET_FILTER_SEARCH_LIST',

	DEMAND_REPORT_FETCH_START: 'DEMAND_REPORT_FETCH_START',
	DEMAND_REPORT_FETCH_SUCCESS: 'DEMAND_REPORT_FETCH_SUCCESS',
	DEMAND_REPORT_FETCH_FAILURE: 'DEMAND_REPORT_FETCH_FAILURE',
	DEMAND_REPORT_FETCH_CHANGED: 'DEMAND_REPORT_FETCH_CHANGED',
	DEMAND_REPORT_FETCH_RESET: 'DEMAND_REPORT_FETCH_RESET',
};
export default DemandReportActionTypes;
