/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
// import JWT from 'expo-jwt';
import loginActionTypes from './login.types';
import { UserLogin } from './login.services';
import { signInSuccess, signInFailure, signInChanged, signOutChanged } from './login.actions';
import { encodeJson } from '../enode-decode';
import generalTypesAction from '../genera.types';

export function* singInWithPhoneNumber({ payload: { values } }) {
	try {
		const jsonData = {
			UserID: values.phoneNumber,
			Password: values.password,
		};
		const loginData = encodeJson(jsonData, generalTypesAction.LOGIN_KEY);
		const responseData = yield call(UserLogin, loginData);
		if (responseData.data.statusCode === '100') {
			yield put(signInFailure(responseData.data));
		} else {
			yield put(signInSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(signInFailure(error));
	}
}

export function* makeUserLoginReset() {
	yield put(signInChanged());
}

export function* makeUserLogOutReset() {
	yield put(signOutChanged());
}

export function* onLoginStart() {
	yield takeLatest(loginActionTypes.SIGN_IN_START, singInWithPhoneNumber);
}

export function* onLoginReset() {
	yield takeLatest(loginActionTypes.SIGN_IN_RESET, makeUserLoginReset);
}

export function* onLogOutReset() {
	yield takeLatest(loginActionTypes.SIGN_OUT_RESET, makeUserLogOutReset);
}

export function* loginSaga() {
	yield all([call(onLoginStart), call(onLoginReset), call(onLogOutReset)]);
}
