/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import DemandReportActionTypes from './demandReport.types';

export const getAllDemandList = (data) => ({
	type: DemandReportActionTypes.DEMAND_REPORT_LIST_START,
	payload: data,
});

export const getAllDemandListSuccess = (data) => ({
	type: DemandReportActionTypes.DEMAND_REPORT_LIST_SUCCESS,
	payload: data,
});

export const getAllDemandListFailure = (error) => ({
	type: DemandReportActionTypes.DEMAND_REPORT_LIST_FAILURE,
	payload: error,
});

export const getAllDemandListChanged = () => ({
	type: DemandReportActionTypes.DEMAND_REPORT_LIST_CHANGED,
});

export const getAllDemandListReset = () => ({
	type: DemandReportActionTypes.DEMAND_REPORT_LIST_RESET,
});

export const getFilterListStart = (data) => ({
	type: DemandReportActionTypes.DEMAND_REPORT_GET_FILTER_SEARCH_LIST,
	payload: data,
});

// FETCH SECTION
export const demandReportFetchStart = (data) => ({
	type: DemandReportActionTypes.DEMAND_REPORT_FETCH_START,
	payload: data,
});

export const demandReportFetchSuccess = (data) => ({
	type: DemandReportActionTypes.DEMAND_REPORT_FETCH_SUCCESS,
	payload: data,
});

export const demandReportFetchFailure = (error) => ({
	type: DemandReportActionTypes.DEMAND_REPORT_FETCH_FAILURE,
	payload: error,
});

export const demandReportFetchChanged = () => ({
	type: DemandReportActionTypes.DEMAND_REPORT_FETCH_CHANGED,
});

export const demandReportFetchReset = () => ({
	type: DemandReportActionTypes.DEMAND_REPORT_FETCH_RESET,
});