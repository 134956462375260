/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import JWT from 'expo-jwt';
import bankActionTypes from './bank.types';
import {
	getMemberBankAnalyse,
	getMemberUpdateBankAnalyse,
	getMemberBankInkredo,
	getMemberUpdateBankInkredoJson,
} from './bank.services';
import generalTypesAction from '../genera.types';
import {
	memberBorrowerBankAnalyseSuccess,
	memberBorrowerBankAnalyseFailure,
	memberBorrowerBankAnalyseChanged,
	memberUpdateBankAnalyseSuccess,
	memberUpdateBankAnalyseFailure,
	memberUpdateBankAnalyseChanged,
	memberBorrowerBankInkredoSuccess,
	memberBorrowerBankInkredoFailure,
	memberBorrowerBankInkredoChanged,
	memberUpdateBankInkredoJsonSuccess,
	memberUpdateBankInkredoJsonFailure,
	memberUpdateBankInkredoJsonChanged,
} from './bank.actions';
import { encodeJson } from '../enode-decode';

export function* memberBankAnalyseList() {
	try {
		const responseData = yield call(getMemberBankAnalyse);
		if (responseData.data.statusCode === '100') {
			yield put(memberBorrowerBankAnalyseFailure(responseData.data));
		} else {
			yield put(memberBorrowerBankAnalyseSuccess(responseData.data));
		}
	} catch (error) {
		yield put(memberBorrowerBankAnalyseFailure(error));
	}
}

export function* memberBankAnalyseListReset() {
	yield put(memberBorrowerBankAnalyseChanged());
}

export function* memberUpdateBankAnalyseList({ payload: { bankDetails } }) {
	try {
		const jsonData = {
			MemberID: bankDetails.memberID,
			User: bankDetails.staffID,
			MemberBankID: bankDetails.memberBankID,
			BankStatementURL: bankDetails.bankStatementURL,
			BankStatementPassword: bankDetails.bankStatementPassword,
			ApplicantPK: '1e1e06fb-e1e3-4b48-9533-6db44a1a3aff',
			BankCode: bankDetails.bankCodeID,
		};
		const memberBankData = encodeJson(jsonData, generalTypesAction.BANK);
		const responseData = yield call(getMemberUpdateBankAnalyse, memberBankData);
		if (responseData.data.statusCode === '100') {
			yield put(memberUpdateBankAnalyseFailure(responseData.data));
		} else {
			const DecodeBankData = JWT.decode(responseData.data.response, generalTypesAction.BANK);
			yield put(memberUpdateBankAnalyseSuccess(DecodeBankData));
		}
	} catch (error) {
		yield put(memberUpdateBankAnalyseFailure(error));
	}
}

export function* memberUpdateBankAnalyseListReset() {
	yield put(memberUpdateBankAnalyseChanged());
}

export function* memberBankInkredoList({ payload: { filePkData } }) {
	try {
		const responseData = yield call(getMemberBankInkredo, filePkData);
		if (responseData.status !== 200) {
			yield put(memberBorrowerBankInkredoFailure(responseData.data));
		} else {
			yield put(memberBorrowerBankInkredoSuccess(responseData.data));
		}
	} catch (error) {
		yield put(memberBorrowerBankInkredoFailure(error));
	}
}

export function* memberBankInkredoListReset() {
	yield put(memberBorrowerBankInkredoChanged());
}

export function* memberUpdateBankInkredoJsonList({ payload: { inkredoRespJson } }) {
	try {
		const jsonData = {
			MemberID: inkredoRespJson.memberID,
			MemberBankID: inkredoRespJson.memberBankID,
			Json: inkredoRespJson.json,
			Path: inkredoRespJson.path,
		};
		const memberBankJsonData = encodeJson(jsonData, generalTypesAction.BANK);
		const responseData = yield call(getMemberUpdateBankInkredoJson, memberBankJsonData);
		if (responseData.data.statusCode === '100') {
			yield put(memberUpdateBankInkredoJsonFailure(responseData.data));
		} else {
			yield put(memberUpdateBankInkredoJsonSuccess(responseData.data));
		}
	} catch (error) {
		yield put(memberUpdateBankInkredoJsonFailure(error));
	}
}

export function* memberUpdateBankInkredoJsonListReset() {
	yield put(memberUpdateBankInkredoJsonChanged());
}

export function* getBankAnalyseList() {
	yield takeLatest(bankActionTypes.MEMBER_ANALYSE_BANK_STATEMENT_START, memberBankAnalyseList);
}

export function* getBankAnalyseListReset() {
	yield takeLatest(
		bankActionTypes.MEMBER_ANALYSE_BANK_STATEMENT_RESET,
		memberBankAnalyseListReset,
	);
}

export function* getBankAnalyseUpdateList() {
	yield takeLatest(
		bankActionTypes.UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_START,
		memberUpdateBankAnalyseList,
	);
}

export function* getBankAnalyseUpdateListReset() {
	yield takeLatest(
		bankActionTypes.UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_RESET,
		memberUpdateBankAnalyseListReset,
	);
}

export function* getBankInkredoList() {
	yield takeLatest(bankActionTypes.MEMBER_INKREDO_BANK_STATEMENT_START, memberBankInkredoList);
}

export function* getBankInkredoListReset() {
	yield takeLatest(
		bankActionTypes.MEMBER_INKREDO_BANK_STATEMENT_RESET,
		memberBankInkredoListReset,
	);
}

export function* getBankInkredoUpdateJsonList() {
	yield takeLatest(
		bankActionTypes.UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_JSON_START,
		memberUpdateBankInkredoJsonList,
	);
}

export function* getBankInkredoUpdateJsonListReset() {
	yield takeLatest(
		bankActionTypes.UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_JSON_RESET,
		memberUpdateBankInkredoJsonListReset,
	);
}

export function* bankSaga() {
	yield all([
		call(getBankAnalyseList),
		call(getBankAnalyseListReset),
		call(getBankAnalyseUpdateList),
		call(getBankAnalyseUpdateListReset),
		call(getBankInkredoList),
		call(getBankInkredoListReset),
		call(getBankInkredoUpdateJsonList),
		call(getBankInkredoUpdateJsonListReset),
	]);
}
