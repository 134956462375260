/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import loginActionTypes from './login.types';
import generalTypesAction from '../genera.types';
import { getEndodeJsonData } from '../common/common.utils';

const INITIAL_STATE = {
	loginStatus: null,
	loginResponse: null,
	isLogin: 0,
	companyID: null,
	userID: null,
	userType: null,
};

// eslint-disable-next-line default-param-last
const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case loginActionTypes.SIGN_IN_SUCCESS:
			return {
				...state,
				loginResponse: getEndodeJsonData(action.payload, generalTypesAction.LOGIN_KEY),
				loginStatus: 'Success',
				isLogin: 1,
			};

		case loginActionTypes.SIGN_IN_FAILURE:
			return { ...state, loginResponse: null, loginStatus: 'Failure' };

		case loginActionTypes.SIGN_IN_CHANGED:
			return { ...state, loginStatus: null };

		case loginActionTypes.SIGN_OUT_CHANGED:
			return {
				...state,
				loginStatus: null,
				loginResponse: null,
				isLogin: 0,
				companyID: null,
				userID: null,
				userType: null,
			};

		case loginActionTypes.SIGN_IN_COMPANYID:
			return { ...state, companyID: action.payload };

		case loginActionTypes.SIGN_IN_USER:
			return { ...state, userID: action.payload };

		case loginActionTypes.SIGN_IN_USERTYPE:
			return { ...state, userType: action.payload };

		default:
			return state;
	}
};

export default userReducer;
