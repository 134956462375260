/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import departmentActionTypes from './department.types';

export const createDepartmentSuccess = (Department) => ({
	type: departmentActionTypes.DEPARTMENT_CREATE_REPSONSE_SUCCESS,
	payload: Department,
});

export const createDepartmentFailure = (error) => ({
	type: departmentActionTypes.DEPARTMENT_CREATE_REPSONSE_FAILURE,
	payload: error,
});

export const createDepartmentStart = (DepartmentDetails) => ({
	type: departmentActionTypes.DEPARTMENT_CREATE_REPSONSE_START,
	payload: DepartmentDetails,
});

export const updateDepartmentSuccess = (Department) => ({
	type: departmentActionTypes.DEPARTMENT_UPDATE_REPSONSE_SUCCESS,
	payload: Department,
});

export const updateDepartmentFailure = (error) => ({
	type: departmentActionTypes.DEPARTMENT_UPDATE_REPSONSE_FAILURE,
	payload: error,
});

export const updateDepartmentStart = (DepartmentDetails) => ({
	type: departmentActionTypes.DEPARTMENT_UPDATE_REPSONSE_START,
	payload: DepartmentDetails,
});

export const deleteDepartmentSuccess = (Department) => ({
	type: departmentActionTypes.DEPARTMENT_DELETE_REPSONSE_SUCCESS,
	payload: Department,
});

export const deleteDepartmentFailure = (error) => ({
	type: departmentActionTypes.DEPARTMENT_DELETE_REPSONSE_FAILURE,
	payload: error,
});

export const createDeleteStart = (DepartmentDetails) => ({
	type: departmentActionTypes.DEPARTMENT_DELETE_REPSONSE_START,
	payload: DepartmentDetails,
});

export const departmentChanged = () => ({
	type: departmentActionTypes.DEPARTMENT_CREATE_REPSONSE_CHANGED,
});

export const getAllList = (Department) => ({
	type: departmentActionTypes.DEPARTMENT_LIST_START,
	payload: Department,
});

export const getAllListSuccess = (Department) => ({
	type: departmentActionTypes.DEPARTMENT_LIST_SUCCESS,
	payload: Department,
});

export const getAllListFailure = (error) => ({
	type: departmentActionTypes.DEPARTMENT_LIST_FAILURE,
	payload: error,
});

export const departmentDeleteChanged = () => ({
	type: departmentActionTypes.DEPARTMENT_DELETE_REPSONSE_CHANGED,
});
