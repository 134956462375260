/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { PAISANOW_API_URL } from '../const';

export const getDeliveryOrderList = (postListValue) => {
	const data = {
		Limit: postListValue.limit,
	};
	const url = `${PAISANOW_API_URL}GetDelivaryOrderList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getUserOrderList = (userOrder) => {
	const data = {
		UserID: userOrder.UserID,
		OrderModeID: userOrder.OrderModeID,
	};
	const url = `${PAISANOW_API_URL}GetPerUserOrders`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getComboProductOrderList = (combo) => {
	const data = {
		UserID: combo.UserID,
		OrderModeID: combo.OrderModeID,
	};
	const url = `${PAISANOW_API_URL}StaffLogin`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
