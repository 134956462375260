/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { PAISANOW_API_URL } from '../const';

export const createStaff = (StaffData) => {
	debugger;
	const data = {
		StaffCreateToken: StaffData,
	};
	const url = `${PAISANOW_API_URL}AddStaffDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateStaff = (StaffData) => {
	const data = {
		StaffUpdateToken: StaffData,
	};
	const url = `${PAISANOW_API_URL}UpdateStaffDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteStaff = (StaffData) => {
	const data = {
		StaffDeleteToken: StaffData,
	};
	const url = `${PAISANOW_API_URL}DeleteStaffDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getAllStaff = (StaffData) => {
	const data = {
		StaffListToken: StaffData,
	};
	const url = `${PAISANOW_API_URL}GetAllStaffDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
