/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import questionnaireActionTypes from './questionnaire.types';
import generalTypesAction from '../genera.types';
import {
	createQuestionnaire,
	getAllQuestionnaire,
	updateQuestionnaire,
	deleteQuestionnaire,
	fetchQuestionnaire,
} from './questionnaire.services';
import {
	getAllQuestionnaireListSuccess,
	getAllQuestionnaireListFailure,
	getAllQuestionnaireListChanged,
	createQuestionnaireSuccess,
	createQuestionnaireFailure,
	createQuestionnaireChanged,
	updateQuestionnaireSuccess,
	updateQuestionnaireFailure,
	updateQuestionnaireChanged,
	deleteQuestionnaireSuccess,
	deleteQuestionnaireFailure,
	deleteQuestionnaireChanged,
	fetchQuestionnaireSuccess,
	fetchQuestionnaireFailure,
	fetchQuestionnaireChanged,
} from './questionnaire.actions';
import { encodeJson } from '../enode-decode';

export function* listQuestionnaireStart({ payload: { limit, companyID, searchVal } }) {
	try {
		const jsonData = {
			Limit: limit,
			CompanyID: companyID,
			SearchVal: searchVal,
		};
		const QuestionnaireData = encodeJson(jsonData, generalTypesAction.QUESTIONNAIRE);
		console.log('QuestionnaireData', QuestionnaireData);
		const responseData = yield call(getAllQuestionnaire, QuestionnaireData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(getAllQuestionnaireListFailure(responseData.data));
		} else {
			yield put(getAllQuestionnaireListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getAllQuestionnaireListFailure(error));
	}
}

export function* createNewQuestionnaire({ payload: { values } }) {
	try {
		const jsonData = {
			CompanyID: values.companyID,
			UserID: values.userID,
			Title: values.questionnaireTitle,
			Description: values.questionnaireDescription,
			QuestionArr: JSON.stringify(values.questionnaireQuestions).replace(/"/g, "'"),
		};
		const QuestionnaireData = encodeJson(jsonData, generalTypesAction.QUESTIONNAIRE);
		const responseData = yield call(createQuestionnaire, QuestionnaireData);
		if (responseData.data.statusCode === '100') {
			yield put(createQuestionnaireFailure(responseData.data));
		} else {
			yield put(createQuestionnaireSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(createQuestionnaireFailure(error));
	}
}

export function* updateQuestionnaireDetails({ payload: { values } }) {
	try {
		const jsonData = {
			CompanyID: values.companyID,
			UserID: values.userID,
			QuestionnaireID: values.questionnaireID,
			Title: values.questionnaireTitle,
			Description: values.questionnaireDescription,
			QuestionArr: JSON.stringify(values.questionnaireQuestions).replace(/"/g, "'"),
		};
		const QuestionnaireData = encodeJson(jsonData, generalTypesAction.QUESTIONNAIRE);
		debugger;
		const responseData = yield call(updateQuestionnaire, QuestionnaireData);
		if (responseData.data.statusCode === '100') {
			yield put(updateQuestionnaireFailure(responseData.data));
		} else {
			yield put(updateQuestionnaireSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(updateQuestionnaireFailure(error));
	}
}

export function* deleteQuestionnaireDetails({ payload: { deleteData } }) {
	try {
		const jsonData = {
			UserID: deleteData.userID,
			CompanyID: deleteData.companyID,
			QuestionnaireID: deleteData.id,
		};
		const QuestionnaireData = encodeJson(jsonData, generalTypesAction.QUESTIONNAIRE);
		const responseData = yield call(deleteQuestionnaire, QuestionnaireData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteQuestionnaireFailure(responseData.data));
		} else {
			yield put(deleteQuestionnaireSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteQuestionnaireFailure(error));
	}
}

export function* fetchQuestionnaireDetails({ payload: { fetchData } }) {
	try {
		const jsonData = {
			UserID: fetchData.userID,
			CompanyID: fetchData.companyID,
			QuestionnaireID: fetchData.id,
		};
		const QuestionnaireData = encodeJson(jsonData, generalTypesAction.QUESTIONNAIRE);
		const responseData = yield call(fetchQuestionnaire, QuestionnaireData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchQuestionnaireFailure(responseData.data));
		} else {
			yield put(fetchQuestionnaireSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(fetchQuestionnaireFailure(error));
	}
}

export function* questionnaireFilterList({ payload: { searchVal, companyID } }) {
	try {
		const jsonData = {
			SearchVal: searchVal,
			CompanyID: companyID,
		};
		const QuestionnaireData = encodeJson(jsonData, generalTypesAction.STAFF);
		const responseData = yield call(getAllQuestionnaire, QuestionnaireData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(getAllQuestionnaireListFailure(responseData.data));
		} else {
			yield put(getAllQuestionnaireListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getAllQuestionnaireListFailure(error));
	}
}

export function* createQuestionnaireResponseReset() {
	yield put(createQuestionnaireChanged());
}

export function* updateQuestionnaireResponseReset() {
	yield put(updateQuestionnaireChanged());
}

export function* deleteQuestionnaireResponseReset() {
	yield put(deleteQuestionnaireChanged());
}

export function* fetchQuestionnaireResponseReset() {
	yield put(fetchQuestionnaireChanged());
}

export function* listQuestionnaireReset() {
	yield put(getAllQuestionnaireListChanged());
}

export function* getAllQuestionnaireList() {
	yield takeLatest(questionnaireActionTypes.QUESTIONNAIRE_LIST_START, listQuestionnaireStart);
}

export function* getAllQuestionnaireListReset() {
	yield takeLatest(questionnaireActionTypes.QUESTIONNAIRE_LIST_RESET, listQuestionnaireReset);
}

export function* questionnaireCreateStart() {
	yield takeLatest(
		questionnaireActionTypes.QUESTIONNAIRE_CREATE_REPSONSE_START,
		createNewQuestionnaire,
	);
}

export function* questionnaireCreateResponseReset() {
	yield takeLatest(
		questionnaireActionTypes.QUESTIONNAIRE_CREATE_REPSONSE_RESET,
		createQuestionnaireResponseReset,
	);
}

export function* questionnaireUpdateStart() {
	yield takeLatest(
		questionnaireActionTypes.QUESTIONNAIRE_UPDATE_REPSONSE_START,
		updateQuestionnaireDetails,
	);
}

export function* questionnaireUpdateResponseReset() {
	yield takeLatest(
		questionnaireActionTypes.QUESTIONNAIRE_UPDATE_REPSONSE_RESET,
		updateQuestionnaireResponseReset,
	);
}

export function* questionnaireDeleteStart() {
	yield takeLatest(
		questionnaireActionTypes.QUESTIONNAIRE_DELETE_REPSONSE_START,
		deleteQuestionnaireDetails,
	);
}

export function* questionnaireDeleteResponseReset() {
	yield takeLatest(
		questionnaireActionTypes.QUESTIONNAIRE_DELETE_REPSONSE_RESET,
		deleteQuestionnaireResponseReset,
	);
}

export function* questionnaireFetchStart() {
	yield takeLatest(
		questionnaireActionTypes.QUESTIONNAIRE_FETCH_REPSONSE_START,
		fetchQuestionnaireDetails,
	);
}

export function* questionnaireFetchResponseReset() {
	yield takeLatest(
		questionnaireActionTypes.QUESTIONNAIRE_FETCH_REPSONSE_RESET,
		fetchQuestionnaireResponseReset,
	);
}

export function* getAllQuestionnaireFilterList() {
	yield takeLatest(
		questionnaireActionTypes.QUESTIONNAIRE_GET_FILTER_SEARCH_LIST,
		questionnaireFilterList,
	);
}

export function* questionnaireSaga() {
	yield all([
		call(getAllQuestionnaireList),
		call(getAllQuestionnaireListReset),
		call(questionnaireCreateStart),
		call(questionnaireCreateResponseReset),
		call(questionnaireUpdateStart),
		call(questionnaireUpdateResponseReset),
		call(questionnaireDeleteStart),
		call(questionnaireDeleteResponseReset),
		call(questionnaireFetchStart),
		call(questionnaireFetchResponseReset),
		call(getAllQuestionnaireFilterList),
	]);
}
