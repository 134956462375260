/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import demandReportActionTypes from './demandReport.types';
import generalTypesAction from '../genera.types';
import { getEndodeJsonData } from '../common/common.utils';

const INITIAL_STATE = {
	allDemandReportList: null,
	allDemandReportListStatus: null,

	demandReportFetch: null,
	demandReportFetchStatus: null,
};

// eslint-disable-next-line default-param-last
const demandReportReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case demandReportActionTypes.DEMAND_REPORT_LIST_SUCCESS:
			return {
				...state,
				allDemandReportList: getEndodeJsonData(action.payload, generalTypesAction.DEMAND),
				allDemandReportListStatus: 'Success',
			};
		case demandReportActionTypes.DEMAND_REPORT_LIST_FAILURE:
			return { ...state, allDemandReportList: null, allDemandReportListStatus: 'Failure' };
		case demandReportActionTypes.DEMAND_REPORT_LIST_CHANGED:
			return { ...state, allDemandReportListStatus: null };

		case demandReportActionTypes.DEMAND_REPORT_FETCH_SUCCESS:
			return {
				...state,
				demandReportFetch: getEndodeJsonData(action.payload, generalTypesAction.DEMAND),
				demandReportFetchStatus: 'Success',
			};
		case demandReportActionTypes.DEMAND_REPORT_FETCH_FAILURE:
			return { ...state, demandReportFetch: null, demandReportFetchStatus: 'Failure' };
		case demandReportActionTypes.DEMAND_REPORT_FETCH_CHANGED:
			return { ...state, demandReportFetchStatus: null };
		default:
			return state;
	}
};

export default demandReportReducer;
