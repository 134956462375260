"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvalidAudience = exports.InvalidIssuer = exports.InvalidSubject = exports.IssuedAtInvalid = exports.ImmatureSignature = exports.TokenExpired = exports.AlgorithmNotSupported = exports.AlgorithmMissing = exports.InvalidBody = exports.InvalidHeader = exports.SignatureInvalid = exports.InvalidStructure = void 0;
var algorithms_1 = require("./algorithms");
var InvalidStructure = /** @class */ (function (_super) {
    __extends(InvalidStructure, _super);
    function InvalidStructure() {
        return _super.call(this, 'Token must be in the format: header.body.signature') || this;
    }
    return InvalidStructure;
}(Error));
exports.InvalidStructure = InvalidStructure;
var SignatureInvalid = /** @class */ (function (_super) {
    __extends(SignatureInvalid, _super);
    function SignatureInvalid() {
        return _super.call(this, 'Invalid token signature') || this;
    }
    return SignatureInvalid;
}(Error));
exports.SignatureInvalid = SignatureInvalid;
var InvalidHeader = /** @class */ (function (_super) {
    __extends(InvalidHeader, _super);
    function InvalidHeader() {
        return _super.call(this, 'Invalid token header format') || this;
    }
    return InvalidHeader;
}(Error));
exports.InvalidHeader = InvalidHeader;
var InvalidBody = /** @class */ (function (_super) {
    __extends(InvalidBody, _super);
    function InvalidBody() {
        return _super.call(this, 'Invalid token body format') || this;
    }
    return InvalidBody;
}(Error));
exports.InvalidBody = InvalidBody;
var AlgorithmMissing = /** @class */ (function (_super) {
    __extends(AlgorithmMissing, _super);
    function AlgorithmMissing() {
        return _super.call(this, 'Algorithm specified in header is missing') || this;
    }
    return AlgorithmMissing;
}(Error));
exports.AlgorithmMissing = AlgorithmMissing;
var AlgorithmNotSupported = /** @class */ (function (_super) {
    __extends(AlgorithmNotSupported, _super);
    function AlgorithmNotSupported() {
        var _this = this;
        var supported = algorithms_1.supportedAlgorithms.join(', ');
        _this = _super.call(this, "Algorithm not supported. Supported algorithms are: ".concat(supported)) || this;
        return _this;
    }
    return AlgorithmNotSupported;
}(Error));
exports.AlgorithmNotSupported = AlgorithmNotSupported;
var TokenExpired = /** @class */ (function (_super) {
    __extends(TokenExpired, _super);
    function TokenExpired() {
        return _super.call(this, 'Token has expired') || this;
    }
    return TokenExpired;
}(Error));
exports.TokenExpired = TokenExpired;
var ImmatureSignature = /** @class */ (function (_super) {
    __extends(ImmatureSignature, _super);
    function ImmatureSignature() {
        return _super.call(this, 'Token used before becoming valid') || this;
    }
    return ImmatureSignature;
}(Error));
exports.ImmatureSignature = ImmatureSignature;
var IssuedAtInvalid = /** @class */ (function (_super) {
    __extends(IssuedAtInvalid, _super);
    function IssuedAtInvalid() {
        return _super.call(this, 'Issued At claim is invalid') || this;
    }
    return IssuedAtInvalid;
}(Error));
exports.IssuedAtInvalid = IssuedAtInvalid;
var InvalidSubject = /** @class */ (function (_super) {
    __extends(InvalidSubject, _super);
    function InvalidSubject() {
        return _super.call(this, 'Subject is invalid') || this;
    }
    return InvalidSubject;
}(Error));
exports.InvalidSubject = InvalidSubject;
var InvalidIssuer = /** @class */ (function (_super) {
    __extends(InvalidIssuer, _super);
    function InvalidIssuer() {
        return _super.call(this, 'Issuer is invalid') || this;
    }
    return InvalidIssuer;
}(Error));
exports.InvalidIssuer = InvalidIssuer;
var InvalidAudience = /** @class */ (function (_super) {
    __extends(InvalidAudience, _super);
    function InvalidAudience() {
        return _super.call(this, 'Audience is invalid') || this;
    }
    return InvalidAudience;
}(Error));
exports.InvalidAudience = InvalidAudience;
