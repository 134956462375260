const branchActionTypes = {
	BRANCH_CREATE_REPSONSE_START: 'branch_create_response_start',
	BRANCH_CREATE_REPSONSE_SUCCESS: 'branch_create_response_success',
	BRANCH_CREATE_REPSONSE_FAILURE: 'branch_create_response_failure',
	BRANCH_CREATE_REPSONSE_CHANGED: 'branch_create_response_changed',
	BRANCH_CREATE_REPSONSE_RESET: 'branch_create_response_reset',

	BRANCH_UPDATE_REPSONSE_START: 'branch_update_response_start',
	BRANCH_UPDATE_REPSONSE_SUCCESS: 'branch_update_response_success',
	BRANCH_UPDATE_REPSONSE_FAILURE: 'branch_update_response_failure',
	BRANCH_UPDATE_REPSONSE_CHANGED: 'branch_update_response_changed',
	BRANCH_UPDATE_REPSONSE_RESET: 'branch_update_response_reset',

	BRANCH_DELETE_REPSONSE_START: 'branch_delete_response_start',
	BRANCH_DELETE_REPSONSE_SUCCESS: 'branch_delete_response_success',
	BRANCH_DELETE_REPSONSE_FAILURE: 'branch_delete_response_failure',
	BRANCH_DELETE_REPSONSE_CHANGED: 'branch_delete_response_changed',
	BRANCH_DELETE_REPSONSE_RESET: 'branch_delete_response_reset',

	BRANCH_LIST_START: 'branch_list_start',
	BRANCH_LIST_SUCCESS: 'branch_list_success',
	BRANCH_LIST_FAILURE: 'branch_list_failure',
	BRANCH_LIST_CHANGED: 'branch_list_changed',
	BRANCH_LIST_RESET: 'branch_list_reset',
	BRANCH_LIST_ID: 'branch_list_id',
	BRANCH_LIST_NAME: 'branch_list_name',

	BRANCH_FILTER_SEARCH_LIST_START: 'branch_filter_search_list_start',

	BRANCH_SELECTED_ID: 'branch_selected_id',

	POSTCODE_CREATE_RESPONSE_START: 'postCode_create_response_start',
	POSTCODE_CREATE_RESPONSE_SUCCESS: 'postCode_create_response_success',
	POSTCODE_CREATE_RESPONSE_FAILURE: 'postCode_create_response_failure',
	POSTCODE_CREATE_RESPONSE_CHANGED: 'postCode_create_response_changed',
	POSTCODE_CREATE_RESPONSE_RESET: 'postCode_create_response_reset',

	BRANCH_PINCODE_RESPONSE_START: 'branch_pincode_response_start',
	BRANCH_PINCODE_RESPONSE_SUCCESS: 'branch_pincode_response_success',
	BRANCH_PINCODE_RESPONSE_FAILURE: 'branch_pincode_response_failure',
	BRANCH_PINCODE_RESPONSE_CHANGED: 'branch_pincode_response_changed',
	BRANCH_PINCODE_RESPONSE_RESET: 'branch_pincode_response_reset',

	BRANCH_PINCODE_DELETE_START: 'branch_pincode_delete_start',
	BRANCH_PINCODE_DELETE_SUCCESS: 'branch_pincode_delete_success',
	BRANCH_PINCODE_DELETE_FAILURE: 'branch_pincode_delete_failure',
	BRANCH_PINCODE_DELETE_CHANGED: 'branch_pincode_delete_changed',
	BRANCH_PINCODE_DELETE_RESET: 'branch_pincode_delete_reset',

	BRANCH_PINCODE_LIST_START: 'branch_pincode_list_start',
	BRANCH_PINCODE_LIST_SUCCESS: 'branch_pincode_list_success',
	BRANCH_PINCODE_LIST_FAILURE: 'branch_pincode_list_failure',
	BRANCH_PINCODE_LIST_CHANGED: 'branch_pincode_list_changed',
	BRANCH_PINCODE_LIST_RESET: 'branch_pincode_list_reset',
};
export default branchActionTypes;
