/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import loanIndexActionTypes from './loanIndex.types';

// SET LOAN LIMIT
export const setLoanLimitSuccess = (loanLimit) => ({
	type: loanIndexActionTypes.SET_LOAN_LIMIT_SUCCESS,
	payload: loanLimit,
});

export const setLoanLimitFailure = (error) => ({
	type: loanIndexActionTypes.SET_LOAN_LIMIT_FAILURE,
	payload: error,
});

export const setLoanLimitStart = (loanLimitDetails) => ({
	type: loanIndexActionTypes.SET_LOAN_LIMIT_START,
	payload: loanLimitDetails,
});

export const setLoanLimitChanged = () => ({
	type: loanIndexActionTypes.SET_LOAN_LIMIT_CHANGED,
});

export const setLoanLimitReset = () => ({
	type: loanIndexActionTypes.SET_LOAN_LIMIT_RESET,
});

export const offlineLoanDisbursementSuccess = (loanLimit) => ({
	type: loanIndexActionTypes.LOAN_DISBURSEMENT_OFFLINE_SUCCESS,
	payload: loanLimit,
});

export const offlineLoanDisbursementFailure = (error) => ({
	type: loanIndexActionTypes.LOAN_DISBURSEMENT_OFFLINE_FAILURE,
	payload: error,
});

export const offlineLoanDisbursementStart = (loanLimitDetails) => ({
	type: loanIndexActionTypes.LOAN_DISBURSEMENT_OFFLINE_START,
	payload: loanLimitDetails,
});

export const offlineLoanDisbursementChanged = () => ({
	type: loanIndexActionTypes.LOAN_DISBURSEMENT_OFFLINE_CHANGED,
});

export const offlineLoanDisbursementReset = () => ({
	type: loanIndexActionTypes.LOAN_DISBURSEMENT_OFFLINE_RESET,
});

export const setGroupLoanLimitSuccess = (loanLimit) => ({
	type: loanIndexActionTypes.SET_LOAN_LIMIT_GROUP_SUCCESS,
	payload: loanLimit,
});

export const setGroupLoanLimitFailure = (error) => ({
	type: loanIndexActionTypes.SET_LOAN_LIMIT_GROUP_FAILURE,
	payload: error,
});

export const setGroupLoanLimitStart = (loanLimitDetails) => ({
	type: loanIndexActionTypes.SET_LOAN_LIMIT_GROUP_START,
	payload: loanLimitDetails,
});

export const setGroupLoanLimitChanged = () => ({
	type: loanIndexActionTypes.SET_LOAN_LIMIT_GROUP_CHANGED,
});

export const setGroupLoanLimitReset = () => ({
	type: loanIndexActionTypes.SET_LOAN_LIMIT_GROUP_RESET,
});

export const getLoanRequestSchedule = (loanRequestCredentials) => ({
	type: loanIndexActionTypes.LOAN_REQUEST_CALCULATION,
	payload: loanRequestCredentials,
});

// SET LOAN PRODUCT
export const setLoanProductStart = (loanProductDetails) => ({
	type: loanIndexActionTypes.SET_LOAN_PRODUCT_START,
	payload: loanProductDetails,
});

export const setLoanProductSuccess = (loanProduct) => ({
	type: loanIndexActionTypes.SET_LOAN_PRODUCT_SUCCESS,
	payload: loanProduct,
});

export const setLoanProductFailure = (error) => ({
	type: loanIndexActionTypes.SET_LOAN_PRODUCT_FAILURE,
	payload: error,
});

export const setLoanProductChanged = () => ({
	type: loanIndexActionTypes.SET_LOAN_PRODUCT_CHANGED,
});

export const setLoanProductReset = () => ({
	type: loanIndexActionTypes.SET_LOAN_PRODUCT_RESET,
});