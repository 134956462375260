const RepaymentReportActionTypes = {
	REPAYMENT_REPORT_LIST_START: 'REPAYMENT_REPORT_LIST_START',
	REPAYMENT_REPORT_LIST_SUCCESS: 'REPAYMENT_REPORT_LIST_SUCCESS',
	REPAYMENT_REPORT_LIST_FAILURE: 'REPAYMENT_REPORT_LIST_FAILURE',
	REPAYMENT_REPORT_LIST_CHANGED: 'REPAYMENT_REPORT_LIST_CHANGED',
	REPAYMENT_REPORT_LIST_RESET: 'REPAYMENT_REPORT_LIST_RESET',

	REPAYMENT_REPORT_GET_FILTER_SEARCH_LIST: 'REPAYMENT_REPORT_GET_FILTER_SEARCH_LIST',
};
export default RepaymentReportActionTypes;
