/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

const selectUser = (state) => state.login;

export const selectCurrentUser = createSelector([selectUser], (login) => login.loginResponse);
export const selectCurrentUserStatus = createSelector([selectUser], (login) => login.loginStatus);

export const userLoginStatus = createSelector([selectUser], (login) => login.isLogin);

export const userLoginDatas = createSelector([selectUser], (login) => login);
export const userCompanyID = createSelector([selectUser], (login) => login.companyID);
