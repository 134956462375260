const ProductActionTypes = {
	PRODUCT_LIST_START: 'PRODUCT_LIST_START',
	PRODUCT_LIST_SUCCESS: 'PRODUCT_LIST_SUCCESS',
	PRODUCT_LIST_FAILURE: 'PRODUCT_LIST_FAILURE',
	PRODUCT_LIST_CHANGED: 'PRODUCT_LIST_CHANGED',
	PRODUCT_LIST_RESET: 'PRODUCT_LIST_RESET',

	PRODUCT_CREATE_START: 'PRODUCT_CREATE_START',
	PRODUCT_CREATE_SUCCESS: 'PRODUCT_CREATE_SUCCESS',
	PRODUCT_CREATE_FAILURE: 'PRODUCT_CREATE_FAILURE',
	PRODUCT_CREATE_CHANGED: 'PRODUCT_CREATE_CHANGED',
	PRODUCT_CREATE_RESET: 'PRODUCT_CREATE_RESET',

	PRODUCT_UPDATE_START: 'PRODUCT_UPDATE_START',
	PRODUCT_UPDATE_SUCCESS: 'PRODUCT_UPDATE_SUCCESS',
	PRODUCT_UPDATE_FAILURE: 'PRODUCT_UPDATE_FAILURE',
	PRODUCT_UPDATE_CHANGED: 'PRODUCT_UPDATE_CHANGED',
	PRODUCT_UPDATE_RESET: 'PRODUCT_UPDATE_RESET',

	PRODUCT_DELETE_START: 'PRODUCT_DELETE_START',
	PRODUCT_DELETE_SUCCESS: 'PRODUCT_DELETE_SUCCESS',
	PRODUCT_DELETE_FAILURE: 'PRODUCT_DELETE_FAILURE',
	PRODUCT_DELETE_CHANGED: 'PRODUCT_DELETE_CHANGED',
	PRODUCT_DELETE_RESET: 'PRODUCT_DELETE_RESET',

	PRODUCT_GET_FILTER_SEARCH_LIST: 'PRODUCT_GET_FILTER_SEARCH_LIST',

	PRODUCT_FETCH_START: 'PRODUCT_FETCH_START',
	PRODUCT_FETCH_SUCCESS: 'PRODUCT_FETCH_SUCCESS',
	PRODUCT_FETCH_FAILURE: 'PRODUCT_FETCH_FAILURE',
	PRODUCT_FETCH_CHANGED: 'PRODUCT_FETCH_CHANGED',
	PRODUCT_FETCH_RESET: 'PRODUCT_FETCH_RESET',
};
export default ProductActionTypes;
