/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import cbCheckActionTypes from './cbCheck.types';
import generalTypesAction from '../genera.types';
import { getEndodeJsonData } from '../common/common.utils';

const INITIAL_STATE = {
	memberCBCheckResponse: null,
	memberCBCheckResponseStatus: null,

	memberCRIFCheckResponse: null,
	memberCRIFCheckResponseStatus: null,

	memberCheckCrifStatus: null,
	memberCheckCrifResponse: null,

	memberHMResponse: null,
	memberHMResponseStatus: null,
};

// eslint-disable-next-line default-param-last
const cbCheckReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case cbCheckActionTypes.MEMBER_GET_CBCHECK_SUCCESS:
			return {
				...state,
				memberCBCheckResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.CBCHECK,
				),
				memberCBCheckResponseStatus: 'Success',
			};
		case cbCheckActionTypes.MEMBER_GET_CBCHECK_FAILURE:
			return {
				...state,
				memberCBCheckResponse: null,
				memberCBCheckResponseStatus: 'Failure',
			};
		case cbCheckActionTypes.MEMBER_GET_CBCHECK_CHANGED:
			return { ...state, memberCBCheckResponseStatus: null };

		case cbCheckActionTypes.MEMBER_GET_CRIF_SUCCESS:
			return {
				...state,
				memberCRIFCheckResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.HIGHMARK,
				),
				memberCRIFCheckResponseStatus: 'Success',
			};
		case cbCheckActionTypes.MEMBER_GET_CRIF_FAILURE:
			return {
				...state,
				memberCRIFCheckResponse: null,
				memberCRIFCheckResponseStatus: 'Failure',
			};
		case cbCheckActionTypes.MEMBER_GET_CRIF_CHANGED:
			return { ...state, memberCRIFCheckResponse: null, memberCRIFCheckResponseStatus: null };

		// CHECK CRIF SECTION

		case cbCheckActionTypes.MEMBER_CHECK_CRIF_SUCCESS:
			return {
				...state,
				memberCheckCrifResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.HIGHMARK,
				),
				memberCheckCrifStatus: 'Success',
			};
		case cbCheckActionTypes.MEMBER_CHECK_CRIF_FAILURE:
			return {
				...state,
				memberCheckCrifResponse: null,
				memberCheckCrifStatus: 'Failure',
			};
		case cbCheckActionTypes.MEMBER_CHECK_CRIF_CHANGED:
			return { ...state, memberCheckCrifStatus: null };

		// FETCH HIGH MARK SECTION
		case cbCheckActionTypes.MEMBER_GET_HIGHMARK_SUCCESS:
			return {
				...state,
				memberHMResponse: getEndodeJsonData(action.payload, generalTypesAction.HIGHMARK),
				memberHMResponseStatus: 'Success',
			};
		case cbCheckActionTypes.MEMBER_GET_HIGHMARK_FAILURE:
			return {
				...state,
				memberHMResponse: null,
				memberHMResponseStatus: 'Failure',
			};
		case cbCheckActionTypes.MEMBER_GET_HIGHMARK_CHANGED:
			return { ...state, memberHMResponseStatus: null };

		default:
			return state;
	}
};

export default cbCheckReducer;
