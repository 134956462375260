/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { PAISANOW_API_URL } from '../const';

export const createBranch = (BranchData) => {
	debugger;
	const data = {
		BranchCreateToken: BranchData,
	};
	const url = `${PAISANOW_API_URL}AddBranchDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateBranch = (BranchData) => {
	const data = {
		BranchUpdateToken: BranchData,
	};
	const url = `${PAISANOW_API_URL}UpdateBranchDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteBranch = (BranchData) => {
	const data = {
		BranchDeleteToken: BranchData,
	};
	const url = `${PAISANOW_API_URL}DeleteBranchDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getAllBranch = (BranchData) => {
	const data = {
		BranchListToken: BranchData,
	};
	const url = `${PAISANOW_API_URL}GetAllBranchDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createBranchPinCode = (BranchPinCodeData) => {
	const data = {
		PinCodeCreateToken: BranchPinCodeData,
	};
	const url = `${PAISANOW_API_URL}AddPinCodeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteBranchPinCode = (BranchPinCodeDeleteData) => {
	const data = {
		PinCodeDeleteToken: BranchPinCodeDeleteData,
	};
	debugger;
	const url = `${PAISANOW_API_URL}DeletePinCodeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const postCodeCreate = (postCodeData) => {
	const data = postCodeData;
	const url = `https://api.postalpincode.in/pincode/${data}`;
	return axios.get(url);
};

export const getAllBranchPinCode = (BranchPinCodeData) => {
	const data = {
		PinCodeListToken: BranchPinCodeData,
	};
	const url = `${PAISANOW_API_URL}GetAllPinCodeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};