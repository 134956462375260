const cbCheckActionTypes = {
	MEMBER_GET_CBCHECK_START: 'member_get_cbcheck_start',
	MEMBER_GET_CBCHECK_SUCCESS: 'member_get_cbcheck_success',
	MEMBER_GET_CBCHECK_FAILURE: 'member_get_cbcheck_failure',
	MEMBER_GET_CBCHECK_CHANGED: 'member_get_cbcheck_changed',
	MEMBER_GET_CBCHECK_RESET: 'member_get_cbcheck_reset',

	MEMBER_GET_CRIF_START: 'member_get_crif_start',
	MEMBER_GET_CRIF_SUCCESS: 'member_get_crif_success',
	MEMBER_GET_CRIF_FAILURE: 'member_get_crif_failure',
	MEMBER_GET_CRIF_CHANGED: 'member_get_crif_changed',
	MEMBER_GET_CRIF_RESET: 'member_get_crif_reset',

	MEMBER_CHECK_CRIF_START: 'MEMBER_CHECK_CRIF_START',
	MEMBER_CHECK_CRIF_SUCCESS: 'MEMBER_CHECK_CRIF_SUCCESS',
	MEMBER_CHECK_CRIF_FAILURE: 'MEMBER_CHECK_CRIF_FAILURE',
	MEMBER_CHECK_CRIF_CHANGED: 'MEMBER_CHECK_CRIF_CHANGED',
	MEMBER_CHECK_CRIF_RESET: 'MEMBER_CHECK_CRIF_RESET',

	MEMBER_GET_HIGHMARK_START: 'MEMBER_GET_HIGHMARK_START',
	MEMBER_GET_HIGHMARK_SUCCESS: 'MEMBER_GET_HIGHMARK_SUCCESS',
	MEMBER_GET_HIGHMARK_FAILURE: 'MEMBER_GET_HIGHMARK_FAILURE',
	MEMBER_GET_HIGHMARK_CHANGED: 'MEMBER_GET_HIGHMARK_CHANGED',
	MEMBER_GET_HIGHMARK_RESET: 'MEMBER_GET_HIGHMARK_RESET',
};
export default cbCheckActionTypes;
