/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import userTypeActionTypes from './userType.types';

// CREATE SECTION
export const createUserTypeSuccess = (UserType) => ({
	type: userTypeActionTypes.USERTYPE_CREATE_REPSONSE_SUCCESS,
	payload: UserType,
});

export const createUserTypeFailure = (error) => ({
	type: userTypeActionTypes.USERTYPE_CREATE_REPSONSE_FAILURE,
	payload: error,
});

export const createUserTypeStart = (UserTypeDetails) => ({
	type: userTypeActionTypes.USERTYPE_CREATE_REPSONSE_START,
	payload: UserTypeDetails,
});

export const userTypeCreateChanged = () => ({
	type: userTypeActionTypes.USERTYPE_CREATE_REPSONSE_CHANGED,
});

export const userTypeCreateReset = () => ({
	type: userTypeActionTypes.USERTYPE_CREATE_REPSONSE_RESET,
});

// CREATE SECTION
// UPDATE SECTION
export const updateUserTypeSuccess = (UserType) => ({
	type: userTypeActionTypes.USERTYPE_UPDATE_REPSONSE_SUCCESS,
	payload: UserType,
});

export const updateUserTypeFailure = (error) => ({
	type: userTypeActionTypes.USERTYPE_UPDATE_REPSONSE_FAILURE,
	payload: error,
});

export const updateUserTypeStart = (UserTypeDetails) => ({
	type: userTypeActionTypes.USERTYPE_UPDATE_REPSONSE_START,
	payload: UserTypeDetails,
});

export const userTypeUpdateChanged = () => ({
	type: userTypeActionTypes.USERTYPE_UPDATE_REPSONSE_CHANGED,
});

export const userTypeUpdateReset = () => ({
	type: userTypeActionTypes.USERTYPE_UPDATE_REPSONSE_RESET,
});
// UPDATE SECTION
// DELETE SECTION

export const deleteUserTypeSuccess = (UserType) => ({
	type: userTypeActionTypes.USERTYPE_DELETE_REPSONSE_SUCCESS,
	payload: UserType,
});

export const deleteUserTypeFailure = (error) => ({
	type: userTypeActionTypes.USERTYPE_DELETE_REPSONSE_FAILURE,
	payload: error,
});

export const deleteUserTypeStart = (UserTypeDetails) => ({
	type: userTypeActionTypes.USERTYPE_DELETE_REPSONSE_START,
	payload: UserTypeDetails,
});

export const userTypeDeleteChanged = () => ({
	type: userTypeActionTypes.USERTYPE_DELETE_REPSONSE_CHANGED,
});

export const userTypeDeleteReset = () => ({
	type: userTypeActionTypes.USERTYPE_DELETE_REPSONSE_RESET,
});

// DELETE SECTION
export const getAllUserTypeList = (UserType) => ({
	type: userTypeActionTypes.USERTYPE_LIST_START,
	payload: UserType,
});

export const getAllUserTypeListSuccess = (UserType) => ({
	type: userTypeActionTypes.USERTYPE_LIST_SUCCESS,
	payload: UserType,
});

export const getAllUserTypeListFailure = (error) => ({
	type: userTypeActionTypes.USERTYPE_LIST_FAILURE,
	payload: error,
});

export const getAllUserTypeListChanged = () => ({
	type: userTypeActionTypes.USERTYPE_LIST_CHANGED,
});

export const getAllUserTypeListReset = () => ({
	type: userTypeActionTypes.USERTYPE_LIST_RESET,
});

export const getFilterListStart = (UserTypeDetails) => ({
	type: userTypeActionTypes.USERTYPE_GET_FILTER_SEARCH_LIST,
	payload: UserTypeDetails,
});