/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import departmentActionTypes from './department.types';
import {
	createDepartment,
	getAllDepartment,
	updateDepartment,
	deleteDepartment,
} from './department.services';
import {
	createDepartmentSuccess,
	createDepartmentFailure,
	updateDepartmentSuccess,
	updateDepartmentFailure,
	getAllListSuccess,
	getAllListFailure,
	deleteDepartmentSuccess,
	deleteDepartmentFailure,
} from './department.actions';

export function* createNewDepartment({ payload: { departmentName, payrollID, dbName } }) {
	try {
		const responseData = yield call(createDepartment, departmentName, payrollID, dbName);
		if (responseData.data.status === 'failure') {
			yield put(createDepartmentFailure(responseData.data.message_text));
		} else {
			yield put(createDepartmentSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createDepartmentFailure(error));
	}
}

export function* updateDepartmentDetails({
	payload: { departmentName, payrollID, dbName, deptID },
}) {
	try {
		const responseData = yield call(
			updateDepartment,
			departmentName,
			payrollID,
			dbName,
			deptID,
		);
		if (responseData.data.status === 'failure') {
			yield put(updateDepartmentFailure(responseData.data.message_text));
		} else {
			yield put(updateDepartmentSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateDepartmentFailure(error));
	}
}

export function* deleteDepartmentDetails({ payload: { dbName, deptID } }) {
	try {
		const responseData = yield call(deleteDepartment, dbName, deptID);
		if (responseData.data.status === 'failure') {
			yield put(deleteDepartmentFailure(responseData.data.message_text));
		} else {
			yield put(deleteDepartmentSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteDepartmentFailure(error));
	}
}

export function* createListOfDepartment({ payload: { dbName, searchData, limit, payrollID } }) {
	try {
		const responseData = yield call(getAllDepartment, dbName, searchData, limit, payrollID);
		if (responseData.data.status === 'failure') {
			yield put(getAllListFailure(responseData.data.message_text));
		} else {
			yield put(getAllListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAllListFailure(error));
	}
}

export function* departmentCreateStart() {
	yield takeLatest(departmentActionTypes.DEPARTMENT_CREATE_REPSONSE_START, createNewDepartment);
}

export function* departmentUpdateStart() {
	yield takeLatest(
		departmentActionTypes.DEPARTMENT_UPDATE_REPSONSE_START,
		updateDepartmentDetails,
	);
}

export function* departmentDeleteStart() {
	yield takeLatest(
		departmentActionTypes.DEPARTMENT_DELETE_REPSONSE_START,
		deleteDepartmentDetails,
	);
}

export function* getAllDepartmentList() {
	yield takeLatest(departmentActionTypes.DEPARTMENT_LIST_START, createListOfDepartment);
}

export function* departmentSaga() {
	yield all([
		call(departmentCreateStart),
		call(getAllDepartmentList),
		call(departmentUpdateStart),
		call(departmentDeleteStart),
	]);
}
