/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { PAISANOW_API_URL } from '../const';

export const createCompany = (CompanyData) => {
	const data = {
		CompanyCreateToken: CompanyData,
	};
	const url = `${PAISANOW_API_URL}AddCompanyDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateCompany = (CompanyData) => {
	const data = {
		CompanyUpdateToken: CompanyData,
	};
	const url = `${PAISANOW_API_URL}UpdateCompanyDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteCompany = (CompanyData) => {
	const data = {
		CompanyDeleteToken: CompanyData,
	};
	const url = `${PAISANOW_API_URL}DeleteCompanyDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getAllCompany = (CompanyData) => {
	const data = {
		CompanyListToken: CompanyData,
	};
	const url = `${PAISANOW_API_URL}GetAllCompanyDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// LMS CALL SECTION

export const companyLMSCall = (CompanyData) => {
	const data = {
		PaisaNowPushToken: CompanyData,
	};
	const url = `${PAISANOW_API_URL}PushToSquareNow`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// COMPANY LIST SELECT

export const companyListSelectCall = (CompanyData) => {
	const data = {
		CompanyListToken: CompanyData,
	};
	const url = `${PAISANOW_API_URL}GetAllCompanyList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// COMPANY	COND LIST SELECT
export const companyCondListCall = (CompanyData) => {
	const data = {
		CompanyCondListToken: CompanyData,
	};
	const url = `${PAISANOW_API_URL}GetAllCompanyCondList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// COMPANY	COND CREATE SELECT

export const companyCondCreateCall = (CompanyData) => {
	const data = {
		CompanyCondCreateToken: CompanyData,
	};
	const url = `${PAISANOW_API_URL}AddCompanyCond`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// LOAN SCHEDULE SECTION

export const companyLoanSchedule = (CompanyData) => {
	const data = {
		PaisaNowPushToken: CompanyData,
	};
	const url = `${PAISANOW_API_URL}PushToSquareNowScheduleDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

