/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import memberActionTypes from './member.types';
import generalTypesAction from '../genera.types';
import { getEndodeJsonData } from '../common/common.utils';

const INITIAL_STATE = {
	allMemberBorrowerList: null,
	allMemberBorrowerListStatus: null,

	MemberBorrowerDetails: null,
	MemberBorrowerDetailsStatus: null,

	memberContactList: null,
	memberContactListStatus: null,

	memberBankList: null,
	memberBankListStatus: null,

	memberLoanList: null,
	memberLoanListStatus: null,

	// REJECT SECTION
	memberReject: null,
	memberRejectStatus: null,
};

// eslint-disable-next-line default-param-last
const memberReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case memberActionTypes.MEMBER_GET_ALL_SUCCESS:
			return {
				...state,
				allMemberBorrowerList: getEndodeJsonData(action.payload, generalTypesAction.MEMBER),
				allMemberBorrowerListStatus: 'Success',
			};
		case memberActionTypes.MEMBER_GET_ALL_FAILURE:
			return {
				...state,
				allMemberBorrowerList: null,
				allMemberBorrowerListStatus: 'Failure',
			};
		case memberActionTypes.MEMBER_GET_ALL_CHANGED:
			return { ...state, allMemberBorrowerListStatus: null };

		case memberActionTypes.MEMBER_GET_DETAILS_SUCCESS:
			return {
				...state,
				MemberBorrowerDetails: action.payload,
				MemberBorrowerDetailsStatus: 'Success',
			};
		case memberActionTypes.MEMBER_GET_DETAILS_FAILURE:
			return {
				...state,
				MemberBorrowerDetails: action.payload,
				MemberBorrowerDetailsStatus: 'Failure',
			};
		case memberActionTypes.MEMBER_GET_DETAILS_CHANGED:
			return { ...state, MemberBorrowerDetails: null, MemberBorrowerDetailsStatus: null };

		case memberActionTypes.MEMBER_CONTACT_FETCH_SUCCESS:
			return {
				...state,
				memberContactList: action.payload,
				memberContactListStatus: 'Success',
			};
		case memberActionTypes.MEMBER_CONTACT_FETCH_FAILURE:
			return {
				...state,
				memberContactList: action.payload,
				memberContactListStatus: 'Failure',
			};
		case memberActionTypes.MEMBER_CONTACT_FETCH_CHANGED:
			return { ...state, memberContactList: null, memberContactListStatus: null };

		case memberActionTypes.MEMBER_BANK_FETCH_SUCCESS:
			return {
				...state,
				memberBankList: action.payload,
				memberBankListStatus: 'Success',
			};
		case memberActionTypes.MEMBER_BANK_FETCH_FAILURE:
			return {
				...state,
				memberBankList: action.payload,
				memberBankListStatus: 'Failure',
			};
		case memberActionTypes.MEMBER_BANK_FETCH_CHANGED:
			return { ...state, memberBankList: null, memberBankListStatus: null };

		case memberActionTypes.MEMBER_GET_LOAN_DETAILS_SUCCESS:
			return {
				...state,
				memberLoanList: action.payload,
				memberLoanListStatus: 'Success',
			};
		case memberActionTypes.MEMBER_GET_LOAN_DETAILS_FAILURE:
			return {
				...state,
				memberLoanList: action.payload,
				memberLoanListStatus: 'Failure',
			};
		case memberActionTypes.MEMBER_GET_LOAN_DETAILS_CHANGED:
			return { ...state, memberLoanList: null, memberLoanListStatus: null };

		// REJECT SECTION
		case memberActionTypes.MEMBER_REJECT_SUCCESS:
			return {
				...state,
				memberReject: getEndodeJsonData(action.payload, generalTypesAction.MEMBER),
				memberRejectStatus: 'Success',
			};
		case memberActionTypes.MEMBER_REJECT_FAILURE:
			return {
				...state,
				memberReject: null,
				memberRejectStatus: 'Failure',
			};
		case memberActionTypes.MEMBER_REJECT_CHANGED:
			return { ...state, memberRejectStatus: null };

		default:
			return state;
	}
};

export default memberReducer;
