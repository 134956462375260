/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { PAISANOW_API_URL } from '../const';

export const getBorrowersCBCheck = (memberData) => {
	const data = {
		CBCheckToken: memberData,
	};
	const url = `${PAISANOW_API_URL}GetStageThreeCheckCBResponse`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getBorrowersCRIFCheck = (memberData) => {
	const data = {
		HighMarkToken: memberData,
	};
	const url = `${PAISANOW_API_URL}UploadHighMarkDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// CHECK CRIF SECTION
export const memberCRIFCheck = (memberData) => {
	const data = {
		HighMarkToken: memberData,
	};
	const url = `${PAISANOW_API_URL}CheckMemberHighMarkDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// FETCH HIGHMARK SECTION
export const getMemberHMCheck = (memberData) => {
	const data = {
		HighMarkToken: memberData,
	};
	const url = `${PAISANOW_API_URL}FetchHighMarkDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};