/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import dashboardActionTypes from './dashboard.types';
import generalTypesAction from '../genera.types';
import { dashboardLoadCall, dashboardCollectionCall, dashboardCollectionTableCall } from './dashboard.services';
import {
	dashboardLoadSuccess,
	dashboardLoadFailure,
	dashboardLoadChanged,
	dashboardCollectionSuccess,
	dashboardCollectionFailure,
	dashboardCollectionChanged,
	dashboardCollectionTableSuccess,
	dashboardCollectionTableFailure,
	dashboardCollectionTableChanged,
} from './dashboard.actions';
import { encodeJson } from '../enode-decode';

export function* loadDashboardStart({ payload }) {
	try {
		const jsonData = {
			UserID: payload.userID,
			CompanyID: payload.companyID,
			IsKapiital: payload.isKapiital,
			OverViewType: payload.overViewType,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
		};
		const encodeData = encodeJson(jsonData, generalTypesAction.DASHBOARD);
		const responseData = yield call(dashboardLoadCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(dashboardLoadFailure(responseData.data));
		} else {
			yield put(dashboardLoadSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(dashboardLoadFailure(error));
	}
}

export function* loadDashboardResponseReset() {
	yield put(dashboardLoadChanged());
}
export function* dashLoadStart() {
	yield takeLatest(dashboardActionTypes.DASHBOARD_LOAD_REPSONSE_START, loadDashboardStart);
}

export function* dashLoadResponseReset() {
	yield takeLatest(
		dashboardActionTypes.DASHBOARD_LOAD_REPSONSE_RESET,
		loadDashboardResponseReset,
	);
}

// COLLECTION SECTION
export function* collectionDashboardStart({ payload }) {
	try {
		const jsonData = {
			UserID: payload.userID,
			CompanyID: payload.companyID,
			IsKapiital: payload.isKapiital,
			OverViewType: payload.overViewType,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
		};
		const encodeData = encodeJson(jsonData, generalTypesAction.DASHBOARD);
		const responseData = yield call(dashboardCollectionCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(dashboardCollectionFailure(responseData.data));
		} else {
			yield put(dashboardCollectionSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(dashboardCollectionFailure(error));
	}
}

export function* collectionDashboardResponseReset() {
	yield put(dashboardCollectionChanged());
}
export function* dashCollectionStart() {
	yield takeLatest(dashboardActionTypes.DASHBOARD_COLLECTION_START, collectionDashboardStart);
}

export function* dashCollectionResponseReset() {
	yield takeLatest(
		dashboardActionTypes.DASHBOARD_COLLECTION_RESET,
		collectionDashboardResponseReset,
	);
}


// COLLECTION SECTION TABLE
export function* collectionTableDashboardStart({ payload }) {
	try {
		const jsonData = {
			UserID: payload.userID,
			CompanyID: payload.companyID,
			IsKapiital: payload.isKapiital,
			OverViewType: payload.overViewType,
			FromDate: payload.startDate,
			ToDate: payload.endDate,
		};
		const encodeData = encodeJson(jsonData, generalTypesAction.DASHBOARD);
		const responseData = yield call(dashboardCollectionTableCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(dashboardCollectionTableFailure(responseData.data));
		} else {
			yield put(dashboardCollectionTableSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(dashboardCollectionTableFailure(error));
	}
}

export function* collectionTableDashboardResponseReset() {
	yield put(dashboardCollectionTableChanged());
}
export function* dashCollectionTableStart() {
	yield takeLatest(dashboardActionTypes.DASHBOARD_COLLECTION_TABLE_START, collectionTableDashboardStart);
}

export function* dashCollectionTableResponseReset() {
	yield takeLatest(
		dashboardActionTypes.DASHBOARD_COLLECTION_TABLE_RESET,
		collectionTableDashboardResponseReset,
	);
}

export function* dashboardSaga() {
	yield all([
		call(dashLoadStart),
		call(dashLoadResponseReset),
		call(dashCollectionStart),
		call(dashCollectionResponseReset),
		call(dashCollectionTableStart),
		call(dashCollectionTableResponseReset),
	]);
}
