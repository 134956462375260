/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import JWT from 'expo-jwt';
import loanIndexActionTypes from './loanIndex.types';
import {
	createLoanLimit,
	createGroupLoanLimit,
	loanRequestCreate,
	createLoanProduct,
} from './loanIndex.services';
import {
	setLoanLimitSuccess,
	setLoanLimitFailure,
	setLoanLimitChanged,
	setGroupLoanLimitSuccess,
	setGroupLoanLimitFailure,
	setGroupLoanLimitChanged,
	offlineLoanDisbursementSuccess,
	offlineLoanDisbursementFailure,
	offlineLoanDisbursementChanged,
	setLoanProductSuccess,
	setLoanProductFailure,
	setLoanProductChanged,
} from './loanIndex.actions';
import { encodeJson } from '../enode-decode';

export function* loanLimitDetailsStart({ payload }) {
	try {
		const jsonData = {
			Limit: payload.limit,
			Tenure: payload.tenure,
			Percentage: payload.percentage,
			ProcessingPercentage: payload.processingPercentage,
			MemberID: payload.memberID,
			UserID: payload.userID,
			CompanyID: payload.companyID,
			CollectionType: payload.typeCollection,
			CollectionPayDay: payload.collectionPayDay,
			IsPenaltyPercentage: payload.isPenaltyPercentageCheck,
			Penalty: payload.penaltyCheck,
			IsConfirmation: payload.isConfirmation,
			MemberSelectedLoanType: payload.memberSelectedLoanType,
		};
		const encodeData = encodeJson(jsonData, 'LoanIndex');
		console.log('encodeData', encodeData);
		const responseData = yield call(createLoanLimit, encodeData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(setLoanLimitFailure(responseData.data));
		} else {
			const DecodeloanIndexData = JWT.decode(responseData.data.response, 'LoanIndex');
			yield put(setLoanLimitSuccess(DecodeloanIndexData.data));
		}
	} catch (error) {
		yield put(setLoanLimitFailure(error));
	}
}

export function* loanLimitDetailsReset() {
	yield put(setLoanLimitChanged());
}

export function* loanLimitCreateStart() {
	yield takeLatest(loanIndexActionTypes.SET_LOAN_LIMIT_START, loanLimitDetailsStart);
}

export function* loanLimitCreateReset() {
	yield takeLatest(loanIndexActionTypes.SET_LOAN_LIMIT_RESET, loanLimitDetailsReset);
}

export function* groupLoanLimitDetailsStart({ payload }) {
	try {
		const jsonData = {
			GroupArr: JSON.stringify(payload.groupDetails).replace(/"/g, "'"),
			UserID: payload.userID,
			CompanyID: payload.companyID,
		};
		const encodeData = encodeJson(jsonData, 'LoanIndex');
		const responseData = yield call(createGroupLoanLimit, encodeData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(setGroupLoanLimitFailure(responseData.data));
		} else {
			yield put(setGroupLoanLimitSuccess(responseData.data));
		}
	} catch (error) {
		yield put(setGroupLoanLimitFailure(error));
	}
}

export function* groupLoanLimitDetailsReset() {
	yield put(setGroupLoanLimitChanged());
}

export function* groupLoanLimitCreateStart() {
	yield takeLatest(loanIndexActionTypes.SET_LOAN_LIMIT_GROUP_START, groupLoanLimitDetailsStart);
}

export function* groupLoanLimitCreateReset() {
	yield takeLatest(loanIndexActionTypes.SET_LOAN_LIMIT_GROUP_RESET, groupLoanLimitDetailsReset);
}
export function* loanDisburseStart({ payload }) {
	try {
		const jsonData = {
			MemberID: payload.memberID,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			LoanApproveAmount: payload.limit,
			LoanAmount: payload.limit,
			LoanPercentage: payload.percentage,
			LoanTenureCount: payload.tenure,
			CollectionType: payload.typeCollection,
			CollectionPayDay: payload.collectionPayDay,
			IsPenaltyPercentage: payload.isPenaltyPercentageCheck,
			Penalty: payload.penaltyCheck,
			LoanProcessingPercentage: payload.processingPercentage,
			IsConfirmation: payload.isConfirmation,
			LoanPaidArr: JSON.stringify(payload.loanPayArr).replace(/"/g, "'"),
			BankAccountNumber: payload.accountNumber,
			BankIFSCcode: payload.ifscCode,
			DeviceModel: '',
			Latitude: 0,
			Longitude: 0,
			MemberSelectedLoanType: payload.memberSelectedLoanType,
			IsOffline: 1,
		};
		const encodeData = encodeJson(jsonData, 'LoanIndex');
		console.log('encodeData', encodeData);
		const responseData = yield call(loanRequestCreate, encodeData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(offlineLoanDisbursementFailure(responseData.data));
		} else {
			const DecodeloanIndexData = JWT.decode(responseData.data.response, 'LoanIndex');
			yield put(offlineLoanDisbursementSuccess(DecodeloanIndexData.data));
		}
	} catch (error) {
		yield put(offlineLoanDisbursementFailure(error));
	}
}

export function* loanDisburseReset() {
	yield put(offlineLoanDisbursementChanged());
}

export function* loanOfflineDisbursementStart() {
	yield takeLatest(loanIndexActionTypes.LOAN_DISBURSEMENT_OFFLINE_START, loanDisburseStart);
}

export function* loanOfflineDisbursementReset() {
	yield takeLatest(loanIndexActionTypes.LOAN_DISBURSEMENT_OFFLINE_RESET, loanDisburseReset);
}

export function* loanProductDetailsStart({ payload }) {
	try {
		const jsonData = {
			MemberID: payload.memberID,
			UserID: payload.userID,
			CompanyID: payload.companyID,
			ProductIDs: payload.productIds,
			MemberSelectedLoanType: payload.memberSelectedLoanType,
			CollectionDay: payload.collectionDay,
			IsOffline: 1,
		};
		const encodeData = encodeJson(jsonData, 'LoanIndex');
		const responseData = yield call(createLoanProduct, encodeData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(setLoanProductFailure(responseData.data));
		} else {
			yield put(setLoanProductSuccess(responseData.data));
		}
	} catch (error) {
		yield put(setLoanProductFailure(error));
	}
}

export function* loanProductDetailsReset() {
	yield put(setLoanProductChanged());
}

export function* loanProductCreateStart() {
	yield takeLatest(loanIndexActionTypes.SET_LOAN_PRODUCT_START, loanProductDetailsStart);
}

export function* loanProductCreateReset() {
	yield takeLatest(loanIndexActionTypes.SET_LOAN_PRODUCT_RESET, loanProductDetailsReset);
}
export function* loanIndexSaga() {
	yield all([
		call(loanLimitCreateStart),
		call(loanLimitCreateReset),
		call(groupLoanLimitCreateStart),
		call(groupLoanLimitCreateReset),
		call(loanOfflineDisbursementStart),
		call(loanOfflineDisbursementReset),
		call(loanProductCreateStart),
		call(loanProductCreateReset),
	]);
}
