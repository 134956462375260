/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import blockChainActionTypes from './blockChain.types';

export const memberBlockChainLoanApprovalStart = (details) => ({
	type: blockChainActionTypes.MEMBER_BLOCK_CHAIN_LOAN_APPROVAL_START,
	payload: details,
});

export const memberBlockChainLoanApprovalSuccess = (details) => ({
	type: blockChainActionTypes.MEMBER_BLOCK_CHAIN_LOAN_APPROVAL_SUCCESS,
	payload: details,
});

export const memberBlockChainLoanApprovalFailure = (error) => ({
	type: blockChainActionTypes.MEMBER_BLOCK_CHAIN_LOAN_APPROVAL_FAILURE,
	payload: error,
});

export const memberBlockChainLoanApprovalChanged = () => ({
	type: blockChainActionTypes.MEMBER_BLOCK_CHAIN_LOAN_APPROVAL_CHANGED,
});

export const memberBlockChainLoanApprovalReset = () => ({
	type: blockChainActionTypes.MEMBER_BLOCK_CHAIN_LOAN_APPROVAL_RESET,
});

