/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
// import JWT from 'expo-jwt';
import cbCheckActionTypes from './cbCheck.types';
import { getBorrowersCBCheck, getBorrowersCRIFCheck, memberCRIFCheck, getMemberHMCheck } from './cbCheck.services';
import generalTypesAction from '../genera.types';
import {
	getBorrowerCBSuccess,
	getBorrowerCBFailure,
	getBorrowerCBChanged,
	getBorrowerCRIFSuccess,
	getBorrowerCRIFFailure,
	getBorrowerCRIFChanged,
	checkMemberCRIFSuccess,
	checkMemberCRIFFailure,
	checkMemberCRIFChanged,
	getMemberHMSuccess,
	getMemberHMFailure,
	getMemberHMChanged,
} from './cbCheck.actions';
import { encodeJson } from '../enode-decode';

export function* memberBorrowerCBChecklist({ payload: { cbCredentials } }) {
	try {
		const jsonData = {
			MemberID: cbCredentials.memberID,
			CompanyID: cbCredentials.companyID,
		};
		const memberCBCheckData = encodeJson(jsonData, generalTypesAction.CBCHECK);
		const responseData = yield call(getBorrowersCBCheck, memberCBCheckData);
		if (responseData.data.statusCode === '100') {
			yield put(getBorrowerCBFailure(responseData.data));
		} else {
			yield put(getBorrowerCBSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getBorrowerCBFailure(error));
	}
}

export function* memberBorrowerCBChecklistReset() {
	yield put(getBorrowerCBChanged());
}

export function* memberBorrowerCRIFChecklist({ payload: { crifData } }) {
	try {
		const jsonData = {
			MemberID: crifData.memberID,
			CompanyID: crifData.companyID,
			UserID: crifData.userID,
		};
		const memberCRIFCheckData = encodeJson(jsonData, generalTypesAction.HIGHMARK);
		const responseData = yield call(getBorrowersCRIFCheck, memberCRIFCheckData);
		if (responseData.data.statusCode === '100') {
			yield put(getBorrowerCRIFFailure(responseData.data));
		} else {
			yield put(getBorrowerCRIFSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getBorrowerCRIFFailure(error));
	}
}

export function* memberBorrowerCRIFChecklistReset() {
	yield put(getBorrowerCRIFChanged());
}

export function* getBorrowerCBCheck() {
	yield takeLatest(cbCheckActionTypes.MEMBER_GET_CBCHECK_START, memberBorrowerCBChecklist);
}

export function* getBorrowerCBCheckReset() {
	yield takeLatest(cbCheckActionTypes.MEMBER_GET_CBCHECK_RESET, memberBorrowerCBChecklistReset);
}

export function* getBorrowerCRIFCheck() {
	yield takeLatest(cbCheckActionTypes.MEMBER_GET_CRIF_START, memberBorrowerCRIFChecklist);
}

export function* getBorrowerCRIFCheckReset() {
	yield takeLatest(cbCheckActionTypes.MEMBER_GET_CRIF_RESET, memberBorrowerCRIFChecklistReset);
}

// CHECK CRIF SECTION

export function* memberCheckCrifStart({ payload }) {
	try {
		const jsonData = {
			MemberID: payload.memberID,
			CompanyID: payload.companyID,
			UserID: payload.userID,
		};
		const memberCRIFCheckData = encodeJson(jsonData, generalTypesAction.HIGHMARK);
		const responseData = yield call(memberCRIFCheck, memberCRIFCheckData);
		if (responseData.data.statusCode === '100') {
			yield put(checkMemberCRIFFailure(responseData.data));
		} else {
			yield put(checkMemberCRIFSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(checkMemberCRIFFailure(error));
	}
}

export function* memberCheckCrifReset() {
	yield put(checkMemberCRIFChanged());
}

export function* checkMemberCrif() {
	yield takeLatest(cbCheckActionTypes.MEMBER_CHECK_CRIF_START, memberCheckCrifStart);
}

export function* checkMemberCrifReset() {
	yield takeLatest(cbCheckActionTypes.MEMBER_CHECK_CRIF_RESET, memberCheckCrifReset);
}

// FETCH HIGH MARK SECTION
export function* memberHMStart({ payload }) {
	try {
		const jsonData = {
			MemberID: payload.memberID,
			CompanyID: payload.companyID,
			UserID: payload.userID,
		};
		const memberCBCheckData = encodeJson(jsonData, generalTypesAction.HIGHMARK);
		const responseData = yield call(getMemberHMCheck, memberCBCheckData);
		if (responseData.data.statusCode === '100') {
			yield put(getMemberHMFailure(responseData.data));
		} else {
			yield put(getMemberHMSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getMemberHMFailure(error));
	}
}

export function* memberHMStartReset() {
	yield put(getMemberHMChanged());
}

export function* getMemberHM() {
	yield takeLatest(cbCheckActionTypes.MEMBER_GET_HIGHMARK_START, memberHMStart);
}

export function* getMemberHMReset() {
	yield takeLatest(cbCheckActionTypes.MEMBER_GET_HIGHMARK_RESET, memberHMStartReset);
}

export function* cbCheckSaga() {
	yield all([
		call(getBorrowerCBCheck),
		call(getBorrowerCBCheckReset),
		call(getBorrowerCRIFCheck),
		call(getBorrowerCRIFCheckReset),
		call(checkMemberCrif),
		call(checkMemberCrifReset),
		call(getMemberHM),
		call(getMemberHMReset),
	]);
}
